import RawComponent from "./RawComponent.js";
import Log from "./Log.js";
import Component from "./Component.js";
import HTMLQuery from "./HTMLQuery.js";
import {
  Emitter,
  uuid,
  setCookie,
  b64DecodeUnicode,
  getLocalComponentsList,
} from "./globals.js";
import "./types.js";

/** @type {{ [url: string]: DataCache }} */
const loadDataCache = {};

/** @type {{ [url: string]: Promise<ComponentData> }} */
const loadComponentCache = {};

class Zone {
  /** @type { Component } */
  rootComponent;

  debugMode = false;

  emitter = new Emitter();

  /** @type {{[RawComponentId: string]: RawComponent}} */
  rawComponents = {};

  /** @type {{ [ComponentURN: string]: Component }} */
  components = {};
  /** @type {{ [ComponentURN: string]: Component }} */
  componentsLoaded = {};

  /** @type {{ [url: string]: Component }} */
  history = {};

  /** @type {{ [ComponentName: string]: {} }} */
  treeData = {};
  sharedData = {};

  /** @type { Log } */
  log;
  conf;

  /**
   * @param {{
   * env: object,
   * logs: Log,
   * app: object
   * }?} param0.conf
   */
  constructor({ conf }) {
    this.debugMode = conf.env === "dev";
    this.log = new Log(conf.logs);
    this.conf = conf;
  }

  /**
   * @param {string} eventName
   * @param {Function} handler
   * @param {EventOptionsParams} options
   */
  on(eventName, handler, options) {
    return this.emitter.on(eventName, handler, options);
  }

  /**
   * @param {string} eventName
   * @param {any} data
   */
  emit(eventName, data) {
    this.emitter.emit(eventName, data);
  }

  /**
   * @param {string} eventName
   * @param {Function} handler
   * @param {EventOptionsParams} options
   */
  off(eventName, handler, options) {
    this.emitter.off(eventName, handler, options);
  }

  find(selector, scope) {
    return new HTMLQuery(selector, scope);
  }

  getRawComponent(rawComponentId) {
    return this.rawComponents[rawComponentId];
  }

  /**
   * Get a component by this name
   * @param {string} componentName "zone-name"
   */
  getComponent(componentName) {
    const components = Object.values(this.components);

    let index = components.findIndex(
      (element) => element.name === componentName
    );
    const component = components[index];

    if (this.debugMode && component) {
      while (index >= 0) {
        index = components.findIndex(
          (element, key) => key > index && element.name === componentName
        );
        if (index >= 0) {
          this.log.warning(
            "Zone",
            "multiples components for name " +
              componentName +
              ", URN: " +
              components[index].URN
          );
        }
      }
    }

    return component;
  }

  /**
   * Get a component in the root component hierarchy
   * @param {string} componentURN ">zone-name1>zone-name2" or ">" for root component
   */
  getComponentByURN(componentURN) {
    // get root component
    if (componentURN === ">") return this.rootComponent;

    let parts = componentURN.substring(1).split(">");

    let component = this.components[">" + parts[0]];

    if (!component) {
      component = this.rootComponent?.subComponents[parts[0]];

      if (!component) return;
    }

    parts.splice(0, 1);

    for (const name of parts) {
      if (name === "..") {
        component = component.parent;
      } else {
        component = component.subComponents[name];
      }

      if (!component) break;
    }

    return component;
  }

  /**
   * @param {Component} rootComponent
   * @param {TreeData?} branchData
   */
  createTreeData(rootComponent, branchData) {
    if (branchData) {
      branchData[rootComponent.name] || (branchData[rootComponent.name] = {});

      branchData[rootComponent.name].merged = Object.assign(
        {},
        branchData.merged,
        rootComponent.data
      );

      branchData[rootComponent.name].parent = branchData;

      branchData = branchData[rootComponent.name];
    } else {
      this.treeData[rootComponent.name] ||
        (this.treeData[rootComponent.name] = {});

      branchData = this.treeData[rootComponent.name];

      branchData.merged = rootComponent.data;
    }

    Object.assign(branchData, rootComponent.data);
    branchData.component = rootComponent.componentData;

    rootComponent.treeData = branchData;

    // create sub components branch data
    for (const subComponent of Object.values(rootComponent.subComponents)) {
      subComponent && this.createTreeData(subComponent, branchData);
    }
  }

  /**
   * @param {ComponentData} componentData
   * @param {Component} parent
   * @param {boolean} isPage
   */
  async addComponent(
    {
      path,
      templateHash,
      assetsPaths,
      templateContent,
      name,
      URN,
      data,
      selector,
      isStatic,
      isLayout,
      refLayoutName,
      subComponents,
    },
    parent,
    isPage = false
  ) {
    const rawComponentId = path.replaceAll("/", ".").substring(1);
    let rawComponent = this.rawComponents[rawComponentId];
    if (!rawComponent) {
      const decodeTemplate = templateContent
        ? b64DecodeUnicode(templateContent)
        : undefined;

      // CREATE RAW COMPONENT
      rawComponent = new RawComponent({
        path,
        template: decodeTemplate,
        templateHash,
        assetsPaths,
      });

      this.log.info("Zone", "-- CREATE RAW COMPONENT -- " + rawComponent.id, 1);

      await rawComponent.init();

      // save
      this.rawComponents[rawComponent.id] = rawComponent;
    }

    // set page id
    name || (name = rawComponent.id);

    // decode data
    let decodeData = data ? JSON.parse(b64DecodeUnicode(data)) : undefined;

    // extract component data
    let componentData;
    if (decodeData) {
      let { component: extract, ...rest } = decodeData;
      componentData = extract;
      decodeData = rest;
    }

    // set URN
    URN || (URN = name);
    isPage && (URN = ">" + URN);
    parent && (URN = parent.URN + ">" + URN);

    let component = this.components[URN];
    const isNewComponent = !component;
    if (isNewComponent) {
      // CREATE COMPONENT
      component = new Component({
        name,
        URN,
        rawComponent,
        data: decodeData,
        componentData,
        parent,
        isLayout,
        isStatic,
        isPage,
        isRoot: !parent && !isPage,
      });

      this.log.info("Zone", "-- CREATE COMPONENT -- " + name, 1);

      // save
      if (component.isRoot) {
        this.componentsLoaded[component.URN] = component;
      } else {
        this.components[component.URN] = component;
      }

      // set selector
      selector && (component.selector = selector);
    }

    component.isLayout && (component.activeComponentName = refLayoutName);

    // CREATE SUB COMPONENTS
    if (subComponents) {
      for (const subComponentData of Object.values(subComponents)) {
        const subComponent = await this.addComponent(
          subComponentData,
          component
        );
        // add to subcomponents of rawComponent
        // rawComponent.subComponents[subComponent.name] = subComponent.rawComponent;

        // add to subcomponents of component
        if (
          component.subComponents[subComponent.name] &&
          component.subComponents[subComponent.name] !== subComponent
        ) {
          this.log.warning(
            "Zone",
            "a component " +
              subComponent.name +
              " is already present in " +
              component.name
          );
        }
        component.subComponents[subComponent.name] = subComponent;

        if (component.isLayout && subComponentData.refParentName) {
          component.layoutSubComponents[subComponentData.refParentName] ||
            (component.layoutSubComponents[subComponentData.refParentName] =
              {});
          component.layoutSubComponents[subComponentData.refParentName][
            subComponent.name
          ] = subComponent;
        }
      }
    }

    if (!isNewComponent) {
      decodeData && (component.data = decodeData);

      if (!component.getIsStatic()) {
        componentData && (component.componentData = componentData);
      }

      await component.reset({ stopPropagation: true });
    }

    await component.loaded();

    return component;
  }

  /**
   * @param {string | Request} resource url or Request of the data
   * @param {Function?} handler
   */
  async loadData(resource, handler) {
    let url;
    if (typeof resource === "string") {
      url = resource;
    } else if (resource instanceof Request) {
      url = JSON.stringify(resource, ["url", "method", "headers", "body"]);
    } else {
      this.log.error("Zone", "resource type is not valid");
      return;
    }

    const uuidCache = uuid();
    const onceEventName = `setDataCache:${uuidCache}:${url}`;
    const onEventName = `newDataCache:${url}`;

    const haveHandler = typeof handler === "function";

    if (haveHandler) {
      this.on(onceEventName, handler);
      this.on(onEventName, handler);
    }

    loadDataCache[url] || (loadDataCache[url] = { callbacks: [] });
    const cache = loadDataCache[url];

    if (cache.data) {
      if (haveHandler) {
        this.emit(onceEventName, cache.data);
      }
      return cache.data;
    }

    return await fetch(resource)
      .then((res) => res.json())
      .then((data) => {
        cache.data = data;

        this.emit(onEventName, data);

        return cache.data;
      })
      .catch((error) => {
        this.log.error("Zone", error);
      });
  }

  /**
   * @param {string | URL} url
   * @param {string} componentName
   *
   * @returns {Promise<Component>}
   */
  async loadComponent(url, componentName) {
    try {
      try {
        url = new URL(url);
      } catch (error) {
        url = new URL(url, document.location.origin);
      }

      let componentData;
      let component;

      if (!loadComponentCache[url.pathname]) {
        loadComponentCache[url.pathname] = this.fetchComponentData(url);
      }

      componentData = await loadComponentCache[url.pathname];

      componentData.name = componentName;

      component = await this.addComponent(componentData);

      // add url to history
      if (component.isLayout) {
        this.history[url.pathname] =
          component.subComponents[componentData.refLayoutName];
      } else {
        this.history[url.pathname] = component;
      }

      return component;
    } catch (error) {
      this.log.error("Zone", error);
    }
  }

  /**
   * @param {string| URL} url
   * @param {boolean?} SSR
   *
   * @returns {Promise<ComponentData>}
   */
  fetchComponentData(url, SSR = false) {
    try {
      url = new URL(url);
    } catch (error) {
      url = new URL(url, document.location.origin);
    }

    let component = this.history[url.pathname];

    const clientComponents = getLocalComponentsList(component);

    if (component?.parent?.isLayout) {
      const layoutCmp = component.parent;
      Object.assign(clientComponents, getLocalComponentsList(layoutCmp));

      for (const layoutSubComponent of Object.values(
        layoutCmp.layoutSubComponents[layoutCmp.activeComponentName]
      )) {
        Object.assign(
          clientComponents,
          getLocalComponentsList(layoutSubComponent)
        );
      }
    }

    setCookie("clientComponents", JSON.stringify(clientComponents));

    return new Promise(async (resolve, reject) => {
      await fetch(url, {
        method: SSR ? "GET" : "POST",
      })
        .then((response) => response.json())
        .then((componentData) => {
          if (componentData.success) {
            resolve(componentData);
          } else {
            reject(new Error("Request failed"));
          }
        })
        .catch((error) => {
          reject(error);
        });

      setCookie("clientComponents", "", { maxAge: 0 });
    });
  }
}

export default Zone;

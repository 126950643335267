/* common/serializer/serializer */ 
/** @typedef {import("/zone/js/ComponentScript.js").default} ComponentScript*/

/**
 * @param {ComponentScript} me
 */

export default function (me) {
	let _b0xComponent;
	let _inspectorComponent;

	let _currentRule = null;
	let _enabled = false;

	let _rulesFieldsValues = {};

	const _debounce = (func, wait) => {
		let timeout;

		return function executedFunction(...args) {
			const later = () => {
				clearTimeout(timeout);
				func(...args);
			};

			clearTimeout(timeout);
			timeout = setTimeout(later, wait);
		};
	};

	function debounce(callback, delay) {
		var timer;
		return function () {
			var args = arguments;
			var context = this;
			clearTimeout(timer);
			timer = setTimeout(function () {
				callback.apply(context, args);
			}, delay);
		};
	}

	function throttle(callback, delay) {
		var last;
		var timer;
		return function () {
			var context = this;
			var now = +new Date();
			var args = arguments;
			if (last && now < last + delay) {
				// le délai n'est pas écoulé on reset le timer
				clearTimeout(timer);
				timer = setTimeout(function () {
					last = now;
					callback.apply(context, args);
				}, delay);
			} else {
				last = now;
				callback.apply(context, args);
			}
		};
	}

	function _map(value, fromLow, toLow, fromHigh, toHigh) {
		const fromRange = fromHigh - fromLow;
		const toRange = toHigh - toLow;
		const scaleFactor = toRange / fromRange;

		let newValue = value - fromLow;
		newValue = newValue * scaleFactor;
		newValue = newValue + toLow;

		return newValue;
	}

	return {
		onInit: function () {
			_b0xComponent = Zone.getComponent("b0x");
			_inspectorComponent = Zone.getComponent("inspector");
		},
		onRender: function () {
			let data = me.getData();
			let portConfig;
			let rulesFieldsValues = {};

			if (data.uuid) {
				//we're serializing a rule and not GLOBAL
				portConfig = _b0xComponent.getPortConfig("input", data.portNumber);
				_currentRule = _b0xComponent.getPortRule(data.portNumber, data.uuid);
				rulesFieldsValues = _b0xComponent.getRuleFieldsValues(data.uuid);
			} else {
				portConfig = _b0xComponent.getGlobalConfig();
				_currentRule = null;
			}

			console.log("serialize on render", rulesFieldsValues, data);

			//Creation of some fields managed by JS
			for (var index in data.fields) {
				let field = data.fields[index];
				let $fieldEl = $("#field_port" + data.portNumber + "_" + field.var);
				if (field.orientation != "vertical") field.orientation = "horizontal";

				switch (field.control) {
					case "separation":
						break;
					case "bar":
						field.editable = typeof field.editable === "boolean" ? field.editable : false;
						field.from = Number.isInteger(field.from) ? field.from : 0;
						field.to = Number.isInteger(field.to) ? field.to : 127;
						if (field.to < field.from) field.to = field.from;
						if (field.from > field.to) field.from = field.to;
						field.default = Number.isInteger(field.default) && field.default >= field.from && field.default <= field.to ? field.default : field.from;

						if (rulesFieldsValues[field.var] != undefined) {
							field.default = rulesFieldsValues[field.var];
							console.log(">>>>> VALUE !!! ", field.var, field.default);
						}

						let direction = "ltr";
						if (field.orientation == "vertical") direction = "rtl";

						let slider = $fieldEl.get(0);
						if (slider != undefined) {
							if (slider.noUiSlider) slider.noUiSlider.destroy();

							noUiSlider.create(slider, {
								animate: false,
								start: [field.default],
								orientation: field.orientation,
								direction: direction,
								format: {
									// 'to' the formatted value. Receives a number.
									to: function (value) {
										return parseInt(value);
									},
									// 'from' the formatted value.
									// Receives a string, should return a number.
									from: function (value) {
										return parseInt(value);
									},
								},
								tooltips: true,
								connect: [true, false],
								step: 1,
								margin: 1,
								pips: {
									mode: "range",
									density: 5,
								},
								range: {
									min: field.from,
									"50%": field.to / 2,
									max: field.to,
								},
							});

							let $tooltip;
							$tooltip = $(".noUi-handle", slider);

							if (!field.editable) {
								slider.classList.add("no-handle-slider"); //hide the handle if not editable
							} else slider.classList.remove("no-handle-slider");

							slider.noUiSlider.on("set", function (values, handle) {
								const value = values[handle];
								//save this value in memory
								if (_currentRule) {
									//for a rule
									if (_currentRule.editables[field.var] != value) $tooltip.addClass("noUi-active"); //display the tooltip if a new value is set

									_currentRule.editables[field.var] = value;
								} else {
									//for global
									if (!portConfig.editables) portConfig.editables = {};
									if (portConfig.editables[field.var] != value) $tooltip.addClass("noUi-active"); //display the tooltip if a new value is set
									portConfig.editables[field.var] = value;
								}
							});

							slider.noUiSlider.on(
								"update",
								debounce(function () {
									setTimeout(() => {
										$tooltip.removeClass("noUi-active"); //hide the tooltip after a second and half (à revoir)
									}, 1500);
								}, 100)
							);
						}

						break;
					case "checkbox":
						if (rulesFieldsValues[field.var] != undefined) {
							let $fieldEl = $("#field_port" + data.portNumber + "_" + field.var);
							let value = rulesFieldsValues[field.var];
							console.log("checkbox", value);
							$fieldEl.prop("checked", value);
						}
						break;
					case "text":
					default:
						break;
				}
			}

			_b0xComponent.on("FieldsUpdated", function (eventData) {
				//this is occuring when some fields has just been updated due to rules to be activated

				let data = me.getData();

				//console.log("FieldsUpdated eventData.fields", eventData.fields);

				_b0xComponent.setRulesFieldsValues(eventData.fields);

				//if (_enabled) {
				if (eventData.portNumber == data.portNumber) {
					if (_currentRule != null) {
						let fieldsData = eventData.fields[_currentRule.uuid];
						if (fieldsData != null && Object.keys(fieldsData).length !== 0) {
							//console.log("FieldsUpdated", eventData, _currentRule, fieldsData, data.fields);

							//id the displayed serializer is the one that needs to be updated

							for (var index in data.fields) {
								let field = data.fields[index];
								if (field.orientation != "vertical") field.orientation = "horizontal";

								if (fieldsData[field.var] != undefined) {
									let $fieldEl = $("#field_port" + data.portNumber + "_" + field.var);
									let value = fieldsData[field.var];
									let percent = 0;
									let currentValue = null;

									if (value != null) {
										switch (field.control) {
											case "barxx":
												currentValue = $fieldEl.get(0).noUiSlider.get(true);
												if (value != currentValue) {
													field.from = Number.isInteger(field.from) ? field.from : 0;
													field.to = Number.isInteger(field.to) ? field.to : 127;
													if (field.to < field.from) field.to = field.from;
													if (field.from > field.to) field.from = field.to;
													field.default = Number.isInteger(field.default) && field.value >= field.from && field.default <= field.to ? field.default : field.from;
													percent = parseInt(_map(value, field.from, field.to, 0, 100)) + "%";
													$fieldEl.width(percent);
												}

												break;
											case "bar":
												currentValue = $fieldEl.get(0).noUiSlider.get(true);
												if (value != currentValue) {
													field.from = Number.isInteger(field.from) ? field.from : 0;
													field.to = Number.isInteger(field.to) ? field.to : 127;
													if (field.to < field.from) field.to = field.from;
													if (field.from > field.to) field.from = field.to;
													field.default = Number.isInteger(field.default) && field.default >= field.from && field.default <= field.to ? field.default : field.from;

													$fieldEl.get(0).noUiSlider.set(value);
												}

												break;
											case "checkbox":
												currentValue = $fieldEl.prop("checked");
												if (value != currentValue) {
													$fieldEl.prop("checked", value);
												}
												break;
											case "text":
											default:
												$fieldEl.val(value);
												break;
										}
									}
								}
							}
						}
					}
				}
				//}
			});

			//BAD!!! To change later

			_inspectorComponent.on("panelClosed", function (panelName) {
				console.log("serializer panelClosed", panelName);
				if (panelName == "rulepanel") {
					_currentRule = null;
				}
			});

			/*
            var slider = document.getElementById('slider');

            noUiSlider.create(slider, {
                start: [20, 80],
                tooltips: true,
                connect: true,
                step: 1,
                margin: 1,
                range: {
                    'min': 0,
                    'max': 100
                }
            });

            var slider2 = document.getElementById('slider2');

            noUiSlider.create(slider2, {
                start: [20],
                tooltips: true,
                connect: true,
                step: 1,
                margin: 1,
                range: {
                    'min': 0,
                    'max': 100
                }
            });
            */
		},
		activate: function () {
			_enabled = true;
		},
		disable: function () {
			_enabled = false;
		},
	};
}

/* b0x/layout-parts/inspector/inspector */ 
/** @typedef {import("/js/dist/ComponentScript.js").default} ComponentScript*/

/**
 * @param {ComponentScript} me
 */

export default function (me) {
	let _globalPanel;
	let _inputPanel;
	let _outputPanel;
	let _rulePanel;

	let _midiRulesComponent;
	let _b0xComponent;
	let _serializerComponent;

	let _currentPanelName = null;
	let _data = null;

	return {
		onInit: function () {
			_midiRulesComponent = Zone.getComponent("midirules");
			_b0xComponent = Zone.getComponent("b0x");
			//_serializerComponent = Zone.getComponent("serializer");

			//me.showPanel("helppanel");

			/*var editor = monaco.editor.create(document.getElementById('luacode'), {
                value: ['local toto; [[field:{"type":"slider","readonly":false,"range":[0,100]}]]','','function BEGIN()', 'io.write([[', , 'MACHIN', ']])','end'].join('
'),
                language: 'lua',
                theme:'vs-dark',
                automaticLayout: true // <<== the important part
            });
            */

			let data = me.getData();

			$("body").on("click", ".inspector-panels .btn-closePanel", function (e) {
				e.stopPropagation();
				me.closePanel();
				return false;
			});
		},
		onRender: function () {},
		showPanel: function (panelName, params) {
			//_serializerComponent = Zone.getComponent("serializer");

			let $inspectorPanels = $(".inspector-panels");
			$inspectorPanels.addClass("on");

			let component = me.getSubComponent(panelName);

			let data = {};

			switch (panelName) {
				case "rulepanel":
					if (_currentPanelName == panelName && data.portNumber == params.portNumber && data.ruleID == params.ruleID) return;

					let rule = _b0xComponent.getPortRule(params.portNumber, params.ruleID);

					data = rule;
					data.portNumber = params.portNumber;
					data.learn = _b0xComponent.getConfig("learn");

					//console.log(data);
					//_serializerComponent.activate();
					break;

				case "inputpanel":
					if (_currentPanelName == panelName && data.portNumber == params.portNumber) return;

					data = _b0xComponent.getPortConfig("input", params.portNumber[0]);
					data.portNumber = params.portNumber[0];
					data.currentSetup = _b0xComponent.getCurrentSetup();
					data.devices = _b0xComponent.getMIDIDevicesAvailable("input", data.portNumber);
					break;
				case "outputpanel":
					if (_currentPanelName == panelName && data.portNumber == params.portNumber) return;

					data = _b0xComponent.getPortConfig("output", params.portNumber[0]);
					data.portNumber = params.portNumber[0];
					data.currentSetup = _b0xComponent.getCurrentSetup();
					data.devices = _b0xComponent.getMIDIDevicesAvailable("output", data.portNumber);
					break;
				case "globalpanel":
					if (_currentPanelName == panelName) return;

					data = _b0xComponent.getGlobalConfig();
					//_serializerComponent.activate();
					break;
				default:
					if (params) {
						data = params;
					}
					break;
			}

			_currentPanelName = panelName;
			_data = data;

			component.render(data);

			$(".panel", $inspectorPanels).removeClass("on");
			$("[zone-name=" + panelName + "]", $inspectorPanels).addClass("on");
		},
		closePanel: function () {
			//console.log("Attempt to close panel :" + _currentPanelName);

			if (_currentPanelName != null) {
				$(".inspector-panels").removeClass("on");
				//$(".panel", $inspectorPanels).removeClass("on");
				me.emit("panelClosed", _currentPanelName); //emit an event to notify that a panel was closed
				switch (_currentPanelName) {
					case "rulepanel":
					case "globalpanel":
					//_serializerComponent.disable();
				}

				_currentPanelName = null;
				_data = null;
			}
		},
		getCurrentPanelInfos: function () {
			return {
				name: _currentPanelName,
				data: _data,
			};
		},
	};
}

/* b0x/layout-parts/modals/devices/devices */ 
/** @typedef {import("/js/dist/ComponentScript.js").default} ComponentScript*/

/**
 * @param {ComponentScript} me
 */

export default function (me) {
	let _MIDIComponent;
	let _b0xComponent;
	let _modal;
	let _data;

	/*function _updateInputsSection(inputs) {
    console.log("_updateInputsSection", inputs);

    _data = me.getData();
    _data.setups = _b0xComponent.getSetups();

    for (var i in _data.setups) {
      let setup = _data.setups[i];
      if (setup.selected) {
        for (var j in inputs) {
          let device = inputs[j];
          let found = false;
          for (var k in setup.inputs) {
            let setupInput = setup.inputs[k];
            if (
              device.type == "input" &&
              setupInput.name == device.name &&
              setupInput.manufacturer == device.manufacturer
            ) {
              _data.setups[i].inputs[j].id = device.id;
              _data.setups[i].inputs[j].state = device.state;
              found = true;
            }
          }
          if (!found) {
            //we add the device in the list
            _data.setups[i].inputs.push({
              id: device.id,
              name: device.name,
              manufacturer: device.manufacturer,
              state: device.state,
              linked: null,
            });
          }
        }
        _data.inputs = _data.setups[i].inputs;
        break;
      }
    }

    _b0xComponent.saveSetup();

    console.log("inputs render");
    me.render(_data);
  }

  function _updateOutputsSection(outputs) {
    _data = me.getData();
    _data.outputs = outputs;
    _data.setups = _b0xComponent.getSetups();

    console.log("outputs render");
    me.render(_data);
  }
  */

	function _messageReceived(msg) {
		//console.log("Settings MIDI Received : "+msg.inputID+" "+msg.command+" d1 = "+msg.data1+" d2 = "+msg.data2);

		_flash(msg.inputID);
	}

	function _flash(inputID) {
		const port = document.querySelector("#led-" + inputID + " .led");

		if (port) {
			if (_modal && _modal._isShown) {
				let color = "#ad214b"; //red
				//let gradient = "linear-gradient(0deg,#fb1304 0%,#c33d29 50%,#edad99 100%);";

				port.animate(
					[
						{
							background: color,
						},
					],
					{
						duration: 50,
						iterations: 1,
					}
				);
			}
		}
	}

	function _update() {
		let selectedSetup = _b0xComponent.getCurrentSetup();
		let currentMIDIDevices = _b0xComponent.getCurrentMIDIDevices();

		//make inputs of the setup an array, including the MIDI Device state
		let inputs = [];
		for (var hash in selectedSetup.inputs) {
			let input = selectedSetup.inputs[hash];

			input.state = "disconnected";
			for (var i in currentMIDIDevices) {
				let device = currentMIDIDevices[i];
				if (device.hash == hash) {
					input.state = device.state;
					break;
				}
			}
			inputs.push(input);
		}

		//make outputs of the setup an array, including the MIDI Device state
		let outputs = [];
		for (var hash in selectedSetup.outputs) {
			let output = selectedSetup.outputs[hash];
			output.state = "disconnected";
			for (var i in currentMIDIDevices) {
				let device = currentMIDIDevices[i];
				if (device.hash == hash) {
					output.state = device.state;
					break;
				}
			}
			outputs.push(output);
		}

		let data = {
			setups: _b0xComponent.getSetups(),
			inputs: inputs,
			outputs: outputs,
		};
		me.render(data);
	}

	return {
		onBeforeInit: function () {},
		onInit: function () {
			_b0xComponent = Zone.getComponent("b0x");
			_MIDIComponent = Zone.getComponent("midi");
			_MIDIComponent.on("MIDIMessageReceived", _messageReceived);

			//Click on a SETUP CHoice button
			$("#devices-modal").on("click", "[data-setup-id]", function () {
				let $this = $(this);
				let setupID = $this.attr("data-setup-id");
				_b0xComponent.selectSetup(setupID);
			});

			$("#settingsInputsTable").on("click", ".btlearn", function () {
				let $this = $(this);
			});

			$("#settingsOutputsTable").on("click", ".btlearn", function () {
				let $this = $(this);
			});

			_update();
		},
		onRender: function () {
			console.log();
		},
		onReady: function () {
			$("#settingsInputsTable .btlearn").on("click", function () {
				let $this = $(this);
				let deviceToForget = $this.attr("data-port-forget");
				_b0xComponent.forgetDevice("input", deviceToForget);
			});

			$("#settingsOutputsTable .btlearn").on("click", function () {
				let $this = $(this);
				let deviceToForget = $this.attr("data-port-forget");
				_b0xComponent.forgetDevice("output", deviceToForget);
			});
		},
		show: function () {
			if (!_modal) {
				_modal = new bootstrap.Modal("#devices-modal", {
					backdrop: "static",
					focus: true,
					keyboard: true,
				});
			}
			_modal.show();
		},
		update: function () {
			_update();
		},
	};
}

/* b0x/layout-parts/inspector/panels/global/global */ 
/** @typedef {import("/js/dist/ComponentScript.js").default} ComponentScript*/

/**
 * @param {ComponentScript} me
 */

export default function (me) {
	let $properties;
	let $code;

	let _b0xComponent;
	let _codeMonacoComponent;
	let _serializerComponent;

	let _editorCode;
	let _data;
	let _codeIsChecked = false;

	let _delegateDeclared = false;

	//Show PropertiesPanel
	function _showPropertiesPanel() {
		$properties = $("#globalProperties");
		$code = $("#globalLUAcode");
		$code.hide();
		$properties.show();
		_codeIsChecked = false;
		//$(".needScroll").getNiceScroll().resize();
	}

	function _showCodePanel() {
		$properties = $("#globalProperties");
		$code = $("#globalLUAcode");

		console.log($properties);

		$properties.hide();
		$code.show();
		_codeIsChecked = true;
		//$(".needScroll").getNiceScroll().resize();
	}

	//debounce used to test and save the luaCode typed in Monaco Editor
	const _debounce = (func, wait) => {
		let timeout;

		return function executedFunction(...args) {
			const later = () => {
				clearTimeout(timeout);
				func(...args);
			};

			clearTimeout(timeout);
			timeout = setTimeout(later, wait);
		};
	};

	//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	return {
		onInit: function () {
			_b0xComponent = Zone.getComponent("b0x");
			_codeMonacoComponent = Zone.getComponent("luacodeglobal");
			_serializerComponent = Zone.getComponent("globalLUAFields");
		},
		onRender: function () {
			//console.log("###################################################################");
			_data = me.getData();
			//console.log("GLOBAL RENDER", _data);

			if (_data.code != undefined) {
				let code = "";
				if (_data.code.trim() != "") {
					code = atob(_data.code);
				}

				let global = _b0xComponent.serializeFields("global", code, _data.portNumber, _data.uuid);
				_serializerComponent.render(global);

				// Code Field Management  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

				_editorCode = _codeMonacoComponent.create({
					value: code,
					language: "lua",
					theme: "vs-dark",
					automaticLayout: true, // <<== the important part
				});

				//Managing test and saving of the code
				_editorCode.onDidChangeModelContent(
					_debounce(function (event) {
						let globalCode = _editorCode.getValue();

						let global = _b0xComponent.updateGlobalCode(globalCode);

						_serializerComponent.render(global);

						//console.log(global);

						//let $globalStateInHome = $(".midi-rule[data-uuid='"+ruleID+"'] .state");
						let $globalStateInPanel = $("[zone-name='globalpanel'] .luacode_icon");

						if (global.state > 0) {
							//$globalStateInHome.addClass("syntaxError"); //todo
							$globalStateInPanel.addClass("error");
							$("[zone-name='globalpanel'] .syntax-error-message").html(global.error);
						} else {
							//$globalStateInHome.removeClass("syntaxError"); //todo
							$globalStateInPanel.removeClass("error");
							$("[zone-name='globalpanel'] .syntax-error-message").html("");
						}
					}, 800)
				);

				//temporary !!!!!!

				let globalVariables = _b0xComponent.getLUAGlobals();
				console.log("Global Variables", globalVariables);

				let $codeCheckbox = $("#globalCodeCheckbox");
				if (_codeIsChecked) {
					$codeCheckbox.prop("checked", true);
					_showCodePanel();
				} else {
					$codeCheckbox.prop("checked", false);
					_showPropertiesPanel();
				}

				//to move to onInit
				$codeCheckbox.change(function () {
					if (this.checked) {
						_showCodePanel();
					} else {
						_showPropertiesPanel();
					}
				});
			}
		},
		onReady: function () {
			if (!_delegateDeclared) {
				//Implementing clicks events //////////////////////////////////////////////////////////

				$("[zone-name=globalpanel]").on("click", ".form-check-input", function () {
					let $checkboxClicked = $(this);
					let checked = $checkboxClicked[0].checked;
					let group = $checkboxClicked.attr("group");
					let sub = $checkboxClicked.attr("sub");

					console.log($checkboxClicked.attr("id"));

					if ($checkboxClicked.attr("id") != "globalCodeCheckbox") {
						//test temporaire
						_b0xComponent.setGlobalConfig(group, sub, checked);
					}
				});

				_delegateDeclared = false;
			}
		},
	};
}

/* b0x/layout-parts/inspector/panels/docs/articles/article */ 
/** @typedef {import("/js/dist/ComponentScript.js").default} ComponentScript*/

/**
 * @param {ComponentScript} me
 */

export default function (me) {
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  let _b0xComponent;
  let _data;
  return {
    onInit: function () {
      _b0xComponent = Zone.getComponent("b0x");

      _data = me.getData();
    },
    onRender: function () {
      //console.log("###################################################################");
      //_data = me.getData();
    },
    onReady: function () {
      $("pre").addClass("prettyprint");
      $("pre").addClass("linenums");
      PR.prettyPrint();
    },
  };
}

/* b0x/layout-parts/modals/confirm/confirm */ 
/** @typedef {import("/js/dist/ComponentScript.js").default} ComponentScript*/

/**
 * @param {ComponentScript} me
 */

export default function (me) {
  let _b0xComponent;
  let _modal;
  let _callback;

  return {
    onBeforeInit: function () {},
    onInit: function () {
      _b0xComponent = Zone.getComponent("b0x");

      $("#modal-btn-confirm-yes").on("click", function () {
        if (typeof _callback == "function") {
          _callback(true);
          _callback = null;
        }
        _modal.hide();
      });

      $("#modal-btn-confirm-no").on("click", function () {
        if (typeof _callback == "function") {
          _callback(false);
          _callback = null;
        }
        _modal.hide();
      });
    },
    onRender: function () {
      console.log();
    },
    onReady: function () {},
    show: function (callback) {
      _callback = callback;
      if (!_modal) {
        _modal = new bootstrap.Modal("#confirm-modal", {
          backdrop: "static",
          focus: true,
          keyboard: true,
        });
      }
      _modal.show();
    },
  };
}

import ComponentScript from "../ComponentScript.js";
import { Twig } from "./globals.js";
import zone from "../Zone.js";
import "./types.js";

const REWRITABLE_COMPONENT_METHODS = [
  "onBeforeInit",

  "onBeforeChildrenInit",
  "onInit",

  "onBeforeChildrenLoaded",
  "onLoaded",

  "onBeforeChildrenRender",
  "onRender",

  "onBeforeChildrenReady",
  "onReady",

  "onBeforeChildrenDeploy",
  "onDeploy",

  "onBeforeChildrenLeave",
  "onLeave",
];

class Component {
  name;
  URN;
  rawComponent;

  data;
  componentData;
  /** @type {TreeData} */
  treeData;

  isLayout;
  isPage;
  isRoot;
  /** @type {boolean?} */
  isStatic;

  state = {
    current: undefined,

    initiated: false,
    loaded: false,

    deployed: false,
    ready: false,

    inOnBeforeInit: false,
    inOnInit: false,
    inRender: false,
    inOnRender: false,
    inOnReady: false,
    inDeploy: false,
    inOnDeploy: false,
    inLeave: false,
    inOnLeave: false,

    reRender: false,
    reDeploy: false,
    reLeave: false,
  };

  eventsInInit = {};
  htmlEventsInInit = {};

  /** @type {ComponentScript} */
  componentScript;

  /** @type {string} */
  selector;
  /** @type {HTMLElement?} */
  container;
  /** @type {Node[]} */
  rootNodes = [];

  appendComponentContainers;

  parent;
  /** @type {{ [componentName: string]: Component }} */
  subComponents = {};

  /**
   * @typedef {{
   * name: string,
   * URN: string,
   * rawComponent: RawComponent,
   * data: {},
   * componentData: {},
   * parent: Component,
   * isLayout: boolean,
   * isStatic?: boolean,
   * isPage?: boolean,
   * isRoot?: boolean,
   * }} ComponentParams
   *
   * @param {ComponentParams}
   */
  constructor({
    name,
    URN,
    rawComponent,
    data,
    componentData,
    parent,
    isStatic,
    isPage = false,
    isRoot = false,
    isLayout,
  }) {
    this.name = name;
    this.URN = URN;
    this.rawComponent = rawComponent;
    this.data = data || {};
    this.componentData = componentData || {};
    this.parent = parent;
    this.isStatic = isStatic;
    this.isPage = isPage;
    this.isRoot = isRoot;

    // layout properties
    this.isLayout = isLayout;
    if (isLayout) {
      /** @type {{ [componentName: string]: { [componentName: string]: Component } } | undefined} components added in layout by a component page*/
      this.layoutSubComponents = {};

      /** @type {string} name of the active component page child in layout component */
      this.activeComponentName;
    }

    this.rawComponent.isLogic || (this.appendComponentContainers = {});

    this.initComponentScript();
  }

  /**
   * @returns {Component}
   */
  get rootComponent() {
    let component = this;

    while (component.parent) {
      component = component.parent;
    }

    return component;
  }

  /** @returns {boolean} */
  getIsStatic() {
    return this.isStatic === undefined ? true : this.isStatic;
  }

  initComponentScript() {
    // set componentScript
    try {
      this.componentScript = new ComponentScript({ zone, component: this });

      if (this.rawComponent.scriptComponent) {
        const result = this.rawComponent.scriptComponent.bind(
          this.componentScript
        )(this.componentScript);

        // assign script imported data
        for (const [key, value] of Object.entries(result)) {
          if (REWRITABLE_COMPONENT_METHODS.includes(key)) {
            this[key] = value;
          } else {
            this.componentScript[key] = value;
          }
        }
      }
    } catch (error) {
      Zone.logs.error(["Zone", "componentEvent"], this.rawComponent.id + ": ");
      Zone.logs.error(["Zone", "componentEvent"], error);
    }
  }

  // STEPS
  async onBeforeInit() {}

  /**
   * @param {ComponentStepParams} options
   */
  async beforeInit(options) {
    // set default values
    const { stopPropagation = false, isFirst = true } = options || {};

    Zone.logs.info(
      ["Zone", "Component", "beforeInit"],
      "-- BEFORE INIT COMPONENT -- " + this.name
    );

    this.state.current = "beforeInit";

    if (!stopPropagation) {
      for (const subComponent of Object.values(this.subComponents)) {
        await subComponent?.beforeInit({ isFirst: false });
      }
    }

    this.state.inOnBeforeInit = true;
    try {
      await this.onBeforeInit(options);
    } catch (error) {
      Zone.logs.error(["Zone", "componentEvent"], error);
    }
    this.state.inOnBeforeInit = false;
  }

  async onBeforeChildrenInit() {}
  async onInit() {}

  /**
   * @param {ComponentStepParams} options
   */
  async init(options) {
    // set default values
    const { stopPropagation = false, isFirst = true } = options || {};

    Zone.logs.info(
      ["Zone", "Component", "init"],
      "-- INIT COMPONENT -- " + this.name
    );

    this.state.current = "init";
    this.state.initiated = true;

    this.state.inOnInit = true;
    try {
      await this.onBeforeChildrenInit(options);
    } catch (error) {
      Zone.logs.error(["Zone", "componentEvent"], error);
    }
    this.state.inOnInit = false;

    if (!stopPropagation) {
      for (const subComponent of Object.values(this.subComponents)) {
        await subComponent?.init({ isFirst: false });
      }
    }

    this.state.inOnInit = true;
    try {
      await this.onInit(options);
    } catch (error) {
      Zone.logs.error(["Zone", "componentEvent"], error);
    }
    this.state.inOnInit = false;
  }

  async onBeforeChildrenLoaded() {}
  async onLoaded() {}

  /**
   * @param {ComponentStepParams} options
   */
  async loaded(options) {
    // set default values
    const { stopPropagation = false, isFirst = true } = options || {};

    Zone.logs.info(
      ["Zone", "Component", "loaded"],
      "-- COMPONENT LOADED --" + this.name
    );

    this.state.current = "loaded";
    this.state.loaded = true;

    try {
      await this.onBeforeChildrenLoaded(options);
    } catch (error) {
      Zone.logs.error(["Zone", "componentEvent"], error);
    }

    if (!stopPropagation) {
      for (const subComponent of Object.values(this.subComponents)) {
        await subComponent?.loaded({ isFirst: false });
      }
    }

    try {
      await this.onLoaded(options);
    } catch (error) {
      Zone.logs.error(["Zone", "componentEvent"], error);
    }
  }

  async onBeforeChildrenRender() {}
  async onRender() {}

  /**
   * @param {ComponentStepParams} options
   */
  async render(options) {
    // set default values
    const { stopPropagation = false, isFirst = true } = options || {};

    Zone.logs.info(
      ["Zone", "Component", "render"],
      "-- RENDER COMPONENT --" + this.name
    );

    if (this.state.reRender || this.state.inRender) {
      this.state.reRender = true;
      return;
    }

    this.state.inRender = true;
    this.state.current = "render";
    this.state.deployed = false;
    this.state.ready = false;

    isFirst && zone.createTreeData(this.rootComponent);

    if (this.isPage) {
      this.container = document;
      this.rootNodes = [document.head, document.body];
    } else {
      if (!this.rawComponent.isLogic) {
        // render template
        const template = Twig.twig({
          data: this.rawComponent.template,
        });

        const HTMLRender = await template.renderAsync({
          ...this.treeData,
          root: this.rootComponent.treeData,
          app: zone.conf.app,
        });

        let oldNodeIsInContainer = false;

        for (const oldNode of this.rootNodes) {
          oldNode.parentNode === this.container &&
            (oldNodeIsInContainer = true);

          // detach old root nodes
          oldNode.parentNode?.removeChild(oldNode);
        }

        // create nodes
        const div = oldNodeIsInContainer
          ? this.container
          : document.createElement("div");
        div.innerHTML = HTMLRender;

        // update root nodes
        this.rootNodes = [...div.childNodes];
        this.cleanRootNodes();

        for (const [selector, componentContainers] of Object.entries(
          this.appendComponentContainers
        )) {
          const container = this.querySelector(selector);
          container.append(...componentContainers);
        }
      }
    }

    this.setChildrenContainers();

    this.state.inOnRender = true;
    try {
      await this.onBeforeChildrenRender(options);
    } catch (error) {
      Zone.logs.error(["Zone", "componentEvent"], error);
    }
    this.state.inOnRender = false;

    if (!stopPropagation) {
      for (const subComponent of Object.values(this.subComponents)) {
        await subComponent?.render({ isFirst: false });
      }
    }

    isFirst && (await this.mount());

    this.state.inOnRender = true;
    try {
      await this.onRender(options);
    } catch (error) {
      Zone.logs.error(["Zone", "componentEvent"], error);
    }
    this.state.inOnRender = false;

    this.state.inRender = false;

    if (this.state.reRender) {
      this.state.reRender = false;

      await this.render(options);

      return;
    } else {
      this.state.reRender = false;
    }

    if (isFirst || this.parent.isLayout) {
      this.state.initiated || (await this.init());
    }
    isFirst && (await this.ready());
  }

  /**
   * @param {ComponentStepParams} options
   */
  async refresh(options) {
    // set default values
    const { stopPropagation = false, isFirst = true } = options || {};

    Zone.logs.info(
      ["Zone", "Component", "render"],
      "-- REFRESH COMPONENT --" + this.name
    );

    isFirst && zone.createTreeData(this.rootComponent);

    if (this.isPage) {
      this.container = document;
      this.rootNodes = [document.head, document.body];
    } else {
      if (!this.rawComponent.isLogic) {
        if (!this.isStatic) {
          this.render(); //render the component, only if it's not static
        }
      }
    }

    this.setChildrenContainers();

    if (!stopPropagation) {
      for (const subComponent of Object.values(this.subComponents)) {
        await subComponent?.refresh({ isFirst: false });
      }
    }
  }

  /**
   * @param {ComponentStepParams} options
   */
  async mount(options) {
    // set default values
    const { stopPropagation = false, isFirst = true } = options || {};

    Zone.logs.info(
      ["Zone", "Component", "mount"],
      "-- MOUNT COMPONENT --" + this.name
    );

    if (this.state.reDeploy || this.state.inDeploy) {
      this.state.reDeploy = true;
      return;
    }

    this.state.inDeploy = true;
    this.state.current = "deployed";

    if (!this.isPage && !this.isRoot && !this.rawComponent.isLogic) {
      if (this.selector) {
        const zonePath = this.container.getAttribute("zone-path");
        const zoneName = this.container.getAttribute("zone-name");
        if (zonePath && zonePath !== this.rawComponent.path) {
          Zone.logs.warning(
            ["Zone", "Component"],
            "The container of component " +
              this.name +
              " are already contain the component path " +
              zonePath
          );
        }
        if (zoneName && zoneName !== this.name) {
          Zone.logs.warning(
            ["Zone", "Component"],
            "The container of component " +
              this.name +
              " are already contain the component name " +
              zoneName
          );
        }

        this.container.setAttribute("zone-path", this.rawComponent.path);
        this.container.setAttribute("zone-name", this.name);
      }

      this.container.replaceChildren(...this.rootNodes);
    }

    if (!stopPropagation) {
      for (const subComponent of Object.values(this.subComponents)) {
        await subComponent?.mount({ isFirst: false });
      }
    }

    if (isFirst && (this.isPage || this.parent?.state.deployed)) {
      await this.deployed({ stopPropagation });
    }

    this.state.inDeploy = false;

    if (this.state.reDeploy) {
      this.state.reDeploy = false;

      await this.mount(options);

      return;
    } else {
      this.state.reDeploy = false;
    }
  }

  async onBeforeChildrenDeploy() {}
  async onDeploy() {}

  /**
   * @param {ComponentStepParams} options
   */
  async deployed(options) {
    // set default values
    const { stopPropagation = false, isFirst = true } = options || {};

    Zone.logs.info(
      ["Zone", "Component", "deploy"],
      "-- DEPLOY COMPONENT --" + this.name
    );

    this.state.deployed = true;

    this.state.inOnDeploy = true;
    try {
      await this.onBeforeChildrenDeploy(options);
    } catch (error) {
      Zone.logs.error(["Zone", "componentEvent"], error);
    }
    this.state.inOnDeploy = false;

    if (!stopPropagation) {
      for (const subComponent of Object.values(this.subComponents)) {
        await subComponent?.deployed({ isFirst: false });
      }
    }

    this.state.inOnDeploy = true;
    try {
      await this.onDeploy(options);
    } catch (error) {
      Zone.logs.error(["Zone", "componentEvent"], error);
    }
    this.state.inOnDeploy = false;

    // add init events
    for (const [eventName, handlerObject] of Object.entries(
      this.eventsInInit
    )) {
      for (const handler of Object.values(handlerObject)) {
        zone.on(eventName, handler, { strict: false });
      }
    }
    for (let [eventName, handlerObject] of Object.entries(
      this.htmlEventsInInit
    )) {
      if (eventName.includes("DelegateOn"))
        eventName = eventName.split("DelegateOn")[0];

      for (const handler of Object.values(handlerObject)) {
        this.container.addEventListener(eventName, handler);
      }
    }
  }

  async onBeforeChildrenReady() {}
  async onReady() {}

  /**
   * @param {ComponentStepParams} options
   */
  async ready(options) {
    // set default values
    const { stopPropagation = false, isFirst = true } = options || {};

    Zone.logs.info(
      ["Zone", "Component", "ready"],
      "-- COMPONENT READY --" + this.name
    );

    this.state.current = "ready";
    this.state.ready = true;

    this.state.inOnReady = true;
    try {
      await this.onBeforeChildrenReady(options);
    } catch (error) {
      Zone.logs.error(["Zone", "componentEvent"], error);
    }
    this.state.inOnReady = false;

    if (!stopPropagation) {
      for (const subComponent of Object.values(this.subComponents)) {
        await subComponent?.ready({ isFirst: false });
      }
    }

    this.state.inOnReady = true;
    try {
      await this.onReady(options);
    } catch (error) {
      Zone.logs.error(["Zone", "componentEvent"], error);
    }
    this.state.inOnReady = false;
  }

  /**
   * @param {ComponentStepParams} options
   */
  async unmount(options) {
    // set default values
    const { stopPropagation = false, isFirst = true } = options || {};

    Zone.logs.info(
      ["Zone", "Component", "unmount"],
      "-- UNMOUNT COMPONENT --" + this.name
    );

    if (this.state.reLeave || this.state.inLeave) {
      this.state.reLeave = true;
      return;
    }

    this.state.inLeave = true;
    this.state.current = "leaved";

    if (!this.isPage && !this.rawComponent.isLogic) {
      if (this.selector) {
        this.container.removeAttribute("zone-path");
        this.container.removeAttribute("zone-name");
      }

      this.container.replaceChildren();
    }

    if (!stopPropagation) {
      for (const subComponent of Object.values(this.subComponents)) {
        await subComponent?.unmount({ isFirst: false });
      }
    }

    isFirst && (await this.leaved({ stopPropagation }));

    this.state.inLeave = false;

    if (this.state.reLeave) {
      this.state.reLeave = false;

      await this.unmount(options);

      return;
    } else {
      this.state.reLeave = false;
    }
  }

  async onBeforeChildrenLeave() {}
  async onLeave() {}

  /**
   * @param {ComponentStepParams} options
   */
  async leaved(options) {
    // set default values
    const { stopPropagation = false, isFirst = true } = options || {};

    Zone.logs.info(
      ["Zone", "Component", "leave"],
      "-- LEAVE COMPONENT --" + this.name
    );

    this.state.deployed = false;

    this.state.inOnLeave = true;
    try {
      await this.onBeforeChildrenLeave(options);
    } catch (error) {
      Zone.logs.error(["Zone", "componentEvent"], error);
    }
    this.state.inOnLeave = false;

    if (!stopPropagation) {
      for (const subComponent of Object.values(this.subComponents)) {
        await subComponent?.leaved({ isFirst: false });
      }
    }

    if (this.isLayout) {
      this.subComponents[this.activeComponentName] = undefined;

      if (this.layoutSubComponents[this.activeComponentName]) {
        for (const subComponent of Object.values(
          this.layoutSubComponents[this.activeComponentName]
        )) {
          this.subComponents[subComponent.name] = undefined;
        }
      }
    }

    this.state.inOnLeave = true;
    try {
      await this.onLeave(options);
    } catch (error) {
      Zone.logs.error(["Zone", "componentEvent"], error);
    }
    this.state.inOnLeave = false;

    // remove init events
    for (const [eventName, handlerObject] of Object.entries(
      this.eventsInInit
    )) {
      for (const handler of Object.values(handlerObject)) {
        zone.off(eventName, handler, { strict: false });
      }
    }
    for (let [eventName, handlerObject] of Object.entries(
      this.htmlEventsInInit
    )) {
      if (eventName.includes("DelegateOn"))
        eventName = eventName.split("DelegateOn")[0];

      for (const handler of Object.values(handlerObject)) {
        this.container.removeEventListener(eventName, handler);
      }
    }

    this.state.current = undefined;
  }

  /**
   * @param {ComponentStepParams} options
   */
  async reset(options) {
    // set default values
    const { stopPropagation = false, isFirst = true } = options || {};

    Zone.logs.info(
      ["Zone", "Component", "reset"],
      "-- RESET COMPONENT --" + this.name
    );

    if (this.isLayout) {
      this.subComponents[this.activeComponentName] =
        zone.components[this.URN + ">" + this.activeComponentName];

      if (this.layoutSubComponents[this.activeComponentName]) {
        for (const subComponent of Object.values(
          this.layoutSubComponents[this.activeComponentName]
        )) {
          this.subComponents[subComponent.name] = subComponent;
        }
      }
    }

    for (const [key, subComponent] of [...Object.entries(this.subComponents)]) {
      if (Object.keys(zone.componentsLoaded).includes(subComponent?.name)) {
        this.subComponents[key] = undefined;
      } else {
        if (!stopPropagation) {
          await subComponent?.reset({ isFirst: false });
        }
      }
    }
  }

  // HTML ELEMENTS
  getChildrenContainers() {
    const result = [];

    for (const subComponent of Object.values(this.subComponents)) {
      subComponent?.container && result.push(subComponent.container);
    }

    return result;
  }

  setChildrenContainers() {
    for (const subComponent of Object.values(this.subComponents)) {
      if (subComponent) {
        subComponent.container = undefined;

        if (subComponent.selector) {
          subComponent.container = this.querySelector(subComponent.selector);
        } else {
          const el = this.querySelector(`[zone-name="${subComponent.name}"]`);

          if (el) {
            subComponent.container = el;
            if (!el.style.display && el.tagName.toLowerCase() === "component") {
              el.style.display = "contents";
            }
          }
        }

        if (subComponent.rawComponent.isLogic) {
          // remove the container tag
          this.removeRootNode(subComponent.container);
          subComponent.container = undefined;
        } else {
          if (!subComponent.container && !subComponent.isRoot) {
            Zone.logs.error(
              ["Zone", "Component"],
              "No container found for component " + subComponent.name
            );
          }
        }
      }
    }
  }

  /**
   * delete lines breaks texts nodes
   */
  cleanRootNodes() {
    for (const node of this.rootNodes) {
      if (node.nodeType === Node.TEXT_NODE) {
        const textContent = node.wholeText.trim();

        if (textContent === "") {
          node.remove();
        }
      } else if (node.nodeType === Node.COMMENT_NODE) {
        node.remove();
      }
    }
  }

  /**
   * @param {Node} node
   */
  removeRootNode(node) {
    const index = this.rootNodes.indexOf(node);
    if (index >= 0) {
      this.rootNodes.splice(index, 1);
    }

    node?.parentNode?.removeChild(node);
  }

  /**
   * @param {string} selector
   * @returns {HTMLElement?}
   */
  querySelector(selector) {
    for (const element of this.getElements()) {
      if (element.matches(selector)) {
        return element;
      } else {
        // don't use elements present in subcomponent containers because they can be old elements
        const els = element.querySelectorAll(selector);

        // check if elements are in subcomponent containers
        const containers = this.getChildrenContainers();
        const invalidEls = [];
        for (const container of containers) {
          for (const el of els) {
            if (container !== el && container.contains(el)) {
              invalidEls.push(el);
              break;
            }
          }
        }

        for (const el of els) {
          if (!invalidEls.includes(el)) {
            return el;
          }
        }
      }
    }

    for (const subComponent of Object.values(this.subComponents)) {
      const el = subComponent?.querySelector(selector);
      if (el) return el;
    }
  }

  /**
   * @param {string} selector
   * @returns {HTMLElement[]}
   */
  querySelectorAll(selector) {
    const elements = new Set();

    const containers = this.getChildrenContainers();

    for (const element of this.getElements()) {
      if (element.matches(selector)) {
        elements.add(element);
      }

      const els = Array.from(element.querySelectorAll(selector));

      // check if elements are in subcomponent containers
      const invalidEls = [];
      for (const container of containers) {
        for (const el of els) {
          if (container !== el && container.contains(el)) {
            invalidEls.push(el);
            break;
          }
        }
      }

      for (const el of els) {
        if (!invalidEls.includes(el)) {
          elements.add(el);
        }
      }
    }

    for (const subComponent of Object.values(this.subComponents)) {
      const els = subComponent?.querySelectorAll(selector);

      for (const el of els) {
        elements.add(el);
      }
    }

    return [...elements];
  }

  /**
   * @returns {HTMLElement[]}
   */
  getElements() {
    return this.rootNodes.filter((item) => {
      if (item.nodeType === Node.ELEMENT_NODE) {
        return item;
      }
    });
  }

  // COMPONENT
  /**
   * Get a sub component by this name
   * @param {string} componentName "zone-name"
   */
  getComponent(componentName) {
    let component = this.subComponents[componentName];

    if (!component) {
      for (const subComponent of Object.values(this.subComponents)) {
        const result = subComponent.getComponent(componentName);

        if (result) {
          if (component) {
            Zone.logs.warning(
              ["Zone", "Component"],
              "multiples sub components for name " +
                componentName +
                ", URN: " +
                result.URN
            );
          } else {
            component = result;
            if (!zone.debugMode) break;
          }
        }
      }
    }

    return component;
  }

  /**
   * @param  {string} componentURN component URN absolute from root component or relative from this
   * @returns {Component}
   */
  getSubComponentByURN(componentURN) {
    let component;

    if (componentURN[0] === ">") {
      componentURN = componentURN.substring(1);
      component = this.rootComponent;
    } else {
      component = this;
    }

    for (const name of componentURN.split(">")) {
      if (name === "") {
        break;
      } else if (name === "..") {
        component = component.parent;
      } else {
        component = component.subComponents[name];
      }

      if (!component) break;
    }

    return component;
  }

  /**
   * @param {Component} component
   * @param {string?} selector
   */
  append(component, selector) {
    component.URN = this.URN + ">" + component.URN;
    component.parent = this;
    this.subComponents[component.name] = component;

    if (selector) {
      const container = this.querySelector(selector);
      if (container) {
        const componentEl = document.createElement("loaded-component");
        componentEl.setAttribute("zone-path", component.rawComponent.path);
        componentEl.setAttribute("zone-name", component.name);
        !componentEl.style.display && (componentEl.style.display = "contents");

        container.append(componentEl);

        component.container = componentEl;
        component.isRoot = false;

        this.appendComponentContainers[selector] ||
          (this.appendComponentContainers[selector] = []);
        this.appendComponentContainers[selector].push(componentEl);
      } else {
        Zone.logs.error(
          ["Zone", "Component"],
          "No container found for component " + component.name
        );
      }
    }
  }

  /**
   * @param {string} url
   * @param {string} componentName
   * @param {string?} selector
   *
   * @returns {Promise<Component>}
   */
  async loadComponent(url, componentName, selector) {
    let component = this.subComponents[componentName];
    if (!component || !component.getIsStatic()) {
      component = await zone.loadComponent(url, componentName);

      this.append(component, selector);
    } else {
      await component.reset();
    }

    return component;
  }
}

export default Component;

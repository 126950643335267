/* b0x/layout-parts/inspector/panels/rule/rule */ 
/** @typedef {import("/js/dist/ComponentScript.js").default} ComponentScript*/

/**
 * @param {ComponentScript} me
 */

export default function (me) {
	let $properties;
	let $code;

	let _codeMonacoComponent;
	let _conditionMonacoComponent;
	let _b0xComponent;
	let _serializerComponent;

	let _editorCode;
	let _editorCondition;
	let _data;
	let _codeIsChecked = false;

	let _whatIsLearning = null; //can be null, "condition" or "luacode" depending on where the MIDI Learning was requested

	//Show PropertiesPanel
	function _showPropertiesPanel() {
		$properties = $("#ruleProperties");
		$code = $("#ruleLUACode");
		$properties.parent().removeClass("code");
		$code.hide();
		$properties.show();
		_codeIsChecked = false;
		//$(".needScroll").getNiceScroll().resize();
	}

	function _showCodePanel() {
		$properties = $("#ruleProperties");
		$code = $("#ruleLUACode");
		$properties.parent().addClass("code");

		$properties.hide();
		$code.show();
		_codeIsChecked = true;
		//$(".needScroll").getNiceScroll().resize();
	}

	//debounce used to test and save the luaCode typed in Monaco Editor
	const _debounce = (func, wait) => {
		let timeout;

		return function executedFunction(...args) {
			const later = () => {
				clearTimeout(timeout);
				func(...args);
			};

			clearTimeout(timeout);
			timeout = setTimeout(later, wait);
		};
	};

	//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	return {
		onInit: function () {
			_b0xComponent = Zone.getComponent("b0x");
			_codeMonacoComponent = Zone.getComponent("ruleLUACode");
			_conditionMonacoComponent = Zone.getComponent("ruleCondition");
			_serializerComponent = Zone.getComponent("ruleLUAFields");

			_b0xComponent.on("MIDILearned", function (msg) {
				_data = me.getData();
				console.log("MIDILearned", msg);
				let condition = ""; //building Lua condition depending on the learn config

				if (msg.type != "clock") {
					if (_data.learn.type && msg.type != undefined) {
						condition += "msg.type == '" + msg.type + "'";
					}

					if (_data.learn.d1 && msg.data1 != undefined) {
						if (condition != "") condition += " and ";
						condition += "msg.d1 == " + msg.data1;
					}

					if (_data.learn.d2 && msg.date2 != undefined) {
						if (condition != "") condition += " and ";
						condition += "msg.d2 == " + msg.data2;
					}

					if (_data.learn.channel && msg.channel != undefined) {
						if (condition != "") condition += " and ";
						condition += "msg.channel == " + msg.channel;
					}

					if (_whatIsLearning == "condition") {
						_editorCondition.setValue(condition);
						$(".btlearn").removeClass("on");
						_whatIsLearning = null;
					} else {
						var selection = _editorCode.getSelection();

						_editorCode.executeEdits("", [
							{
								range: selection,
								text: "if (" + condition + ") then \n\nend",
								forceMoveMarkers: true,
							},
						]);
					}
				}
			});
		},
		onRender: function () {
			//console.log("###################################################################");

			_data = me.getData();
			//console.log("rule panel on render", _data);
			let code = "";

			if (_data.code != undefined) {
				if (_data.code.trim() != "") {
					code = atob(_data.code);
				}

				let condition = "";
				if (_data.condition.trim() != "") {
					condition = _data.condition;
				}

				let rule = _b0xComponent.serializeFields("inputrule", code, _data.portNumber, _data.uuid);
				_serializerComponent.render(rule);

				// Condition Field Management  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

				_editorCondition = _conditionMonacoComponent.create({
					value: condition,
					language: "lua",
					renderLineHighlight: "none",
					quickSuggestions: true,
					glyphMargin: false,
					lineDecorationsWidth: 10,
					folding: false,
					fixedOverflowWidgets: true,
					acceptSuggestionOnEnter: "on",
					hover: {
						delay: 100,
					},
					roundedSelection: true,
					contextmenu: false,
					cursorStyle: "line-thin",
					occurrencesHighlight: false,
					links: false,
					minimap: { enabled: false },
					find: {
						// disable `Find`
						addExtraSpaceOnTop: false,
						autoFindInSelection: "never",
						seedSearchStringFromSelection: "never",
					},
					fontSize: 16,
					fontWeight: "normal",
					wordWrap: "off",
					lineNumbers: "off",
					lineNumbersMinChars: 0,
					overviewRulerLanes: 0,
					overviewRulerBorder: false,
					hideCursorInOverviewRuler: true,
					scrollBeyondLastColumn: 0,
					scrollbar: {
						horizontal: "hidden",
						vertical: "hidden",
						alwaysConsumeMouseWheel: false, // avoid can not scroll page when hover monaco
					},
				});

				_editorCondition.onDidChangeCursorPosition((e) => {
					// Monaco tells us the line number after cursor position changed
					if (e.position.lineNumber > 1) {
						// Trim editor value
						_editorCondition.setValue(_editorCondition.getValue().trim());
						// Bring back the cursor to the end of the first line
						_editorCondition.setPosition({
							...e.position,
							// Setting column to Infinity would mean the end of the line
							column: Infinity,
							lineNumber: 1,
						});
					}
				});

				_editorCondition.onDidChangeModelContent(
					_debounce(function (event) {
						let ruleCondition = _editorCondition.getValue();
						let portNumber = _data.portNumber;
						let ruleID = _data.uuid;

						let rule = _b0xComponent.updateRuleCondition(portNumber, ruleID, ruleCondition); //update rule condition code in memory and return if the code is syntacticaly correct

						_serializerComponent.render(rule);

						//console.log(rule);

						let $ruleStateInListEl = $(".midi-rule[data-uuid='" + ruleID + "'] > .state");
						let $ruleStateInPanel = $("[zone-name='rulepanel'] .luacode_icon");

						if (rule.state > 0) {
							$ruleStateInListEl.addClass("syntaxError");
							$ruleStateInPanel.addClass("error");
							$("#ruleConditionError").html(rule.conditionError);
						} else {
							$ruleStateInListEl.removeClass("syntaxError");
							$ruleStateInPanel.removeClass("error");
							$("#ruleConditionError").html("");
						}
					}, 800)
				);

				// Code Field Management  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

				_editorCode = _codeMonacoComponent.create({
					value: code,
					language: "lua",
					theme: "vs-dark",
					automaticLayout: true, // <<== the important part
				});

				//Managing test and saving of the code
				_editorCode.onDidChangeModelContent(
					_debounce(function (event) {
						let ruleCode = _editorCode.getValue();
						let portNumber = _data.portNumber;
						let ruleID = _data.uuid;

						let rule = _b0xComponent.updateRuleCode(portNumber, ruleID, ruleCode);

						_serializerComponent.render(rule);

						let $ruleStateInListEl = $(".midi-rule[data-uuid='" + ruleID + "'] > .state");
						let $ruleStateInPanel = $("[zone-name='rulepanel'] .luacode_icon");

						if (rule.state > 0) {
							$ruleStateInListEl.addClass("syntaxError");
							$ruleStateInPanel.addClass("error");
							$("[zone-name='rulepanel'] .syntax-error-message").html(rule.error);
						} else {
							$ruleStateInListEl.removeClass("syntaxError");
							$ruleStateInPanel.removeClass("error");
							$("[zone-name='rulepanel'] .syntax-error-message").html("");
						}
					}, 500)
				);

				// Assume 'editor' is your Monaco Editor instance
				_editorCode.onKeyDown((e) => {
					// Check if the Ctrl key is pressed
					if (_whatIsLearning == null) {
						if (e.ctrlKey && e.altKey) {
							// Perform your logic here
							console.log("Ctrl+Alt key is pressed while the cursor is active.");
							$("#ruleLUACode").addClass("learning");
							_whatIsLearning = "luacode";
							_b0xComponent.MIDILearning(true);
						}
					}
				});

				_editorCode.onKeyUp((e) => {
					// Check if the Ctrl key is pressed
					if (_whatIsLearning == "luacode") {
						if (e.ctrlKey || e.altKey) {
							$("#ruleLUACode").removeClass("learning");
							_whatIsLearning = null;
							_b0xComponent.MIDILearning(false);
						}
					}
				});

				//temporary !!!!!!

				let $codeCheckbox = $("#ruleCodeCheckbox");
				if (_codeIsChecked) {
					$codeCheckbox.prop("checked", true);
					_showCodePanel();
				} else {
					$codeCheckbox.prop("checked", false);
					_showPropertiesPanel();
				}

				//to move to onInit
				$codeCheckbox.change(function () {
					if (this.checked) {
						_showCodePanel();
					} else {
						_showPropertiesPanel();
					}
				});

				$("#ruleName").on(
					"input",
					_debounce(function (event) {
						console.log(event);
						let $this = $(event.target);
						console.log($this.val());
						let ruleName = $this.val();
						let ruleNewName = "";
						const maxLength = 50;

						const forbiddenPattern = /[^a-zA-Z0-9-\s]/g;

						// Replace all forbidden characters with an empty string
						ruleNewName = ruleName.replace(forbiddenPattern, "");
						ruleNewName.length > maxLength ? ruleNewName.substring(0, maxLength) : ruleNewName;

						if (ruleName != ruleNewName) $this.val(ruleNewName);

						_b0xComponent.updateRuleName(_data.portNumber, _data.uuid, ruleNewName);
					}, 800)
				);

				$(".btlearn").on("click", function () {
					if (_whatIsLearning != "luacode") {
						if ($(this).hasClass("on")) {
							_b0xComponent.MIDILearning(false);
							$(this).removeClass("on");
							_whatIsLearning = null;
						} else {
							_b0xComponent.MIDILearning(true);
							$(this).addClass("on");
							_whatIsLearning = "condition";
						}
					}
				});

				$(".btLearnConf").on("click", function () {
					let $button = $(this);
					let learnWhat = $button.attr("data-what");
					console.log(learnWhat);
					if ($button.hasClass("on")) {
						$button.removeClass("on");
						_b0xComponent.setConfig("learn", learnWhat, false);
					} else {
						$button.addClass("on");
						_b0xComponent.setConfig("learn", learnWhat, true);
					}
				});
			}
		},
	};
}

class Log {

    /** @type {object} */
    conf;
    
    /**
     * logConf: object,
     * 
     * @param {LogParams}
     */
    constructor(logConf) {
        this.conf = logConf;
    }
        
    /**
     * @param {string} severity severity of this log (DEBUG, INFO, NOTICE, WARNING, ERROR, CRITICAL, ALERT, EMERGENCY)
     * @param {string} context context of this log
     * @param {any} content content of this log
     * @param {string} color color (string, hex, rgba...)
     * @param {number} verbose verbose level
     * @param {boolean} trace log trace
     */
    write(severity, context, content, color = null, verbose = 0){
        let severityColor = "white";
        let severityPrefix = "";
        let verbosityLevel = 0;
        let severityStyle = "";

        switch(severity){
            case "DEBUG":
                if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
                    severityColor = "white";
                } else {
                    severityColor = "black";
                }
                severityPrefix = "🐞";
            break;
            case "INFO":
                severityColor = "teal";
                severityPrefix = "ℹ️"
            break;
            case "NOTICE":
                severityColor = "green";
                severityPrefix = "🗒️"
            break;
            case "WARNING":
                severityColor = "darkorange";
                severityPrefix = "🚧";
            break;
            case "ERROR":
                severityColor = "firebrick";
                severityPrefix = "❌"
            break;
            case "CRITICAL":
                severityColor = "crimson";
                severityPrefix = "🔥"
            break;
            case "ALERT":
                severityColor = "red";
                severityPrefix = "🔔"
            break;
            case "EMERGENCY":
                severityColor = "white";
                severityPrefix = "🚨";
                severityStyle = "background-color: #FF0000;"
            break;
        }

        if(color == null) color = severityColor;

        if (typeof this.conf.verbosity ===  'string') {
            if (this.conf.verbosity == "NORMAL") {
                verbosityLevel = 0;
            } else if (this.conf.verbosity == "VERBOSE") {
                verbosityLevel = 1;
            } else if (this.conf.verbosity == "VERY_VERBOSE") {
                verbosityLevel = 2;
            }    
        } else if (typeof this.conf.verbosity === 'number') {
            verbosityLevel = this.conf.verbosity;
        }

        if (verbose <= verbosityLevel && this.conf.severity.includes(severity) && (!this.contextIsExclude(context) && this.contextIsValid(context))) {

            //Format :
            let lenSeverity = severity.length;
            let spacesSeverity = 12 - lenSeverity;
            let severityText = severity + " ".repeat(spacesSeverity);
            
            const d = new Date();

            let iso = d.toISOString().match(/(\d{4}\-\d{2}\-\d{2})T(\d{2}:\d{2}:\d{2})/);
            let dateText = iso[1] + ' ' + iso[2];
            
            if (context instanceof Array) {
                context=context.join(",");
            }

            let lenContext = context.length;

            let spacesContext = 25 - lenContext;
            if (spacesContext <= 0) spacesContext = 0;

            let contextText = "[" + context + "]" + " ".repeat(spacesContext);

            if(typeof content === 'object'){
                console.groupCollapsed('%c ' + severityText + " " + severityPrefix + " " + dateText + " " + contextText, 'color: ' + color + '; ' + severityStyle, content);
                console.trace();
                console.groupEnd();
            } else {
                console.groupCollapsed('%c ' + severityText + " " + severityPrefix + " " + dateText + " " + contextText + content, 'color: ' + color + '; ' + severityStyle);
                console.trace();
                console.groupEnd();
            }
        }
    }

    /**
     * @param {string | Array} context 
     * @returns {boolean}
     */
    contextIsValid(context) {
        if (context instanceof Array) {
            for (const c of context) {
                if (this.contextIsValid(c)) return true;
            }
        } else {
            return this.conf.context == "ALL" || this.conf.context.includes(context);
        }

        return false;
    }
    /**
     * @param {string | Array} context 
     * @returns {boolean}
     */
    contextIsExclude(context) {
        if(this.conf.exclude === undefined) return false;
        if (context instanceof Array) {
            for (const c of context) {
                if (this.contextIsExclude(c)) return true;
            }
        } else {
            return this.conf.exclude == "ALL" || this.conf.exclude.includes(context);
        }

        return false;
    }

    /**
     * @param {string} context context of this log
     * @param {any} content content of this line of log
     * @param {number} verbose verbose level
     */
    debug(context, content, verbose = 0)
    {
        this.write("DEBUG", context, content, null, verbose);
    }

    /**
     * @param {string} context context of this log
     * @param {any} content content of this line of log
     * @param {number} verbose verbose level
     */
    info(context, content, verbose = 0)
    {
        this.write("INFO", context, content, null, verbose);
    }
    
    /**
     * @param {string} context context of this log
     * @param {any} content content of this line of log
     * @param {number} verbose verbose level
     */
    notice(context, content, verbose = 0)
    {
        this.write("NOTICE", context, content, null, verbose);
    }
    
    /**
     * @param {string} context context of this log
     * @param {any} content content of this line of log
     * @param {number} verbose verbose level
     */
    warning(context, content, verbose = 0)
    {
        this.write("WARNING", context, content, null, verbose);
    }
    
    /**
     * @param {string} context context of this log
     * @param {any} content content of this line of log
     * @param {number} verbose verbose level
     */
    error(context, content, verbose = 0)
    {
        this.write("ERROR", context, content, null, verbose);
    }
    
    /**
     * @param {string} context context of this log
     * @param {any} content content of this line of log
     * @param {number} verbose verbose level
     */
    critical(context, content, verbose = 0)
    {
        this.write("CRITICAL", context, content, null, verbose);
    }
    
    /**
     * @param {string} context context of this log
     * @param {any} content content of this line of log
     * @param {number} verbose verbose level
     */
    alert(context, content, verbose = 0)
    {
        this.write("ALERT", context, content, null, verbose);
    }
    
    /**
     * @param {string} context context of this log
     * @param {any} content content of this line of log
     * @param {number} verbose verbose level
     */
    emergency(context, content, verbose = 0)
    {
        this.write("EMERGENCY", context, content, null, verbose);
    }
}

export default Log;
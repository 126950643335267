/* common/monaco-editor/monaco */ 
/** @typedef {import("/zone/js/ComponentScript.js").default} ComponentScript*/

import * as monaco from 'monaco-editor';

self.MonacoEnvironment = {
    getWorkerUrl: function (moduleId, label) {
        if (label === "typescript" || label === "javascript") {
        return "/js/dist/ts.worker.bundle.js";
        }
        return "/js/dist/editor.worker.bundle.js";
    },
};

/**
 * @param {ComponentScript} me
 */

export default function (me) {
    return {
        onRender: async function(){
            
        },
        onReady: async function() {
            /*monaco.editor.create(document.getElementById("mc-" + me.name), {
                value: ["function x() {", '	console.log("Hello world!");', "}"].join("\n"),
                language: "javascript",
            });*/
        },
        getInstance : function(){
            return monaco;
        },
        create : function(options){

            let editor = monaco.editor.create(document.getElementById("mc-" + me.name), options);

            return editor;
        }
    }
}

/* b0x/layout-parts/inspector/toto */ 
/** @typedef {import("/js/dist/ComponentScript.js").default} ComponentScript*/

/** 
 * @param {ComponentScript} me 
 */

export default function (me) {
    let spinnerEl;
    let textEl;
    return {
        onInit: function () {
        },
        onRender: function() {
            spinnerEl = me.querySelector(".loader-btn_spinner");
            textEl = me.querySelector("p");
        },
        onReady: function () {
            if (me.input.text) textEl.innerText = me.input.text;
            if (me.input.loadingBeforeClick) {
                spinnerEl.style.display = "";
            } else {
                textEl.style.display = "";
            }
        },
        setText: function(text) {
            textEl.innerText = text;
        }
    }
}

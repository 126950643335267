/* b0x/layout-parts/inspector/panels/presets/presets */ 
/** @typedef {import("/js/dist/ComponentScript.js").default} ComponentScript*/

/**
 * @param {ComponentScript} me
 */

export default function (me) {
  let _MIDIComponent;

  return {
    onInit: function () {
      _MIDIComponent = Zone.getComponent("midi");

      $(".btTestMIDI").on("click", function (e) {
        e.stopPropagation();

        let $this = $(this);
        let input = $this.attr("data-input");

        _MIDIComponent.truc("MIDIMessageReceived", {
          type: "cc",
          data1: 10,
          data2: 50,
          channel: 1,
          inputID: "input-0",
        });

        console.log("btTestMIDI", input);
        return false;
      });
    },
    onReady: function () {},
  };
}

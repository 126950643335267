/* b0x/b0x.page */ 
//--- IMPORT ---//
/** @typedef {import("/js/dist/ComponentScript.js").default} ComponentScript*/

/**
 * @param {ComponentScript} me
 */
import * as monaco from "monaco-editor";

export default function (me) {
	let _isLocalStorageEnabled = typeof Storage !== "undefined";
	let _ua = navigator.userAgent.toLowerCase();
	let _data;

	let _deviceManufacturer = "Van Ooijen Technische Informatica";

	let _isPassthroughMode = false; //true if a real hardware B0X is plugged to the computer. in that case, Hardware MIDI messages go through the virtual B0X
	let _handshakeStep = 0; //used to check that we are well connected to a L1V3B0X Hardware
	// 0 - we are not connected
	// 1 - we sent a handshake request
	// 2 - we received a handshake challenge from the box
	// 3 - we answered the challenge
	// 4 - we received the acceptance from the b0x
	let _handshakeChallenge = null;
	let _handshakeDevice = null;

	let _MIDIComponent;
	let _LUAComponent;
	let _inspectorComponent;
	let _midiRulesComponent;
	let _devicesModalComponent;

	let _isMIDILearning = false;
	let _LUAMainModule;
	let _LUAGlobalModule;
	let _LUAMIDIModule;
	let _LUAPortModules = {
		midiinput1: null,
		midiinput2: null,
		midiinput3: null,
		midiinput4: null,
		midiinput5: null,
	};

	let _sortableConfig = null;

	const MIDILOGS_INGOING = 1;
	const MIDILOGS_ALLTYPES = 2;
	const MIDILOGS_OUTGOING = 3;

	let _MIDILogsConfig = {
		enabled: false,
		types: MIDILOGS_ALLTYPES,
	};

	let _config = {
		learn: {
			type: true,
			d1: true,
			d2: false,
			channel: true,
		},
	};

	let _rulesFieldsValues = {};

	const OK = 0;
	const CONDITION_SYNTAX_ERROR = 1;
	const CONDITION_RUNTIME_ERROR = 2;
	const CODE_SYNTAX_ERROR = 3;
	const CODE_RUNTIME_ERROR = 4;

	/*the properties for each rule are : 
        uuid : a unique id used to identifiy the rule in memory and in the DOM
        name : the name given to the rule by the user
        condition : the lua condition used to validate if the rule should apply
        state : the runnable state of the rule : 
            0 : the rule is ok, can be run
            1 : there is a syntax error on the condition
            2 : there is a runtime error on the condition
            3 : there is a syntax error on the code
            4 : there is a runtime error on the code
        error : the error message related to the state
        code : a byte64code encryption of the lua code written by the user
    */

	//the template for a new rule to create
	let ruleTemplate = {
		uuid: null,
		name: "My New rule",
		condition: "true",
		state: OK,
		error: null,
		code: "",
	};

	/*b0xSetups = [
    {
      id: "ecba37ea-dc54-4467-ab9b-2dde6658d32b",
      name: "Studio",
      selected: true,
      inputs: [],
      outputs: [],
    },
  ];
  localStorage.setItem("L1V3_Setups", JSON.stringify(b0xSetups));
*/

	//The whole ports and rules in memory
	/*
  let b0xPorts = {
    GLOBAL: {
      compiledCode: "",
      state: OK,
      error: null,
      block: { clock: true, sysex: false, cc: false, notes: false },
      fields: [],
      code: "R0xPQkFMLlNISUZUTEVGVCA9IGZhbHNlIC0tdGhlIHNoaWZ0IGJ1dHRvbiBvZiB0aGUgTGVmdCBVQzQKR0xPQkFMLlNISUZUUklHSFQgPSBmYWxzZSAtLSB0aGUgc2hpZnQgYnV0dG9uIG9mIHRoZSBSaWdodCBVQzQKCi0tIEEgS25vYiBvYmplY3QgdGhhdCBjYW4gYmUgdXNlZCBzZXZlcmFsIHRpbWVzCktub2IgPSB7fQoKZnVuY3Rpb24gS25vYi5uZXcoaW5kZXgpCiAgbG9jYWwgc2VsZiA9IHNldG1ldGF0YWJsZSh7fSwgS25vYikKICBzZWxmLmluZGV4ID0gaW5kZXgKICBzZWxmLnBvc2l0aW9uID0gMAogIHNlbGYudmFsdWUgPSAwCiAgc2VsZi5hcm1lZCA9IGZhbHNlCiAgc2VsZi5hc2xlZXAgPSBmYWxzZQogIHJldHVybiBzZWxmCmVuZAoKLS0gRGVjbGFyaW5nIDggdmVydGljYWwgZmFkZXJzIG9mIHRoZSBGYWRlcmZveApHTE9CQUwuVUM0UmlnaHRfdmVydGljYWxGYWRlcnMgPSB7fQpmb3IgaSA9IDEsIDggZG8gR0xPQkFMLlVDNFJpZ2h0X3ZlcnRpY2FsRmFkZXJzW2ldID0gS25vYi5uZXcoaSkgZW5kCgotLSBEZWNsYXJpbmcgdGhlIDggdmVydGljYWwgZW5jb2RlcnMgb2YgdGhlIEZhZGVyZm94CkdMT0JBTC5VQzRSaWdodF9lbmNvZGVycyA9IHt9CmZvciBpID0gMSwgOCBkbyBHTE9CQUwuVUM0UmlnaHRfZW5jb2RlcnNbaV0gPSBLbm9iLm5ldyhpKSBlbmQKClVDNFJpZ2h0X0dSUDFfSG9yaXpvbnRhbEZhZGVyID0gS25vYi5uZXcoIlVDNFJpZ2h0X0dSUDFfSG9yaXpvbnRhbEZhZGVyIikKCgpmdW5jdGlvbiBHTE9CQUwucmVjb21wdXRlS25vYlZhbHVlKEtub2IpCgogIGxvY2FsIG5ld1ZhbHVlID0gMDsKCiAgaWYgKGtub2IucG9zaXRpb24gPj0gNjMpIHRoZW4gLS1mYWRlciBpcyBhYm92ZSB0aGUgbWlkZGxlIHBvc2l0aW9uLCBuZXdWYWx1ZSB3aWxsIGdvIGRvd24gaWYgaG9yaXpvbnRhbCBmYWRlciBnb2VzIHJpZ2h0CiAgICBuZXdWYWx1ZSA9IG1hcChVQzRSaWdodF9HUlAxX0hvcml6b250YWxGYWRlci5wb3NpdGlvbiwwLDEyNyxrbm9iLnBvc2l0aW9uLDApOyAKICBlbHNlIC0tZmFkZXIgaXMgYmVsbG93IHRoZSBtaWRkbGUgcG9zaXRpb24sIG5ld1ZhbHVlIHdpbGwgZ28gdXAgaWYgaG9yaXpvbnRhbCBmYWRlciBnb2VzIHJpZ2h0CiAgICBuZXdWYWx1ZSA9IG1hcChVQzRSaWdodF9HUlAxX0hvcml6b250YWxGYWRlci5wb3NpdGlvbiwwLDEyNyxrbm9iLnBvc2l0aW9uLDEyNyk7IAogIGVuZAoKICBrbm9iLnZhbHVlID0gbmV3VmFsdWU7CgplbmQ=",
    },
    "midi-input-1": {
      compiledCode: "",
      block: { clock: false, sysex: false, cc: false, notes: false },
      forward: { 1: true, 2: true, 3: true, 4: false, 5: false },
      rules: [
        {
          uuid: "38bae880-80f0-4d0d-a643-e135d8cdbc88",
          name: "A green button is pressed",
          condition: "msg.type == 'cc' and msg.d1 == 10",
          conditionError: null,
          state: OK,
          error: null,
          fields: [],
          code: "bG9jYWwgaW5kZXggPSBtc2cuY2hhbm5lbCAtIDgKbG9jYWwga25vYiA9IEdMT0JBTC5VQzRSaWdodF92ZXJ0aWNhbEZhZGVyc1tpbmRleF0KCmlmIChtc2cuZDIgPT0gMSkgdGhlbgoJLS1hcm1pbmcgdGhlIGNoYW5uZWwKCWtub2IuYXJtZWQgPSB0cnVlCglrbm9iLmFzbGVlcCA9IGZhbHNlCglwcmludCgiKiogRmFkZXIgKCIuLmluZGV4Li4iKSBpcyBhcm1lZCIpCmVsc2UKCS0tdW5hcm1pbmcgdGhlIGNoYW5uZWwKCWtub2IuYXJtZWQgPSBmYWxzZQoJcHJpbnQoIioqIEZhZGVyICgiLi5pbmRleC4uIikgaXMgdW5hcm1lZCIpCmVuZA==",
        },
        {
          uuid: "3383a4c6-75e6-4446-8527-c4ab10f25eb7",
          name: "An encoder is pressed",
          condition:
            "msg.type == 'cc' and msg.d1 == 14 and GLOBAL.SHIFTRIGHT == false",
          conditionError: null,
          state: OK,
          error: null,
          fields: [],
          code: "bG9jYWwga25vYiA9IEdMT0JBTC5VQzRSaWdodF9lbmNvZGVyc1ttc2cuY2hhbm5lbF0KCmlmIChtc2cuZDIgPT0gMSkgdGhlbgoJLS1hcm1pbmcgdGhlIGNoYW5uZWwKCWtub2IuYXJtZWQgPSB0cnVlCglrbm9iLmFzbGVlcCA9IGZhbHNlCglwcmludCgiKiogRW5jb2RlciAoIi4ubXNnLmNoYW5uZWwuLiIpIGlzIGFybWVkIikKZWxzZQoJLS11bmFybWluZyB0aGUgY2hhbm5lbAoJa25vYi5hcm1lZCA9IGZhbHNlCglwcmludCgiKiogRW5jb2RlciAoIi4ubXNnLmNoYW5uZWwuLiIpIGlzIHVuYXJtZWQiKQplbmQ=",
        },
        {
          uuid: "1b6df5da-4c50-471a-ab3b-3e4575f9b10e",
          name: "An encoder is pressed in SHIFT mode",
          condition:
            "msg.type == 'cc' and msg.d1 == 14 and GLOBAL.SHIFTRIGHT == true",
          conditionError: null,
          state: OK,
          error: null,
          fields: [],
          code: "cHJpbnQoIioqIFNBVklORyBQUkVTRVQgSU5UTyAoIi4ubXNnLmNoYW5uZWwuLiIpIHNsb3QiKQ==",
        },
        {
          uuid: "34e8ecaa-f99f-45ce-b63a-b6b1ac6eb935",
          name: "The 8th encoder is pressed",
          condition: "msg.type == 'cc' and msg.d1 == 15",
          conditionError: null,
          state: OK,
          error: null,
          fields: [],
          code: "aWYgKG1zZy5kMiA9PSAxKSB0aGVuCglHTE9CQUwuU0hJRlRSSUdIVCA9IHRydWUKCXByaW50KCIqKiBTSElGVCBSSUdIVCBpcyBhY3RpdmUiKQplbHNlCgktLXVuYXJtaW5nIHRoZSBjaGFubmVsCglHTE9CQUwuU0hJRlRSSUdIVCA9IGZhbHNlCglwcmludCgiKiogU0hJRlQgUklHSFQgaXMgaW5hY3RpdmUiKQoJTUlESS5zZW5kKDIsImNjIiw3LDEyNyw0KQplbmQ=",
        },
        {
          uuid: "198399cc-3c25-4c43-981a-a16c95cc4872",
          name: "A vertical fader is moved",
          condition: "msg.type == 'cc' and msg.d1 == 11",
          conditionError: null,
          state: OK,
          error: null,
          fields: [],
          code: "bG9jYWwgY2hhbm5lbCA9IDEgLS1iYXI6eyJsYWJlbCI6Ik1JREkgQ2hhbm5lbCIsImZyb20iOjEsInRvIjoxNiwiZWRpdGFibGUiOnRydWUsImRlZmF1bHQiOjB9CmxvY2FsIHZvbF8xID0gMCAtLXRleHQ6eyJsYWJlbCI6IlZvbCAxIiwiZWRpdGFibGUiOmZhbHNlLCJkZWZhdWx0IjoiIn0KbG9jYWwgdm9sXzIgPSAwIC0tYmFyOnsibGFiZWwiOiJWb2wgMiIsImZyb20iOjAsInRvIjoxMjcsImVkaXRhYmxlIjp0cnVlLCJkZWZhdWx0IjowfQpsb2NhbCB2b2xfMyA9IDAgLS1iYXI6eyJsYWJlbCI6IlZvbCAzIiwiZnJvbSI6MCwidG8iOjEyNywiZWRpdGFibGUiOmZhbHNlLCJkZWZhdWx0IjowfQpsb2NhbCB2b2xfNCA9IDAgLS1iYXI6eyJsYWJlbCI6IlZvbCA0IiwiZnJvbSI6MCwidG8iOjEyNywiZWRpdGFibGUiOmZhbHNlLCJkZWZhdWx0IjowfQpsb2NhbCB2b2xfNSA9IDAgLS1iYXI6eyJsYWJlbCI6IlZvbCA1IiwiZnJvbSI6MCwidG8iOjEyNywiZWRpdGFibGUiOmZhbHNlLCJkZWZhdWx0IjowfQogCgpsb2NhbCB0cmFjayA9IG1zZy5jaGFubmVsLTg7CnZvbHVtZSA9IG1zZy5kMjsgCgoKaWYgKEdMT0JBTC5TSElGVFJJR0hUID09IHRydWUpIHRoZW4KICAgIHZvbHVtZSA9IE1JREkubWFwKHZvbHVtZSwwLDEyNywxMjcsMCkKZW5kCgppZiAodHJhY2sgPT0gMSkgdGhlbiB2b2xfMSA9IHZvbHVtZTsgCmVsc2VpZiAodHJhY2sgPT0gMikgdGhlbiB2b2xfMiA9IHZvbHVtZTsgCmVsc2VpZiAodHJhY2sgPT0gMykgdGhlbiB2b2xfMyA9IHZvbHVtZTsgCmVsc2VpZiAodHJhY2sgPT0gNCkgdGhlbiB2b2xfNCA9IHZvbHVtZTsgCmVsc2VpZiAodHJhY2sgPT0gNSkgdGhlbiB2b2xfNSA9IHZvbHVtZTsgCmVuZAoKcHJpbnQoIioqIFZvbHVtZSBpcyIsdm9sdW1lLCJmb3IgdHJhY2sgIix0cmFjaywiIG9uIE1JREkgQ2hhbm5lbCIsY2hhbm5lbCkKTUlESS5mb3J3YXJkKCkg",
        },
      ],
    },
    "midi-input-2": {
      compiledCode: "",
      block: { clock: false, sysex: false, cc: false, notes: false },
      forward: { 1: false, 2: true, 3: false, 4: false, 5: false },
      rules: [
        {
          uuid: "1",
          name: "Bla 1",
          condition: "",
          conditionError: null,
          state: OK,
          error: null,
          fields: [],
          code: "",
        },
        {
          uuid: "2",
          name: "Bla 2",
          condition: "",
          conditionError: null,
          state: OK,
          error: null,
          fields: [],
          code: "",
        },
      ],
    },
    "midi-input-3": {
      compiledCode: "",
      block: { clock: false, sysex: false, cc: false, notes: false },
      forward: { 1: false, 2: false, 3: true, 4: false, 5: false },
      rules: [
        {
          uuid: "3",
          name: "Truc 3",
          condition: "",
          conditionError: null,
          state: OK,
          error: null,
          fields: [],
          code: "",
        },
        {
          uuid: "4",
          name: "Truc 4",
          condition: "",
          conditionError: null,
          state: OK,
          error: null,
          fields: [],
          code: "",
        },
      ],
    },
    "midi-input-4": {
      compiledCode: "",
      block: { clock: false, sysex: false, cc: false, notes: false },
      forward: { 1: false, 2: false, 3: false, 4: true, 5: false },
      rules: [
        {
          uuid: "5",
          name: "Machin 5",
          condition: "",
          conditionError: null,
          state: OK,
          error: null,
          fields: [],
          code: "",
        },
      ],
    },
    "midi-input-5": {
      compiledCode: "",
      block: { clock: false, sysex: false, cc: false, notes: false },
      forward: { 1: false, 2: false, 3: false, 4: false, 5: true },
      rules: [],
    },
    "midi-output-1": { compiledCode: "" },
    "midi-output-2": { compiledCode: "" },
    "midi-output-3": { compiledCode: "" },
    "midi-output-4": { compiledCode: "" },
    "midi-output-5": { compiledCode: "" },
  };
  */

	//All the setups of the user (for now, there is only a Default one and the special "Passthrough" that is used when a L1V3B0X hardware is connected (set later during init))
	let b0xSetups = [];

	let currentMIDIDevices = {}; //keeping in memory the list of MIDI devices known during this session

	// Configurations of all the ports of the L1V3B0X

	let b0xPorts = {
		GLOBAL: {
			compiledCode: "",
			state: OK,
			error: null,
			block: {
				clock: false,
				sysex: false,
				cc: false,
				notes: false,
				aftertouch: false,
			},
			fields: [],
			code: "",
		},
		"midi-input-1": {
			compiledCode: "",
			block: {
				clock: false,
				sysex: false,
				cc: false,
				notes: false,
				aftertouch: false,
			},
			forward: { 1: true, 2: true, 3: true, 4: true, 5: true },
			rules: [],
		},
		"midi-input-2": {
			compiledCode: "",
			block: {
				clock: false,
				sysex: false,
				cc: false,
				notes: false,
				aftertouch: false,
			},
			forward: { 1: true, 2: true, 3: true, 4: true, 5: true },
			rules: [],
		},
		"midi-input-3": {
			compiledCode: "",
			block: {
				clock: false,
				sysex: false,
				cc: false,
				notes: false,
				aftertouch: false,
			},
			forward: { 1: true, 2: true, 3: true, 4: true, 5: true },
			rules: [],
		},
		"midi-input-4": {
			compiledCode: "",
			block: {
				clock: false,
				sysex: false,
				cc: false,
				notes: false,
				aftertouch: false,
			},
			forward: { 1: true, 2: true, 3: true, 4: true, 5: true },
			rules: [],
		},
		"midi-input-5": {
			compiledCode: "",
			block: {
				clock: false,
				sysex: false,
				cc: false,
				notes: false,
				aftertouch: false,
			},
			forward: { 1: true, 2: true, 3: true, 4: true, 5: true },
			rules: [],
		},
		"midi-output-1": {
			compiledCode: "",
			block: {
				clock: false,
				sysex: false,
				cc: false,
				notes: false,
				aftertouch: false,
			},
		},
		"midi-output-2": {
			compiledCode: "",
			block: {
				clock: false,
				sysex: false,
				cc: false,
				notes: false,
				aftertouch: false,
			},
		},
		"midi-output-3": {
			compiledCode: "",
			block: {
				clock: false,
				sysex: false,
				cc: false,
				notes: false,
				aftertouch: false,
			},
		},
		"midi-output-4": {
			compiledCode: "",
			block: {
				clock: false,
				sysex: false,
				cc: false,
				notes: false,
				aftertouch: false,
			},
		},
		"midi-output-5": {
			compiledCode: "",
			block: {
				clock: false,
				sysex: false,
				cc: false,
				notes: false,
				aftertouch: false,
			},
		},
	};

	//contains the links from the ports of the virtual L1V3B0X point of view
	//this one is separated from b0xPorts above because we don't to save these in b0xPrograms as they are dependant on the setup
	// this variable must be updated each time :
	// - each time a setup is selected
	// - each time a new MIDI Device is connected or disconnected
	// - each time a device is forgot
	// - each time a port linked value change

	let b0xPortsLinks = {
		"midi-input-1": 0,
		"midi-input-2": 0,
		"midi-input-3": 0,
		"midi-input-4": 0,
		"midi-input-5": 0,
		"midi-output-1": 0,
		"midi-output-2": 0,
		"midi-output-3": 0,
		"midi-output-4": 0,
		"midi-output-5": 0,
	};

	let b0xPresets = {
		"dddc4fcc-ff7e-4643-beec-142f0e108235": {
			name: "Blank",
			type: "rule",
			code: "",
			condition: "true",
		},
		"18d21422-b65d-4e71-b885-b460ec59e831": {
			name: "Group",
			type: "group",
			code: "",
			condition: "true",
		},
		"6976c19b-8c3c-4df5-b32f-4a3b39b40518": {
			name: "Reverse CC",
			type: "rule",
			code: "bG9jYWwgdmFsdWUgPSBtc2cuZDIgLS0tYmFyOnsibGFiZWwiOiJNeSBGaWVsZCBOYW1lIiwiZnJvbSI6MCwidG8iOjEyNywiZWRpdGFibGUiOmZhbHNlLCJkZWZhdWx0IjowLCJvcmllbnRhdGlvbiI6Imhvcml6b250YWwifQpsb2NhbCBuZXcgPSAwIC0tLWJhcjp7ImxhYmVsIjoiTXkgRmllbGQgTmFtZSIsImZyb20iOjAsInRvIjoxMjcsImVkaXRhYmxlIjpmYWxzZSwiZGVmYXVsdCI6MCwib3JpZW50YXRpb24iOiJob3Jpem9udGFsIn0KCm5ldyA9IE1JREkubWFwKHZhbHVlLDAsMTI3LDEyNywwKQpwcmludCgicmVjZWl2ZWQgdmFsdWUgd2FzICIuLiB2YWx1ZSAuLiAiIGJ1dCBuZXcgdmFsdWUgbm93IGlzICIuLiBuZXcpCgpNSURJLnNlbmQoMSwiY2MiLG1zZy5kMSx2YWx1ZSxtc2cuY2hhbm5lbCkKTUlESS5zZW5kKDIsImNjIixtc2cuZDEsbmV3LG1zZy5jaGFubmVsKQo=",
			condition: "true",
		},
		"f2fc474f-9715-4313-b730-aaa862865dc7": {
			name: "Note Quantizer",
			type: "rule",
			code: "",
			condition: "true",
		},
	};

	//old
	// 			code: "bG9jYWwgdmFsdWUgPSBtc2cuZDIgLS1iYXI6eyJsYWJlbCI6Ik15IHZhbHVlIiwiZnJvbSI6MCwidG8iOjEyNywiZWRpdGFibGUiOmZhbHNlLCJkZWZhdWx0IjowfQpsb2NhbCBuZXcgPSAwIC0tYmFyOnsibGFiZWwiOiJNeSBuZXcgdmFsdWUiLCJmcm9tIjowLCJ0byI6MTI3LCJlZGl0YWJsZSI6ZmFsc2UsImRlZmF1bHQiOjB9CgpuZXcgPSBNSURJLm1hcCh2YWx1ZSwwLDEyNywxMjcsMCkKcHJpbnQoInJlY2VpdmVkIHZhbHVlIHdhcyAiLi4gdmFsdWUgLi4gIiBidXQgbmV3IHZhbHVlIG5vdyBpcyAiLi4gbmV3KQoKTUlESS5zZW5kKDIsImNjIixtc2cuZDEsbmV3LG1zZy5jaGFubmVsKQo=",

	let b0xPrograms = {
		default: b0xPorts,
	};

	//Initialize MIDI Setups and Programs ////////////////////////////////////////////////////////////////////////////////////////
	if (_isLocalStorageEnabled) {
		//Loading setup
		let setups = localStorage.getItem("L1V3_Setups");
		if (setups != null) {
			b0xSetups = JSON.parse(setups);
		}

		let _userConfig = localStorage.getItem("L1V3_Config");
		if (_userConfig != null) {
			_config = JSON.parse(_userConfig);
		}

		console.log("CONFIG", _config);

		//b0xSetups = [];

		_b0xSetupsClean();

		//Loading program
		let storedPrograms = localStorage.getItem("L1V3_Programs");
		if (storedPrograms != null) {
			b0xPrograms = JSON.parse(storedPrograms);
			b0xPorts = b0xPrograms["default"];
			console.log("b0xPorts", b0xPorts);
		}
	}

	let _b0xSetupSelected = 0;
	for (var i in b0xSetups) {
		if (b0xSetups[i].selected) {
			_b0xSetupSelected = i;
		}
	}

	let MIDIMessageLog = [];

	//Whare are the infos of the selected port (could be an input or an output)
	let _selectedPortID = null;
	let _selectedPortNumber = null; //the current selected port number "1 to 5"
	let _selectedPortType = null; //output or input

	let _selectedRuleID = null;

	function _check(r) {
		return r.test(_ua);
	}

	function _isWindows() {
		return _check(/windows|win32/);
	}

	function _getLineNumber(str, substring) {
		const index = str.indexOf(substring);
		if (index === -1) {
			return -1; // Substring not found
		}
		// Count the number of newline characters before the found index
		const beforeSubstring = str.substring(0, index);
		const lineNumber = beforeSubstring.split("\n").length;
		return lineNumber;
	}

	function _moveElementAt(arr, fromIndex, toIndex) {
		if (fromIndex >= 0 && fromIndex < arr.length && toIndex >= 0 && toIndex < arr.length) {
			const element = arr.splice(fromIndex, 1)[0]; // Remove the element from the original index
			arr.splice(toIndex, 0, element); // Insert the element at the desired index
		} else {
			throw new Error("Invalid index provided.");
		}
	}

	function _moveElementFromToAt(fromArray, fromIndex, toArray, toIndex) {
		if (fromIndex >= 0 && fromIndex < fromArray.length && toIndex >= 0 && toIndex <= toArray.length) {
			const element = fromArray.splice(fromIndex, 1)[0]; // Remove the element from the original index
			toArray.splice(toIndex, 0, element); // Insert the element at the desired index
		} else {
			throw new Error("Invalid index provided.");
		}
	}

	function _insertElementAt(arr, index, element) {
		if (index >= 0 && index <= arr.length) {
			arr.splice(index, 0, element);
		} else {
			throw new Error("Invalid index provided.");
		}
	}

	//used to write down the Syntax Error retrieved from lua interpreter
	const _encodeHTML = function (rawStr) {
		let encodedStr = null;
		if (rawStr) {
			encodedStr = rawStr.replace(/[\u00A0-\u9999<>\&]/g, function (i) {
				return "&#" + i.charCodeAt(0) + ";";
			});
		}
		return encodedStr;
	};

	const _debounce = (func, wait) => {
		let timeout;

		return function executedFunction(...args) {
			const later = () => {
				clearTimeout(timeout);
				func(...args);
			};

			clearTimeout(timeout);
			timeout = setTimeout(later, wait);
		};
	};

	function uuidv4() {
		return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) => (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16));
	}

	function _getAutoCompletion(what) {
		switch (what) {
			case "global":
				return [
					{
						label: "test",
						kind: monaco.languages.CompletionItemKind.Method,
						insertText: "test()",
						documentation: "Tests the global object",
					},
					{
						label: "truc",
						kind: monaco.languages.CompletionItemKind.Method,
						insertText: "truc()",
						documentation: "Does something with the global object",
					},
				];
				break;
			case "midi":
				return [
					{
						label: "send",
						kind: monaco.languages.CompletionItemKind.Method,
						insertText: "send(output,type,data1,data2,channel)",
						documentation: "Sends MIDI data",
					},
					{
						label: "forward",
						kind: monaco.languages.CompletionItemKind.Method,
						insertText: "forward()",
						documentation: "Forward the original received MIDI message",
					},
					{
						label: "CC",
						kind: monaco.languages.CompletionItemKind.Method,
						insertText: "receive()",
						documentation: "Receives MIDI data",
					},
					{
						label: "Message",
						kind: monaco.languages.CompletionItemKind.Class,
						insertText: "Message",
						documentation: "The received MIDI message",
					},
					{
						label: "map",
						kind: monaco.languages.CompletionItemKind.Method,
						insertText: "map(value,fromLow,toLow,fromHigh,toHigh)",
						documentation: "The received MIDI message",
					},
				];
				break;
			case "msg":
				return [
					{
						label: "type",
						kind: monaco.languages.CompletionItemKind.Method,
						insertText: "type",
						documentation: "The type of MIDI message received",
					},
					{
						label: "d1",
						kind: monaco.languages.CompletionItemKind.Method,
						insertText: "d1",
						documentation: "the first data parameter included in the received MIDI message",
					},
					{
						label: "d2",
						kind: monaco.languages.CompletionItemKind.Method,
						insertText: "d2",
						documentation: "the second data parameter included in the received MIDI message",
					},
					{
						label: "channel",
						kind: monaco.languages.CompletionItemKind.Method,
						insertText: "channel",
						documentation: "the MIDI channel of the received MIDI message",
					},
				];
				break;
			case "fields":
				return [
					{
						label: "bar",
						kind: monaco.languages.CompletionItemKind.Snippet,
						insertText: 'bar:{"label":"My Field Name","from":0,"to":127,"editable":false,"default":0,"orientation":"horizontal"}',
						documentation: "The type of MIDI message received",
					},
					{
						label: "text",
						kind: monaco.languages.CompletionItemKind.Snippet,
						insertText: 'text:{"label":"My Field Name","editable":false,"default":""}',
						documentation: "the first data parameter included in the received MIDI message",
					},
					{
						label: "checkbox",
						kind: monaco.languages.CompletionItemKind.Snippet,
						insertText: 'checkbox:{"label":"My Field Name","editable":false,"default":false,"orientation":"horizontal"}',
						documentation: "the first data parameter included in the received MIDI message",
					},
					{
						label: "separation",
						kind: monaco.languages.CompletionItemKind.Snippet,
						insertText: " separation --------------------------------------------------------------",
						documentation: "",
					},
				];
				break;
		}
	}

	// intellisense for lua in B0X
	function _monacoProposals(range) {
		// returning a static list of proposals, not even looking at the prefix (filtering is done by the Monaco editor),
		// here you could do a server side lookup
		return [
			{
				label: "MIDI",
				kind: monaco.languages.CompletionItemKind.Class,
				insertText: "MIDI",
				documentation: "Represents a MIDI device",
			},
			{
				label: "GLOBAL",
				kind: monaco.languages.CompletionItemKind.Class,
				insertText: "GLOBAL",
				documentation: "Access global data",
			},
		];
	}

	function _getSetups() {
		return b0xSetups;
	}

	function _b0xSetupsClean() {
		//Set the default and passthrough Setup entries in the config if not exist
		let hasL1V3B0XSetup = false;
		let hasDefaultSetup = false;

		for (var i in b0xSetups) {
			let setup = b0xSetups[i];
			if (setup.id == "_default") {
				hasDefaultSetup = true;
			}
			if (setup.id == "_l1v3b0x") {
				hasL1V3B0XSetup = true;
			}
		}

		if (!hasL1V3B0XSetup) {
			b0xSetups.push({
				id: "_l1v3b0x",
				name: "L1V3B0X",
				selected: false,
				inputs: {
					h450713686: {
						id: "",
						hash: "h450713686",
						name: "L1V3B0X",
						manufacturer: "Van Ooijen Technische Informatica",
						linked: 1,
					},
					"h-524097885": {
						id: "",
						hash: "h-524097885",
						name: "MIDIIN2 (L1V3B0X)",
						manufacturer: "Van Ooijen Technische Informatica",
						linked: 2,
					},
					h2070128932: {
						id: "",
						hash: "h2070128932",
						name: "MIDIIN3 (L1V3B0X)",
						manufacturer: "Van Ooijen Technische Informatica",
						linked: 3,
					},
					h369388453: {
						id: "",
						hash: "h369388453",
						name: "MIDIIN4 (L1V3B0X)",
						manufacturer: "Van Ooijen Technische Informatica",
						linked: 4,
					},
					"h-1331352026": {
						id: "",
						hash: "h-1331352026",
						name: "MIDIIN5 (L1V3B0X)",
						manufacturer: "Van Ooijen Technische Informatica",
						linked: 5,
					},
				},
				outputs: {
					h71345357: {
						id: "",
						hash: "h71345357",
						name: "L1V3B0X",
						manufacturer: "Van Ooijen Technische Informatica",
						linked: 1,
					},
					h2041855503: {
						id: "",
						hash: "h2041855503",
						name: "MIDIOUT2 (L1V3B0X)",
						manufacturer: "Van Ooijen Technische Informatica",
						linked: 2,
					},
					h341115024: {
						id: "",
						hash: "h341115024",
						name: "MIDIOUT3 (L1V3B0X)",
						manufacturer: "Van Ooijen Technische Informatica",
						linked: 3,
					},
					"h-1359625455": {
						id: "",
						hash: "h-1359625455",
						name: "MIDIOUT4 (L1V3B0X)",
						manufacturer: "Van Ooijen Technische Informatica",
						linked: 4,
					},
					h1234601362: {
						id: "",
						hash: "h1234601362",
						name: "MIDIOUT5 (L1V3B0X)",
						manufacturer: "Van Ooijen Technische Informatica",
						linked: 5,
					},
				},
			});
		}

		if (!hasDefaultSetup) {
			b0xSetups.push({
				id: "_default",
				name: "Default",
				selected: true,
				inputs: {},
				outputs: {},
			});
		}
	}

	function _selectSetup(destinationID) {
		let setupSelected = b0xSetups[_b0xSetupSelected];
		if (setupSelected.id != destinationID) {
			for (var i in b0xSetups) {
				let newSetup = b0xSetups[i];
				if (newSetup.id == destinationID) {
					b0xSetups[_b0xSetupSelected].selected = false;
					newSetup.selected = true;
					_b0xSetupSelected = i;

					if (destinationID == "_l1v3b0x") {
						_passthroughMode(true);
					} else {
						_passthroughMode(false);
					}

					//update MIDI Setup modal
					_MIDIRefreshDevices();

					return true;
				}
			}
		}
		return false;
	}

	function _saveSetup() {
		let selectedSetup = b0xSetups[_b0xSetupSelected];
		if (selectedSetup.id != "_l1v3b0x") {
			//we never save while being on _l1v3b0x setup
			let setupsToSave = structuredClone(b0xSetups);

			//cleaning first (we don't save the id property in our setups as they are dependant on currently connected devices)
			for (var i in setupsToSave) {
				let setup = setupsToSave[i];
				let portType = "inputs";
				for (var j = 1; j <= 2; j++) {
					if (j == 2) {
						portType = "outputs";
					}

					for (var k in setup[portType]) {
						let device = setup[portType][k];
						device.id = "";
					}
				}
			}
			localStorage.setItem("L1V3_Setups", JSON.stringify(setupsToSave));
			_devicesModalComponent.update();
		}
	}

	function _setConfig(group, property, value) {
		switch (group) {
			case "learn":
				_config.learn[property] = value;
				break;
			default:
				break;
		}
		_saveConfig();
	}

	function _saveConfig() {
		localStorage.setItem("L1V3_Config", JSON.stringify(_config));
	}

	function _saveProgram() {
		localStorage.setItem("L1V3_Programs", JSON.stringify(b0xPrograms));
	}

	function _selectPort(portType, portNumber) {
		//passing null portNumber will only unselect the current selected port

		if (_selectedPortNumber == portNumber && _selectedPortType == portType) {
			return false;
		}
		_inspectorComponent.closePanel();

		let id = null;
		let $connectInputs = $(".connect_inputs");
		//unselect current selected port
		if (_selectedPortID != null) {
			me.find("#" + _selectedPortID).removeClass("selected");
			$connectInputs.removeClass("selected_" + _selectedPortNumber);
		}
		if (portNumber != null) {
			id = "midi-" + portType + "-" + portNumber;

			let $connectInputs = $(".connect_inputs");
			$connectInputs.addClass("selected_" + portNumber);

			let $selectedPort = me.find("#" + id);
			$selectedPort.addClass("selected");
			_selectedPortNumber = parseInt($selectedPort.attr("data-port"), 10);

			if ($selectedPort.hasClass("midi-output")) _selectedPortType = "output";
			if ($selectedPort.hasClass("midi-input")) _selectedPortType = "input";

			if (portType == "input") _midiRulesComponent.display(portNumber);
			else _midiRulesComponent.display(null);
		} else {
			_midiRulesComponent.display(null);
		}
		_selectedPortID = id;
	}

	function _editPort(portType, portNumber) {
		_selectPort(portType, portNumber);

		_inspectorComponent.showPanel(portType + "panel", {
			portNumber: portNumber,
		});
	}

	//passing null will unselect the current selected rule
	function _selectRule(ruleID) {
		if (_selectedRuleID != null) {
			let selectedRule = me.find(".midi-rule[data-uuid='" + _selectedRuleID + "']");
			if (selectedRule == undefined) {
				_selectedRuleID = null;
			} else {
				selectedRule.removeClass("selected");
			}
		}
		if (ruleID != null) {
			me.find(".midi-rule[data-uuid='" + ruleID + "']").addClass("selected");
		}
		_selectedRuleID = ruleID;
	}

	function _getPortRules(portNumber) {
		let portID = "midi-input-" + portNumber;
		return b0xPorts[portID].rules;
	}

	function _getPortSetup(type, portNumber) {
		if (type != "output") type = "input";
		let portID = "midi-" + type + "-" + portNumber;
	}

	function _getPortConfig(type, portNumber) {
		if (type != "output") type = "input";
		let portID = "midi-" + type + "-" + portNumber;

		if (b0xPorts[portID]["block"] == undefined)
			b0xPorts[portID]["block"] = {
				clock: false,
				sysex: false,
				cc: false,
				notes: false,
				aftertouch: false,
			};
		if (b0xPorts[portID]["block"]["cc"] == undefined) b0xPorts[portID]["block"]["cc"] = false;
		if (b0xPorts[portID]["block"]["sysex"] == undefined) b0xPorts[portID]["block"]["sysex"] = false;
		if (b0xPorts[portID]["block"]["clock"] == undefined) b0xPorts[portID]["block"]["clock"] = false;
		if (b0xPorts[portID]["block"]["notes"] == undefined) b0xPorts[portID]["block"]["notes"] = false;
		if (b0xPorts[portID]["block"]["aftertouch"] == undefined) b0xPorts[portID]["block"]["aftertouch"] = false;

		return b0xPorts[portID];
	}

	function _setPortConfig(type, portNumber, group, sub, value) {
		let portID = "midi-" + type + "-" + portNumber;
		if (b0xPorts[portID][group] == undefined) b0xPorts[portID][group] = {};
		if (b0xPorts[portID][group][sub] == undefined) b0xPorts[portID][group][sub] = false;
		b0xPorts[portID][group][sub] = value;
		_saveProgram();
	}

	function _getGlobalConfig() {
		return b0xPorts["GLOBAL"];
	}

	function _setGlobalConfig(group, sub, value) {
		if (b0xPorts["GLOBAL"][group][sub]) {
			b0xPorts["GLOBAL"][group][sub] = value;
			_saveProgram();
		}
	}

	function _getRule(ruleID, portNumber) {
		//NOTE : it is by design that i chose not to add a parameter defining the container ID of the rule
		// i prefer just giving the rule ID, and make the algo search where to find it

		//if (portNumber != undefined) {
		let portRules = _getPortRules(portNumber);
		for (var i = 0; i < portRules.length; i++) {
			let portRule = portRules[i];
			if (portRule.uuid == ruleID) {
				return portRule;
			}
		}

		//we didn't find it in the main area (we need to go deeper in each rule of group type)
		for (var i = 0; i < portRules.length; i++) {
			let portRule = portRules[i];
			if (portRule.type == "group") {
				if (portRule.rules.length > 0) {
					for (var j = 0; j < portRule.rules.length; j++) {
						let subRule = portRule.rules[j];
						if (subRule.uuid == ruleID) {
							return subRule;
						}
					}
				}
			}
		}

		return null;
	}

	function _setRulesFieldsValues(rulesFields) {
		//console.log("_setRulesFieldsValues", rulesFields);
		for (var ruleID in rulesFields) {
			let fields = rulesFields[ruleID];
			if (_rulesFieldsValues[ruleID] == undefined) {
				_rulesFieldsValues[ruleID] = fields;
			} else {
				for (var fieldName in fields) {
					let field = fields[fieldName];
					if (field != null) _rulesFieldsValues[ruleID][fieldName] = field; //to memorize fields values
				}
			}
		}
		//console.log(" >> ", _rulesFieldsValues);
	}

	function _getPortCode(portNumber) {
		let portID = "midi-input-" + portNumber;
		return b0xPorts[portID].compiledCode;
	}

	function _setPortCode(portNumber, portCode) {
		let portID = "midi-input-" + portNumber;
		b0xPorts[portID].compiledCode = portCode;
		_saveProgram();
	}

	//will compile the lua code of every rules for a input port in one LUA module

	function _compileMIDI() {
		let code = `
        if package.loaded["MIDI"] then
            -- Clean the module if it was already included
            package.loaded["MIDI"] = nil
        end
        
        local code = [[--MIDI module
            local MIDI = {}
        
            -- MIDI incorporation ----------------------------------------------
            MIDI = {}
            MIDI.CC = 'cc' --Control Change
            MIDI.NoteOn = 'noteon' --Note On
            MIDI.NoteOff = 'noteoff' --Note Off
            MIDI.Output1 = 1
            MIDI.Output2 = 2
            MIDI.Output3 = 3 
            MIDI.Output4 = 4 
            MIDI.Output5 = 5 
            MIDI.message = msg
       
            function MIDI.map(value, fromLow, fromHigh, toLow, toHigh)
                local fromRange = fromHigh - fromLow
                local toRange = toHigh - toLow
                local scaleFactor = toRange / fromRange

                local newValue = toLow
                if (fromRange ~= 0) then
                  newValue = value - fromLow
                  newValue = newValue * scaleFactor
                  newValue = newValue + toLow
                end
        
                newValue = math.floor(newValue)

                if (toLow <= toHigh) then
                  if (newValue < toLow) then newValue = toLow end
                  if (newValue > toHigh) then newValue = toHigh end
                else
                  if (newValue > toLow) then newValue = toLow end
                  if (newValue < toHigh) then newValue = toHigh end
                end

                return newValue
            end
        
        
            -- Return the module
            return MIDI
        ]]
        
        local loader = load(code)
        package.preload["MIDI"] = loader
        `;

		//console.log("MIDI", code);
		_LUAMIDIModule = _LUAComponent.compile(code, "MIDI");
	}

	function _compileGlobal() {
		let global = _getGlobalConfig();

		//if (global.error == null) {
		let globalCode = global.code;
		if (globalCode.trim() != "") globalCode = atob(globalCode);
		globalCode = globalCode.trim();

		let code =
			`
			-- Clean the module if it was already included
			if package.loaded["GLOBAL"] then
				package.loaded["GLOBAL"] = nil
			end
	
			local code = [[--GLOBAL module
			  local GLOBAL = {}
			  local MIDI = require("MIDI")
			  --module("GLOBAL", package.seeall)
	
			  ` +
			globalCode +
			`
	
			  -- Return the module
			  return GLOBAL
			]]
			
			local loader = load(code)
			package.preload["GLOBAL"] = loader
			`;

		//console.log("GLOBAL", code);
		_LUAGlobalModule = _LUAComponent.compile(code, "GLOBAL");
		//}

		_saveProgram();
	}

	function _serializeFields(type, code, portNumber, ruleID) {
		let entry;
		let fields = [];

		if (type == "global") {
			entry = _getGlobalConfig();
		} else {
			entry = _getRule(ruleID, portNumber);
		}

		if (code == undefined) {
			code = atob(entry.code);
		}

		//parsing serialized fields
		if (entry.state == OK) {
			//const codeLines = code.split("\n");

			// Searching this type of line in the code : local myVariableName = something --bar:{"label":"Vol 1","editable":true,"field":"input","type":"int"}
			let regex = /(?:(?:local )*([a-z0-9_]+)[\s]*(?:=[\s]*[a-z0-9_"'.+*/\s\(\)]+)*[\s]*[;]*[\s]*---(bar|text|checkbox|slider)[\s]*:[\s]*{([^}]+)}[\s]*)|(--- separation[\s-]*)/gim;
			let matches = [...code.matchAll(regex)];

			//console.log("_serializeFields Matches", ruleID, matches);

			if (matches.length > 0) {
				for (var fieldIndex in matches) {
					let fieldDef = matches[fieldIndex];
					if (fieldDef[0].substr(0, 14) == "--- separation") {
						//special Separation field
						try {
							const field = {
								control: "separation",
								orientation: "horizontal",
							};
							fields.push(field);
						} catch (error) {}
					} else {
						//Standard fields

						//console.log("fieldDef", fieldDef);

						let fieldName = fieldDef[1];
						let fieldControl = fieldDef[2];
						//parsing json definition of the field first
						let fieldJSON = fieldDef[3].trim();
						if (fieldJSON != "") {
							fieldJSON = "{" + fieldJSON + "}";
							try {
								const field = JSON.parse(fieldJSON);
								field.var = fieldName;
								field.control = fieldControl;
								field.editable = typeof field.editable === "boolean" ? field.editable : false;
								field.label = typeof field.label === "string" ? field.label : "";
								field.orientation = typeof field.orientation === "string" ? field.orientation : "horizontal";
								if (field.orientation != "vertical") field.orientation = "horizontal";

								switch (field.control) {
									case "bar":
										field.from = Number.isInteger(field.from) ? field.from : 0;
										field.to = Number.isInteger(field.to) ? field.to : 127;
										if (field.to < field.from) field.to = field.from;
										if (field.from > field.to) field.from = field.to;
										field.default = Number.isInteger(field.default) && field.value >= field.from && field.default <= field.to ? field.default : field.from;
										break;
									case "checkbox":
										break;
									case "slider":
										field.from = Number.isInteger(field.from) ? field.from : 0;
										field.to = Number.isInteger(field.to) ? field.to : 127;
										if (field.to < field.from) field.to = field.from;
										if (field.from > field.to) field.from = field.to;
										field.default = Number.isInteger(field.default) && field.value >= field.from && field.default <= field.to ? field.default : field.from;
										break;
									case "text":
										field.orientation = "horizontal"; //a text is always horizontal
									default:
										break;
								}

								if (!entry.editables) entry.editables = {};
								if (field.editable) {
									entry.editables[field.var] = field.default; //save all editable fields on each entry
								} else {
									if (entry.editables[field.var]) delete entry.editables[field.var]; //remove editable if in the list
								}

								fields.push(field);
							} catch (error) {}
						}
					}
				}
			}
		}

		if (fields != entry.fields) {
			entry.fields = fields;
		}

		//console.log("_serializeFields Fields", ruleID, fields);

		return entry;
	}

	function _compilePort(portNumber) {
		if (portNumber > 5) {
			throw new Error("Input port " + portNumber + " does not exist");
			return false;
		}

		//let portID = "midi-input-"+portNumber;
		let portRules = _getPortRules(portNumber);
		let portConfig = _getPortConfig("input", portNumber);

		//Prepping rules codes and arguments ////////////////////////////////////////////////////////////////////////////////////////////

		let rulesArgs = "";
		let ruleNumber = 0;
		let rulesCode = "";
		let ruleIncludedCounter = 0;

		for (var portRule in portRules) {
			let rule = portRules[portRule];

			switch (rule.state) {
				case OK:
					//case CONDITION_RUNTIME_ERROR: //to change (we don 't want to compile code that does not work)
					//case CODE_RUNTIME_ERROR: //to change (we don 't want to compile code that does not work)
					let condition = true;
					condition = rule.condition;

					let code = "";
					if (rule.code.trim() != "") code = atob(rule.code);
					code = code.trim();

					let groupCode = "";

					if ((code != "" && rule.type != "group") || rule.type == "group") {
						//Dealing with group Code first
						//Managing Group Rules if any
						if (rule.type == "group" && rule.rules.length > 0) {
							let subRuleIncludedCounter = 0;

							for (var subRuleID in rule.rules) {
								let subRule = rule.rules[subRuleID];

								switch (subRule.state) {
									case OK:
									case CONDITION_RUNTIME_ERROR:
									case CODE_RUNTIME_ERROR:
										let condition = true;
										condition = subRule.condition;

										let subCode = "";
										if (subRule.code.trim() != "") subCode = atob(subRule.code);
										subCode = subCode.trim();

										if (subCode != "") {
											//Managing rules Arguments declarations
											ruleNumber++;
											rulesArgs += ",r" + ruleNumber;

											_serializeFields("inputrule", subCode, portNumber, subRule.uuid);

											//Treating fields variable that are editable (add a line after the variable declaration to force each editabe variable to the passed value)
											const regex = /(?:local )*([a-z0-9_]+)[\s]*(?:=[\s]*[a-z0-9_"'.+*/\s\(\)]+)*[\s]*---(bar|text|checkbox|slider)[\s]*:[\s]*{([^}]+["|']editable["|'][\s]*:[\s]*[true|1][^}]+)}[\s]*\n/gim;
											subCode = subCode.replace(regex, (match, group1, group2, group3, offset, string) => {
												return `${match}${group1} = editables["` + subRule.uuid + `"].${group1}
`;
											});

											if (subRuleIncludedCounter == 0) groupCode += "if ";
											else groupCode += "elseif ";

											groupCode +=
												`(` +
												condition +
												`) then
                        	ruleTriggered='` +
												subRule.uuid +
												`' --tracking what subrule has been triggered
                                  ` +
												subCode +
												`
`;
											groupCode += `  fields["` + subRule.uuid + `"]='{`;

											for (var i = 0; i < subRule.fields.length; i++) {
												if (subRule.fields[i].control != "separation") {
													if (i > 0) groupCode += `,`;
													groupCode += `"` + subRule.fields[i].var + `":' .. tostring(` + subRule.fields[i].var + `) .. '`;
												}
											}
											groupCode += `}'
`;
											subRuleIncludedCounter++;
										}
										break;
								}
							}
							if (subRuleIncludedCounter > 0) {
								groupCode += `end
`;
							}
						}

						if ((groupCode != "" && rule.type == "group") || rule.type != "group") {
							//Managing rules Arguments declarations
							ruleNumber++;
							rulesArgs += ",r" + ruleNumber;

							_serializeFields("inputrule", code, portNumber, rule.uuid);

							//Treating fields variable that are editable (add a line after the variable declaration to force each editabe variable to the passed value)
							const regex = /(?:local )*([a-z0-9_]+)[\s]*(?:=[\s]*[a-z0-9_"'.+*/\s\(\)]+)*[\s]*[;]*[\s]*---(bar|text|checkbox|slider)[\s]*:[\s]*{([^}]+["|']editable["|'][\s]*:[\s]*[true|1][^}]+)}[\s]*\n/gim;
							code = code.replace(regex, (match, group1, group2, group3, offset, string) => {
								return `${match}${group1} = editables["` + rule.uuid + `"].${group1}
`;
							});

							if (ruleIncludedCounter == 0) rulesCode += "if ";
							else rulesCode += "elseif ";

							rulesCode +=
								`(` +
								condition +
								`) then
                		ruleTriggered='` +
								rule.uuid +
								`' --tracking what rule has been triggered
                          ` +
								code +
								`

`;

							if (rule.type != "group") {
								rulesCode += `fields["` + rule.uuid + `"]='{`;
								for (var i = 0; i < rule.fields.length; i++) {
									if (rule.fields[i].control != "separation") {
										if (i > 0) rulesCode += `,`;
										rulesCode += `"` + rule.fields[i].var + `":' .. tostring(` + rule.fields[i].var + `) .. '`;
									}
								}
								rulesCode += `}'
`;
							}

							ruleIncludedCounter++;

							if (groupCode != "") {
								groupCode += `fields["` + rule.uuid + `"]='{`;
								for (var i = 0; i < rule.fields.length; i++) {
									if (rule.fields[i].control != "separation") {
										if (i > 0) groupCode += `,`;
										groupCode += `"` + rule.fields[i].var + `":' .. tostring(` + rule.fields[i].var + `) .. '`;
									}
								}
								groupCode += `}'
`;

								rulesCode += groupCode + `
`;
							}
						}
					}
					break;
			}
		}
		if (ruleIncludedCounter > 0) rulesCode += `end
`;

		portConfig.rulesArgs = rulesArgs; //save this arguments line for later

		//Writing the compiled code

		rulesArgs = ""; //temporary the time i put fields in place

		let compiledCode =
			`
        function midiinput` +
			portNumber +
			`(msg,editables) -- each rNe argument references the editables values for each rule : r1e means: rule #1 editables
 
            local ruleTriggered = nil;
            local forwardMIDIMessage = false;
            local GLOBAL = require("GLOBAL")
            local MIDI = require("MIDI")
			local fields = {}
			local fieldsStr = ''
            --local fields = '{}'
        
            -- STRING incorporation --------------------------------------------

            function string.starts(str,start)
              return string.sub(str,1,string.len(start))==start
            end

            -- MIDI incorporation ----------------------------------------------
            MIDI.currentPort = ` +
			portNumber +
			` -- the current port number

            function MIDI.send(output,type,d1,d2,channel)
                -- save a stack of message to send
                -- print("MIDI.send :",output,type,d1,d2,channel)

                -- do some checks before sending
                if (output ~= output) then output = "null" end
                if (d1 ~= d1) then d1 = "null" end
                if (d2 ~= d2) then d2 = "null" end
                if (channel ~= channel) then channel = "null" end

                if (result ~= "") then 
                    result = result .. ','
                end
                result = result .. '{"output":' .. output .. ',"type":"'.. type .. '","d1":' .. d1 .. ',"d2":' .. d2 .. ',"channel":'..channel..'}' 
            end
        
            function MIDI.forward(output)
                if (forwardMIDIMessage == false) then
                    if (result ~= "") then 
                        result = result .. ','
                    end
                    if output == nil then
                      result = result .. '{"to":0}' --a forward to all authorized outputs
                    else
                      result = result .. '{"to":' .. output .. '}' --a forward to a specified output
                    end
                    forwardMIDIMessage = true
                end
            end

            function onErrorHandler(err)
              local traceback = debug.traceback(err, 2)
              return err .. traceback
            end

            result = '' 

            function RUN()

            ` +
			rulesCode +
			`

            end

            -- preparing the returned JSON value back to JS --------------------------------------------------------------------------------------------------------

            local ok, err = xpcall(RUN,onErrorHandler) 

            if ok then
              result = '"msg":[' .. result .. '],"ruleTriggered":'
              if (ruleTriggered == nil) then
                  result = result .. 'null'
              else
                  result = result .. '"' .. ruleTriggered .. '"'
              end

			  for key, value in pairs(fields) do
			  		if (fieldsStr ~= '') then 
						fieldsStr = fieldsStr .. ','
					end

					if (value == nil) then
						fieldsStr = fieldsStr .. '"' .. key .. '":null'
					else
						fieldsStr = fieldsStr .. '"' .. key .. '":' .. tostring(value)
					end
			  		
			  end	

              result = result .. ',"fields":{' .. fieldsStr .. '}'
              
            else
              result = '"error":"' .. err:gsub('"', "'") ..'","msg":[],"ruleTriggered":'
              if (ruleTriggered == nil) then
                  result = result .. 'null'
              else
                  result = result .. '"' .. ruleTriggered .. '"'
              end

            end

            return '{' .. result .. '}'   
        

        end
        return midiinput` +
			portNumber;

		//console.log("compiledCode", compiledCode);

		// Update the compiled code
		_setPortCode(portNumber, compiledCode);

		// Compile it in LUA

		let moduleName = "midiinput" + portNumber;

		_LUAPortModules[moduleName] = _LUAComponent.compile(compiledCode, moduleName);

		_saveProgram();
	}

	function _getGlobalModule() {
		return _LUAGlobalModule;
	}

	function _getPortModule(portNumber) {
		let module;
		let moduleName = "midiinput" + portNumber;
		if (_LUAPortModules[moduleName]) module = _LUAPortModules[moduleName];
		return module;
	}

	function _MIDILearning(state) {
		_isMIDILearning = state;
	}

	function _MIDILearned(msg) {
		_MIDILearning(false);
		me.emit("MIDILearned", msg);
	}

	//Initialize the devices setup on start
	// Refresh the
	//TO REWRITE COMPLETELY!!
	function _MIDIRefreshDevices(init) {
		//console.log("_MIDIRefreshDevices");
		//clean
		/*
    for (var i in b0xSetups) {
      let setup = b0xSetups[i];
      let portType = "inputs";
      for (var j = 1; j <= 2; j++) {
        if (j == 2) {
          portType = "outputs";
        }

        for (var k in setup[portType]) {
          let device = b0xSetups[i][portType][k];
          if (device == null) {
            b0xSetups[i][portType].splice(k, 1);
          }
        }
      }
    }*/

		//for the current setup ////////////////////////////////////////////////////////////////////////
		let selectedSetup = b0xSetups[_b0xSetupSelected];

		let portType = "inputs";
		let type = "input";
		for (var j = 1; j <= 2; j++) {
			if (j == 2) {
				portType = "outputs";
				type = "output";
			}

			let $port = null;
			let portID = null;
			for (var portNumber = 1; portNumber <= 5; portNumber++) {
				$port = $("#midi-" + type + "-" + portNumber);
				$port.removeClass("linked");
				$port.removeClass("connected");
				$(".portLinkIcon", $port).attr("data-tooltip", "");

				for (var k in selectedSetup[portType]) {
					let setupDevice = selectedSetup[portType][k];
					if (setupDevice != null) {
						if (init == true) {
							setupDevice.id = "";
						}
						if (setupDevice.linked == undefined) setupDevice.linked = 0;

						//Updating The Graphical Ports of the B0X in the DOM
						if (setupDevice.linked > 0) {
							portID = "midi-" + type + "-" + setupDevice.linked;
							$port = $("#" + portID);
							$port.addClass("linked");
							$(".portLinkIcon", $port).attr("data-tooltip", setupDevice.name);

							//check if this device is currently connected
							for (var deviceId in currentMIDIDevices) {
								let MIDIDevice = currentMIDIDevices[deviceId];
								if (MIDIDevice.hash == setupDevice.hash) {
									if (MIDIDevice.state == "connected") {
										$port.addClass("connected");
									}
								}
							}
						}
					}
				}
			}
		}

		// Updating b0xPortsLinks
		for (var j in b0xPortsLinks) {
			b0xPortsLinks[j] = 0;
		}

		for (var j = 1; j <= 2; j++) {
			portType = "inputs";
			type = "input";
			if (j == 2) {
				portType = "outputs";
				type = "output";
			}

			for (var k in selectedSetup[portType]) {
				let setupDevice = selectedSetup[portType][k];
				if (setupDevice.linked > 0) {
					let portID = "midi-" + type + "-" + setupDevice.linked;

					if (setupDevice.id != "") {
						let MIDIDevice = currentMIDIDevices[setupDevice.id];
						if (MIDIDevice != undefined) {
							if (MIDIDevice.state == "connected") {
								//at this stage, the linked is set, and the MIDI Device is known and connected
								b0xPortsLinks[portID] = setupDevice.id;
							}
						}
					}
				}
			}
		}

		_devicesModalComponent.update();
		_saveSetup();
	}

	function _hash(sText) {
		var hash = 0,
			i,
			chr;
		if (sText.length === 0) return hash;
		for (i = 0; i < sText.length; i++) {
			chr = sText.charCodeAt(i);
			hash = (hash << 5) - hash + chr;
			hash |= 0; // Convert to 32bit integer
		}
		return hash;
	}

	function _passthroughMode(go) {
		if (go) {
			if (_handshakeStep == 4) {
				_isPassthroughMode = true;
				_MIDIComponent.send(_handshakeDevice.id, "cc", 125, 1, 16);
			}
		} else {
			_isPassthroughMode = false;
			_MIDIComponent.send(_handshakeDevice.id, "cc", 125, 0, 16);
		}
	}

	function _handshakeStart(device) {
		if (_handshakeStep == 0) {
			_handshakeChallenge = null; // initialize the challenge
			_handshakeDevice = device;
			_MIDIComponent.send(_handshakeDevice.id, "cc", 127, 127, 16);
			_handshakeStep = 1;
		}
	}

	function _handshakeCheck(msg) {
		if (_handshakeStep == 1 && msg.type == "cc" && msg.channel == 16 && msg.data1 == 127) {
			//Challenge received
			_handshakeChallenge = msg.data2;
			_handshakeStep = 2;

			let answer = _handshakeChallenge * 2;
			_MIDIComponent.send(_handshakeDevice.id, "cc", 126, answer, 16);
			return true;
		} else if (_handshakeStep == 2 && msg.type == "cc" && msg.channel == 16 && msg.data1 == 126) {
			//Acceptance received
			let answer = _handshakeChallenge * 2;
			if (msg.data2 == answer) {
				_handshakeStep = 4;
			}
			return true;
		}
		return false;
	}

	//Updating list of MIDI devices in Memory

	function _MIDIUpdateDevices(devices) {
		//we parse the list of all MIDI ports/devices that were updated
		//we first update or add them in the currentMIDIDevices object

		//We will update their equivalent in the list of devices in the currently selected setup
		//If the device does not exist yet, we add it
		//If it exists, we update it
		//If it's the L1V3B0X Hardware, we need to be in the _l1v3b0x setup, and if we're not, we need to select this setup first as the L1V3B0X can only be used in passthrough mode

		let newAddedDevices = [];
		let updatedDevices = {};
		let updatedCount = 0;

		if (devices != null) {
			for (var j in devices) {
				let device = devices[j];
				if (device != null) {
					let hash = "h" + _hash(device.type + "_" + device.name + "_" + device.manufacturer);
					let currentMIDIDevice = currentMIDIDevices[device.id];
					if (currentMIDIDevice != undefined) {
						//We already know it, we update it
						if (currentMIDIDevice.state != device.state) {
							if (device.name == "L1V3B0X" && device.type == "output" && device.state == "connected") {
								//We send a MIDI Signal back to the hardware b0x
								_handshakeStart(device);
							}
							currentMIDIDevice.state = device.state;
							updatedDevices[device.id] = currentMIDIDevice;
							updatedCount++;
						}
					} else {
						//we need to add it
						currentMIDIDevices[device.id] = {
							id: device.id,
							type: device.type,
							name: device.name,
							manufacturer: device.manufacturer,
							state: device.state,
							hash: hash,
						};

						if (device.name == "L1V3B0X" && device.type == "output" && device.state == "connected") {
							//We send a MIDI Signal back to the hardware b0x
							_handshakeStart(device);
						}

						newAddedDevices.push(device);
						updatedDevices[device.id] = currentMIDIDevices[device.id];
						updatedCount++;
					}
				}
			}
		} else {
			//if no devices are passed, we check the whole currentMIDIDevices for the current setup
			updatedCount = Object.keys(currentMIDIDevices).length;
			updatedDevices = currentMIDIDevices;
		}

		if (updatedCount > 0) {
			//Adding or updating entries in current setup
			let selectedSetup = b0xSetups[_b0xSetupSelected];

			for (var k in updatedDevices) {
				let device = updatedDevices[k];
				let hash = "h" + _hash(device.type + "_" + device.name + "_" + device.manufacturer);

				let portType = "inputs";
				if (device.type == "output") {
					portType = "outputs";
				}
				let setupPort = selectedSetup[portType][hash];

				if (setupPort != undefined) {
					//we update the port if already in the setup
					setupPort.id = device.id; //?????
				} else {
					//the port is not in the setup
					if (!_isPassthroughMode) {
						//in other modes than Passthrough, we only add devices that are not related to a L1V3B0X hardware
						if (device.manufacturer != "Van Ooijen Technische Informatica" && !device.name.includes("L1V3B0X")) {
							selectedSetup[portType][hash] = {
								id: device.id,
								name: device.name,
								manufacturer: device.manufacturer,
								linked: 0,
								hash: device.hash,
							};
						}
					}
				}
			}
			_MIDIRefreshDevices();
		}
	}

	function _MIDIGetCurrentDeviceByHash(hash) {
		for (var deviceID in currentMIDIDevices) {
			let device = currentMIDIDevices[deviceID];
			if (device.hash == hash) {
				return device;
				break;
			}
		}
		return null;
	}

	function _MIDIGetCurrentDeviceByName(name, manufacturer) {
		if (manufacturer == undefined) manufacturer = _deviceManufacturer;

		for (var deviceID in currentMIDIDevices) {
			let device = currentMIDIDevices[deviceID];
			if (device.name == name && device.manufacturer == manufacturer) {
				return device;
				break;
			}
		}
		return null;
	}

	function _MIDIGetCurrentSetupDevices(type) {
		let selectedSetup = b0xSetups[_b0xSetupSelected];
		let portType = "inputs";
		if (type == "output") {
			portType = "outputs";
		}
		return selectedSetup[portType];
	}

	function _MIDIGetCurrentSetupDevicesAvailable(type, portNumber) {
		//get MIDI Devices that are not already linked with a port
		let devices = _MIDIGetCurrentSetupDevices(type);
		let availableDevices = [];
		for (var i in devices) {
			let device = devices[i];
			if (device.linked == 0 || device.linked == portNumber) {
				availableDevices.push(device);
			}
		}
		return availableDevices;
	}

	function _MIDIGetDeviceByHash(type, deviceHash) {
		let selectedSetup = b0xSetups[_b0xSetupSelected];

		let portType = "inputs";
		if (type == "output") {
			portType = "outputs";
		}
		return selectedSetup[portType][deviceHash];
	}

	function _linkPort(type, portNumber, deviceHash) {
		let device = _MIDIGetDeviceByHash(type, deviceHash);

		//find if the portNumber is already linked to another device
		let devices = _MIDIGetCurrentSetupDevices(type);
		for (var i in devices) {
			if (devices[i].linked == portNumber) {
				devices[i].linked = 0; //unlink first
			}
		}
		//link new device
		device.linked = parseInt(portNumber);

		_MIDIRefreshDevices();
	}

	function _unlinkPort(type, portNumber) {
		let devices = _MIDIGetCurrentSetupDevices(type);

		for (var i in devices) {
			if (devices[i].linked == portNumber) {
				devices[i].linked = 0;
			}
		}

		_MIDIRefreshDevices();
	}

	//let elems = [];
	/*
  function _flashPort(type, portNumber, pass) {
    let $port = me.find("#midi-" + type + "-" + portNumber + " .portLED .led");

    console.log("coucou", $port.attr("yo")[0]);

    let className = "pass";
    if (pass == null) className = "error";
    else if (!pass) className = "block";

    //$port.addClass(className);
    $port.addClass("flash");

    if ($port.attr("yo")[0] == null) {
      console.log("added");
      //let element = $port.getEl()[0];
      //elems.push(element);

      $port.attr("yo", "true");

      $port.getEl()[0].addEventListener("animationend", (event) => {
        console.log("animend", event);
        $port.getEl()[0].classList.remove(className);
        $port.getEl()[0].classList.remove("flash");
      });
    }
  }*/

	function _flashPort(type, portNumber, pass) {
		//let $port = me.find("#midi-" + type + "-" + portNumber + " .portLED .led");
		//let $port = $("#midi-" + type + "-" + portNumber + " .portLED .led");

		const port = document.querySelector("#midi-" + type + "-" + portNumber + " .portLED .led");

		let color = "#33e329"; //green
		//let gradient = "linear-gradient(0deg,#33e329 0%,#109915 50%,#a6ed99 100%);";
		if (pass == null) {
			color = "#fffc33"; //yellow
			//gradient = "linear-gradient(0deg,#fbe204 0%,#c3b429 50%,#ede599 100%);";
		} else if (!pass) {
			color = "#ad214b"; //red
			//gradient = "linear-gradient(0deg,#fb1304 0%,#c33d29 50%,#edad99 100%);";
		}

		//let letID = "midi-" + type + "-" + portNumber;
		port.animate(
			[
				{
					background: color,
				},
			],
			{
				duration: 50,
				iterations: 1,
			}
		);
	}

	function _flashConnexions(outputsActivated) {
		//guess the last output number that is activated
		let lastOutputActivated = 0;
		for (let i = 4; i >= 0; i--) {
			if (outputsActivated[i]) {
				lastOutputActivated = i + 1;
				break;
			}
		}

		if (lastOutputActivated > 0) $(".connect_outputs").addClass("last_output_" + lastOutputActivated);

		for (let i = 1; i <= 5; i++) {
			if (outputsActivated[i - 1]) {
				$("#connect_output_" + i).addClass("active");
			}
		}

		$(".b0x-rules").addClass("active");

		$("#b0x-midi-rules").on("animationend", () => {
			$(".b0x-rules").removeClass("active");
			$(".connect_outputs").removeClass("last_output_" + lastOutputActivated);
			for (let i = 1; i <= 5; i++) {
				if (outputsActivated[i - 1]) {
					$("#connect_output_" + i).removeClass("active");
				}
			}
		});
	}

	function _logMIDIMessage(type, device, port, pass, msg) {
		if (_MIDILogsConfig.enabled) {
			let $midiLogs = $(".midilogs");

			//console.log("msg", msg);

			if (msg.d1 != undefined) msg.data1 = msg.d1;
			if (msg.d2 != undefined) msg.data2 = msg.d2;

			if (type == "receive" && _MIDILogsConfig.type == MIDILOGS_OUTGOING) {
				return;
			}

			if ((type == "forward" || type == "send") && _MIDILogsConfig.type == MIDILOGS_INGOING) {
				return;
			}

			let newLogEntry = '<div class="midilog-entry">' + '<span class="type">' + type + "</span>" + '<span class="device">' + device + "</span>" + '<span class="port">' + port + "</span>";
			if (pass) {
				newLogEntry += '<span class="pass">ok</span>';
			} else {
				newLogEntry += '<span class="pass blocked">block</span>';
			}
			newLogEntry += '<span class="miditype">' + msg.type + "</span>" + '<span class="midichannel">' + msg.channel + "</span>";

			if (msg.data1 != undefined) {
				newLogEntry += '<span class="midid1">' + msg.data1 + "</span>";
			}

			if (msg.data2 != undefined) {
				newLogEntry += '<span class="midid2">' + msg.data2 + "</span>";
			}
			newLogEntry += "</div>";

			if ($midiLogs.children().length > 90) {
				$midiLogs.children().last().remove();
			}
			$midiLogs.prepend(newLogEntry);
		}
	}

	return {
		onInit: function () {
			Zone.logs.info("ZONE", "**************************************");
			Zone.logs.info("ZONE", "*        WELCOME TO L1V3 B0X         *");
			Zone.logs.info("ZONE", "**************************************");
			Zone.logs.info("localStorage", _isLocalStorageEnabled);

			/*var observer = new MutationObserver(function (mutations) {
        mutations.forEach(function (mutation) {
          mutation.addedNodes.forEach(function (addedNode) {
            console.log(addedNode);
            console.log($(addedNode).parent().get(0));
          });
        });
      });

      observer.observe(document, { childList: true, subtree: true });
      */

			Zone.on("onPageLoad", function () {
				console.log("coucou");
			});

			_data = me.getData();

			if (_data.docs) Zone.getComponent("inspector").showPanel("docspanel");
			if (_data.sb23) Zone.getComponent("SB23Modal").show();

			/*document
        .querySelector(".b0x-main")
        .addEventListener("animationend", function (event) {
          if (event.target.matches(".portLED .led")) {
            console.log("anim end");
            event.target.classList.remove("flash");
          }
        });*/

			//LUA Autocompletion for the B0X (GLOBAL, MIDI, msg, ...)
			monaco.languages.registerCompletionItemProvider("lua", {
				triggerCharacters: [".", "-"],
				autoIndent: true,
				provideCompletionItems: function (model, position) {
					// Check if the cursor is inside a word that matches "MIDI"
					const previousWord = model.getWordUntilPosition({
						lineNumber: position.lineNumber,
						column: position.column - 1,
					});
					const wordUntil = model.getWordUntilPosition(position);
					const wordRange = new monaco.Range(position.lineNumber, wordUntil.startColumn, position.lineNumber, wordUntil.endColumn);
					//const word = model.getValueInRange(wordRange);
					var word = model.getWordUntilPosition(position);
					var range = {
						startLineNumber: position.lineNumber,
						endLineNumber: position.lineNumber,
						startColumn: word.startColumn,
						endColumn: word.endColumn,
					};
					// Get the current line content up to the cursor position
					const currentLine = model.getLineContent(position.lineNumber);
					const currentLineUntilCursor = currentLine.substr(0, position.column);

					// Check if the current line starts with a single dash (comment indicator)
					if (currentLineUntilCursor.match(/---/)) {
						return {
							suggestions: _getAutoCompletion("fields"),
						};
					}

					if (previousWord.word === "GLOBAL") {
						// Return the completion items for the methods of the GLOBAL class
						return {
							suggestions: _getAutoCompletion("global"),
						};
					} else if (previousWord.word === "MIDI") {
						// Return the completion items for the methods of the MIDI class
						return {
							suggestions: _getAutoCompletion("midi"),
						};
					} else if (previousWord.word === "msg") {
						// Return the completion items for the methods of the MIDI class
						return {
							suggestions: _getAutoCompletion("msg"),
						};
					} else if (previousWord.word === "" && word.word === "") {
						// Return the completion items for the methods of the MIDI class
						return {
							suggestions: [],
						};
					} else {
						return {
							suggestions: _monacoProposals(range),
						};
					}
				},
			});

			_inspectorComponent = Zone.getComponent("inspector");
			_midiRulesComponent = Zone.getComponent("midirules");
			_LUAComponent = Zone.getComponent("lua");
			_devicesModalComponent = Zone.getComponent("devicesModal");

			//Zone.log.emergency("ZONE", "Hello, call 911");

			//Implement needScroll only for sections having the needScroll class
			//TODO : try to implement new scroll CSS capabilities
			if (_isWindows()) {
				/*$(".needScroll").niceScroll({
                    cursorborder: "0px solid #fff",
                    cursorcolor : "#FFF",
                    cursoropacitymin: 0, // change opacity when cursor is inactive (scrollabar "hidden" state), range from 1 to 0
                    cursoropacitymax: 0.7,
                    cursorwidth: "3px",            
                    zindex: 10000
                });
    
                _debouncedResize((e) => {
                    $(".needScroll").getNiceScroll().resize();
                });*/
			}

			//Implementing clicks events //////////////////////////////////////////////////////////

			$("#midilogs-enable").on("click", function () {
				let $button = $(this);
				_MIDILogsConfig.enabled = $button.prop("checked");
				console.log("_MIDILogsConfig", _MIDILogsConfig);
			});

			//MIDILOgs Type Switch
			$("#midilogs-toggle-type").on("change", function () {
				let $button = $(this);
				let $label = $("#midilogs-toggle-type-label");
				let value = $button.val();
				if (value == 1) {
					$label.html("Ingoing");
					_MIDILogsConfig.type = MIDILOGS_INGOING;
				} else if (value == 2) {
					$label.html("All types");
					_MIDILogsConfig.type = MIDILOGS_ALLTYPES;
				} else {
					$label.html("Outgoing");
					_MIDILogsConfig.type = MIDILOGS_OUTGOING;
				}
			});

			Zone.find(".midi-port").on("click", function () {
				let portNumber = this.attr("data-port");
				let type = "input";
				if (this.hasClass("midi-output")) type = "output";

				let currentPanelInfos = _inspectorComponent.getCurrentPanelInfos();

				switch (currentPanelInfos.name) {
					case "inputpanel":
					case "outputpanel":
					case "globalpanel":
						_editPort(type, portNumber);
						break;
					case "rulepanel":
					case null:
					default:
						_selectPort(type, portNumber);
						break;
				}
			});

			Zone.find(".midi-port").on("dblclick", function () {
				let portNumber = this.attr("data-port");
				let type = "input";
				if (this.hasClass("midi-output")) type = "output";

				_editPort(type, portNumber);
			});

			Zone.find("#midi-global").on("click", function () {
				_inspectorComponent.showPanel("globalpanel");
			});

			Zone.find("#midilogs-clear").on("click", function () {
				$(".midilogs").html("<div id='anchor'></div>");
			});

			//Warning : rules requires a delegate type event
			Zone.find("#b0x-midi-rules").on("dblclick", ".midi-rule", function () {
				let ruleID = this.attr("data-uuid");
				let rule = null;
				_selectRule(ruleID);
				//console.log(_inspectorComponent);
				//we first need to parse the lua code to display the right fields

				rule = _getRule(ruleID, _selectedPortNumber);

				_inspectorComponent.showPanel("rulepanel", {
					portNumber: _selectedPortNumber,
					ruleID: ruleID,
					fields: rule.fields,
				});
			});

			$("#b0x-midi-rules").on("click", ".btn-closerule", function (event) {
				event.stopPropagation();
				event.cancelBubble = true;

				let ruleID = $(this).parent().attr("data-uuid");

				Zone.getComponent("confirmModal").show(function (response) {
					if (response == true) {
						//deleting the rule
						//finding the rule first in the array and in the DOM

						let found = false;
						let portRules = _getPortRules(_selectedPortNumber);

						//check in the mail rules first
						for (var i = 0; i < portRules.length; i++) {
							let portRule = portRules[i];
							if (portRule.uuid == ruleID) {
								portRules.splice(i, 1);
								found = true;
							}
						}

						if (!found) {
							//we didn't find it in the main area (we need to go deeper in each rule of group type)
							for (var i = 0; i < portRules.length; i++) {
								let portRule = portRules[i];
								if (portRule.type == "group") {
									if (portRule.rules.length > 0) {
										for (var j = 0; j < portRule.rules.length; j++) {
											let subRule = portRule.rules[j];
											if (subRule.uuid == ruleID) {
												portRule.rules.splice(j, 1);
												found = true;
											}
										}
									}
								}
							}
						}

						if (found) {
							$("#b0x-midi-rules .midi-rule[data-uuid='" + ruleID + "'").remove();

							//_saveProgram();
							_compilePort(_selectedPortNumber);
						}

						/*
            for (var index in b0xPorts[_selectedPortID].rules) {
              let rule = b0xPorts[_selectedPortID].rules[index];
              if (rule.uuid == ruleID) {
                b0xPorts[_selectedPortID].rules.splice(index, 1);
                $(
                  "#b0x-midi-rules .midi-rule[data-uuid='" + ruleID + "'"
                ).remove();

                //_saveProgram();
                _compilePort(_selectedPortNumber);
                break;
              }
            }*/
					}
				});
				return false;
			});

			//Make the MIDI B0X Rules sortable //////////////////////////////////////////////////////

			_sortableConfig = {
				group: {
					name: "rules",
					pull: true,
					put: true,
				},
				sort: true, // sorting inside list
				draggable: ".midi-rule",
				animation: 200, // ms, animation speed moving items when sorting, `0` — without animation
				easing: "cubic-bezier(1, 0, 0, 1)",
				direction: "vertical",
				scroll: true, // Enable the plugin. Can be HTMLElement.
				fallbackOnBody: false,
				swapThreshold: 0.65,
				forceFallback: true,
				bubbleScroll: true,
				revertOnSpill: true, // Enable plugin
				onStart: function (event) {
					//evt.oldIndex;  // element index within parent
					//console.log(event.item);
					//$(event.item).get(0).removeClass("rest");
					//evt.item.classList.remove("rest");; // dragged HTMLElement
					//console.log("start drag");
				},
				// Element is chosen
				onChoose: function (/**Event*/ evt) {
					//evt.oldIndex;  // element index within parent
					evt.item.classList.remove("rest"); // dragged HTMLElement
				},
				onUnchoose: function (/**Event*/ evt) {
					// same properties as onEnd
					evt.item.classList.add("rest"); // dragged HTMLElement
				},
				// Called when creating a clone of element
				onClone: function (/**Event*/ evt) {
					var origEl = evt.item;
					var cloneEl = evt.clone;
					//cloneEl.classList.remove("rest");; // dragged HTMLElement
				},
				onMove: function (evt, originalEvent) {
					//evt.dragged.classList.remove("rest");; // dragged HTMLElement
					//evt.draggedRect; // DOMRect {left, top, right, bottom}
					//evt.related.classList.remove("rest"); // HTMLElement on which have guided
					//evt.relatedRect; // DOMRect
					//evt.willInsertAfter; // Boolean that is true if Sortable will insert drag element after target by default
					//originalEvent.clientY; // mouse position

					let movingRuleIsGroup = $(evt.dragged).hasClass("group");
					let destinationDropType = $(evt.to).attr("data-droptype");

					console.log(movingRuleIsGroup, destinationDropType);

					if (movingRuleIsGroup && destinationDropType == "group") {
						//We can't move a group to a group
						return false;
					}

					// return false; — for cancel
					// return -1; — insert before target
					// return 1; — insert after target
					// return true; — keep default insertion point based on the direction
					// return void; — keep default insertion point based on the direction
				},
				// Called when item is spilled
				onSpill: function (/**Event*/ evt) {
					evt.item; // The spilled item
				},
				onEnd: function (/**Event*/ evt) {
					var itemEl = evt.item; // dragged HTMLElement
					itemEl.classList.add("rest");
					//evt.to;    // target list
					//evt.from;  // previous list
					//evt.oldIndex;  // element's old index within old parent
					//evt.newIndex;  // element's new index within new parent
					//evt.oldDraggableIndex; // element's old index within old parent, only counting draggable elements
					//evt.newDraggableIndex; // element's new index within new parent, only counting draggable elements
					//evt.clone // the clone element
					//evt.pullMode;  // when item is in another sortable: `"clone"` if cloning, `true` if moving
					//we need to recompile the current port code

					if (_selectedPortType == "input") {
						let destinationID = $(evt.to).attr("data-uuid");
						let fromID = $(evt.from).attr("data-uuid");
						if (evt.oldIndex != evt.newIndex || (evt.oldIndex == evt.newIndex && destinationID != fromID)) {
							let destinationDropType = $(evt.to).attr("data-droptype");

							let fromDropType = $(evt.from).attr("data-droptype");
							let rule = null;

							let fromArray = b0xPorts[_selectedPortID].rules;
							let toArray = b0xPorts[_selectedPortID].rules;
							if (fromDropType == "group") {
								rule = _getRule(fromID, _selectedPortNumber);
								fromArray = rule.rules;
							}
							if (destinationDropType == "group") {
								rule = _getRule(destinationID, _selectedPortNumber);
								toArray = rule.rules;
							}

							if (fromID == destinationID) {
								//we're moving a rule inside the same area or group
								_moveElementAt(fromArray, evt.oldIndex, evt.newIndex);
							} else {
								//we're moving a rule from an area to another
								_moveElementFromToAt(fromArray, evt.oldIndex, toArray, evt.newIndex);
							}
							console.log("DROPPED");

							_compilePort(_selectedPortNumber);
						}
					}
				},
			};

			var el = document.getElementById("b0x-midi-rules");
			Sortable.create(el, _sortableConfig);

			// PRESETS ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

			let origin;
			var el = document.getElementById("presetsList");
			new Sortable(el, {
				group: {
					name: "rules", //part of the same group as rules to be dropped in the rules area
					pull: "clone",
					put: false, // Do not allow items to be put into this list
				},
				animation: 150,
				sort: false, // To disable sorting: set sort to false,
				forceFallback: true,
				bubbleScroll: true,
				revertOnSpill: true, // Enable plugin
				onStart: function (event) {
					if (_selectedPortType != "input") {
						_selectPort("input", 1); //to ensure at least a b0x port is selected
					}
				},
				onClone: function (/**Event*/ evt) {
					var origEl = evt.item;
					var cloneEl = evt.clone;

					let presetID = $(cloneEl).attr("data-preset-id");
					if (presetID) {
						if (presetID.trim() != "") {
							let presetName = $("p", cloneEl).html();

							origin = $(origEl).html();
							$(origEl).html('<li class="midi-rule animate__faster" data-uuid="new" style=""><label><div>' + presetName + '</div></label><div class="state "></div><div class="btn btn-closerule"><i class="fa-solid fa-xmark"></i></div></li>');
						}
					}
				},
				onMove: function (evt, originalEvent) {
					//evt.dragged.classList.remove("rest");; // dragged HTMLElement
					//evt.draggedRect; // DOMRect {left, top, right, bottom}
					//evt.related.classList.remove("rest"); // HTMLElement on which have guided
					//evt.relatedRect; // DOMRect
					//evt.willInsertAfter; // Boolean that is true if Sortable will insert drag element after target by default
					//originalEvent.clientY; // mouse position

					let movingRuleIsGroup = $(evt.dragged).hasClass("group");
					let destinationDropType = $(evt.to).attr("data-droptype");

					console.log(movingRuleIsGroup, destinationDropType);

					if (movingRuleIsGroup && destinationDropType == "group") {
						//We can't move a group to a group
						return false;
					}

					// return false; — for cancel
					// return -1; — insert before target
					// return 1; — insert after target
					// return true; — keep default insertion point based on the direction
					// return void; — keep default insertion point based on the direction
				},
				onEnd: function (/**Event*/ evt) {
					var itemEl = evt.item; // dragged HTMLElement
					//let destinationID = $(evt.to).attr("id");
					let destinationID = $(evt.to).attr("data-uuid");
					let destinationDropType = $(evt.to).attr("data-droptype");
					let abort = false; //if true, the drop will be aborted and the dragged element will be reset in place

					if (destinationDropType != "main" && destinationDropType != "group") {
						abort = true;
					} else {
						let presetID = $(itemEl).attr("data-preset-id");
						let preset = b0xPresets[presetID];

						switch (destinationDropType) {
							case "main": //dropping in the main area
							case "group": //or in a group already there
								if (preset) {
									let presetName = preset.name;

									if (preset.type == "group" && destinationDropType == "group") {
										//we can't drop a group inside a group (yet)
										abort = true;
									} else {
										//Creating new Rule
										let ruleUUID = uuidv4();

										let newRule = {
											uuid: ruleUUID,
											name: presetName,
											condition: preset.condition,
											conditionError: null,
											state: OK,
											error: null,
											fields: [],
											code: preset.code,
											type: preset.type,
										};

										//let's test the code now
										if (newRule.code == null) newRule.code = "";
										newRule.code = newRule.code.trim();
										let testResult = {};
										if (newRule.code != "") {
											testResult = _LUAComponent.testSyntax(atob(newRule.code));
										} else {
											testResult.success = true;
										}

										if (testResult.success) {
											if (newRule.conditionError) {
												newRule.state = CONDITION_SYNTAX_ERROR;
											} else {
												newRule.state = OK;
											}
											newRule.error = null;
										} else {
											newRule.state = CODE_SYNTAX_ERROR;
											newRule.error = _encodeHTML(testResult.msg);
										}

										//let's test the condition now
										if (newRule.condition == null) newRule.condition = "";
										newRule.condition = newRule.condition.trim();
										testResult = {};

										if (newRule.condition != "") {
											testResult = _LUAComponent.testSyntax(`if (` + newRule.condition + `) then end`);
											if (testResult.success) {
												if (newRule.error) {
													newRule.state = CODE_SYNTAX_ERROR;
												} else {
													newRule.state = OK;
												}
												newRule.conditionError = null;
											} else {
												newRule.state = CONDITION_SYNTAX_ERROR;
												newRule.conditionError = _encodeHTML(testResult.msg);
											}
										} else {
											newRule.state = CONDITION_SYNTAX_ERROR;
											newRule.conditionError = _encodeHTML("This rule needs a condition to be triggered");
										}

										if (preset.type == "group") {
											//New rule is a group

											newRule.rules = [];

											$(evt.item).replaceWith($('<li class="midi-rule group rest animate__faster" data-uuid="' + ruleUUID + '" style=""><label><div class="folder">' + presetName + '</div></label><div class="subrules">' + '<ul data-droptype="group" data-uuid="' + ruleUUID + '"></ul>' + '</div><div class="state "></div><div class="btn btn-closerule"><i class="fa-solid fa-xmark"></i></div></li>'));

											let nest = $(".midi-rule[data-uuid='" + ruleUUID + "'] .subrules ul").get(0);
											Sortable.create(nest, _sortableConfig);

											//Adding the newly created entry in the Program of the selected b0x Port
											_insertElementAt(b0xPorts[_selectedPortID].rules, evt.newIndex, newRule);
										} else {
											//New rule is a simple rule (not a group)

											$(evt.item).replaceWith($('<li class="midi-rule rest animate__faster" data-uuid="' + ruleUUID + '" style=""><label><div>' + presetName + '</div></label><div class="state "></div><div class="btn btn-closerule"><i class="fa-solid fa-xmark"></i></div></li>'));

											//Adding the new entry in the program depending on the destination type

											switch (destinationDropType) {
												case "group":
													//getting the edited destination rule

													let destinationRule = _getRule(destinationID, _selectedPortNumber);

													//Adding the newly created entry in the Program of the selected b0x Port
													_insertElementAt(destinationRule.rules, evt.newIndex, newRule);
													break;
												case "main":
												default:
													//Adding the newly created entry in the Program of the selected b0x Port
													_insertElementAt(b0xPorts[_selectedPortID].rules, evt.newIndex, newRule);
													break;
											}
										}

										itemEl.classList.add("rest");
										if (_selectedPortType == "input") {
											_compilePort(_selectedPortNumber);
										}

										let $ruleStateInListEl = $(".midi-rule[data-uuid='" + ruleUUID + "'] > .state");

										if (newRule.state > 0) {
											$ruleStateInListEl.addClass("syntaxError");
										} else {
											$ruleStateInListEl.removeClass("syntaxError");
										}
									}
								} else {
									abort = true;
								}

								break;

							default:
								abort = true;
								break;
						}
					}

					if (abort) {
						$(evt.item).html(origin);
					}
				},
			});

			// MIDI Implementation //////////////////////////////////////////////////////////////////////////////////////////

			_MIDIComponent = Zone.getComponent("midi");
			_MIDIComponent.on("MIDIMessageReceived", function (msg) {
				//console.log("MIDI Received : "+msg.command+" d1 = "+msg.data1+" d2 = "+msg.data2);

				if (msg.type == "clock") {
					console.log(msg);
					return;
				}

				//in MIDILearn mode, the received message is taken in account for that and only that
				if (_isMIDILearning) {
					console.log(msg);
					if (msg.type != "clock") {
						_MIDILearned(msg);
					}
					return;
				}

				if (_handshakeStep > 0 && _handshakeStep <= 2) {
					if (_handshakeCheck(msg)) {
						return;
					}
				}

				function getBoxOutputDeviceByPortNumber(portNumber) {
					let outputDeviceName = "L1V3B0X";
					if (portNumber > 1) {
						outputDeviceName = "MIDIOUT";
						outputDeviceName += portNumber + " (L1V3B0X)";
					}
					return _MIDIGetCurrentDeviceByName(outputDeviceName);
				}

				let lastOutputActivated = 0;
				let outputsActivated = [false, false, false, false, false];

				let MIDIInputID = msg.inputID;
				let portConfig;
				let outputConfig;
				let globalConfig = _getGlobalConfig();

				//if (globalConfig.error != null) {

				//} else {
				//We check if this MIDI Device is linked with a L1V3B0X Port (in the current setup)
				//if not linked with an actual Port, we ignore the message

				let MIDIDevice = currentMIDIDevices[MIDIInputID];
				let selectedSetup = b0xSetups[_b0xSetupSelected];
				let setupDevice = selectedSetup.inputs[MIDIDevice.hash];

				if (setupDevice != undefined) {
					//the device was found in the current setup
					let linkedPortNumber = setupDevice.linked; //we get the linked port number

					if (linkedPortNumber > 0) {
						//the device is actually linked to the virtual L1V3B0X port
						portConfig = _getPortConfig("input", linkedPortNumber);

						switch (msg.type) {
							case "noteon": //NoteOn (data1 is the note, data2 is the velocity)
							case "noteoff": //NoteOff
							case "cc": //ControlCHange
							case "pb": //pitchbend
							case "aftertouch": //aftertouch
								//do we block ?
								switch (msg.type) {
									case "noteon":
									case "noteoff":
										if (portConfig.block.notes == true) {
											_flashPort("input", linkedPortNumber, false);
											_logMIDIMessage("receive", MIDIDevice.name, "IN " + linkedPortNumber, false, msg);
											if (_isPassthroughMode) {
												let outputDevice = getBoxOutputDeviceByPortNumber(linkedPortNumber);
												_MIDIComponent.send(outputDevice.id, "cc", 125, 2, 16); //SPECIAL MESSAGE TO CHANGE LATER : to notify the box we're blocking the message (for an INPUT)
											}
											return;
										}
										break;
									case "cc":
										if (portConfig.block.cc == true) {
											_flashPort("input", linkedPortNumber, false);
											_logMIDIMessage("receive", MIDIDevice.name, "IN " + linkedPortNumber, false, msg);
											if (_isPassthroughMode) {
												let outputDevice = getBoxOutputDeviceByPortNumber(linkedPortNumber);
												_MIDIComponent.send(outputDevice.id, "cc", 125, 2, 16); //SPECIAL MESSAGE TO CHANGE LATER : to notify the box we're blocking the message (for an INPUT)
											}
											return;
										}
										break;
									case "aftertouch":
										if (portConfig.block.aftertouch == true) {
											_flashPort("input", linkedPortNumber, false);
											_logMIDIMessage("receive", MIDIDevice.name, "IN " + linkedPortNumber, false, msg);
											if (_isPassthroughMode) {
												let outputDevice = getBoxOutputDeviceByPortNumber(linkedPortNumber);
												_MIDIComponent.send(outputDevice.id, "cc", 125, 2, 16); //SPECIAL MESSAGE TO CHANGE LATER : to notify the box we're blocking the message (for an INPUT)
											}
											return;
										}
										break;
								}

								_flashPort("input", linkedPortNumber, true);
								_logMIDIMessage("receive", MIDIDevice.name, "IN " + linkedPortNumber, true, msg);

								if (globalConfig.error != null) {
									//We don't bother check the Lua code if the Global code can't even compile
									const button = document.getElementById("midi-global");

									button.animate([{ background: "#ee404c", color: "#FFF" }], {
										duration: 50,
										iterations: 1,
									});
									return true;
								}

								//retrieving lua code instance of the port
								let codeInstance = _getPortModule(linkedPortNumber);
								if (codeInstance != null) {
									let result = "";

									let editables = {};
									let rules = _getPortRules(linkedPortNumber);
									for (var i = 0; i < rules.length; i++) {
										let portRule = rules[i];
										editables[portRule.uuid] = portRule.editables;
									}

									//execute lua module of the port, getting midi messages to forward, send and editables fields to update
									try {
										result = codeInstance.call(
											{
												type: msg.type,
												d1: msg.data1,
												d2: msg.data2,
												channel: msg.channel,
											},
											editables
										);
									} catch (error) {
										console.error("lua", error); //should never happened as user lua code is try catched (but it might be inside the system code)
									}

									//console.log("result", result);

									if (result != "") {
										result = result.replace(/\t/g, ""); //clean the return of lua
										result = result.replace(/\n/g, ""); //clean the return of lua
										result = result.replace(/nil/g, "null"); //clean the return of lua

										//testing lua result
										//console.log("result", result);
										let LUAresult = JSON.parse(result);

										if (LUAresult.error == undefined) {
											let fullMsg = msg;
											msg.ruleTriggered = LUAresult.ruleTriggered;
											msg.fields = LUAresult.fields;

											//console.log(msg.fields);

											MIDIMessageLog.push(fullMsg);

											//forwarding a MIDI message
											//the forward parameter is an object with the "to" property defining to what outputs we want to forward the MIDI Message
											//if "to" is a number between 1 and 5 (for output 1 to 5).
											//if "to" is 0 (we try to forward the original message to any 5 outputs (if authorized by each output port config))

											function _isMessageBlocked(message, outputNumber) {
												outputConfig = _getPortConfig("output", outputNumber);

												switch (message.type) {
													case "noteon":
													case "noteoff":
														return outputConfig.block.notes;
													case "cc":
														return outputConfig.block.cc;
													case "aftertouch":
														return outputConfig.block.aftertouch;
												}
												return false;
											}

											function _messageForward(forward, originInputNumber, force) {
												if (forward.to < 0 && forward.to > 5) {
													return;
												}

												for (var i = 1; i <= 5; i++) {
													//forwarding only to outputs that accept forwarding

													if (forward.to != 0) {
														if (i != forward.to) {
															continue;
														}
													}

													if (portConfig.forward[i] == true || forward.to == i) {
														outputsActivated[i - 1] = true;
														if (i > lastOutputActivated) lastOutputActivated = i;

														//we now check if this b0x output is linked to a midi port output

														for (var j in selectedSetup.outputs) {
															let setupDevice = selectedSetup.outputs[j];
															let MIDIDevice = _MIDIGetCurrentDeviceByHash(setupDevice.hash);

															if (MIDIDevice != null) {
																if (setupDevice.linked == i && MIDIDevice.state == "connected") {
																	//do we block this message ?
																	if (!_isMessageBlocked(msg, i)) {
																		//Sending the original message (msg)
																		_MIDIComponent.send(MIDIDevice.id, msg.type, msg.data1, msg.data2, msg.channel);
																		_flashPort("output", setupDevice.linked, true);
																		_logMIDIMessage("forward", MIDIDevice.name, "OUT " + setupDevice.linked, true, msg);
																		if (_isPassthroughMode) {
																			let outputDevice = getBoxOutputDeviceByPortNumber(originInputNumber);
																			_MIDIComponent.send(outputDevice.id, "cc", 125, 4, 16); //SPECIAL MESSAGE TO CHANGE LATER : to notify the box the input message actually passed
																		}
																	} else {
																		//Blocked
																		_flashPort("output", i, false);
																		_logMIDIMessage("forward", MIDIDevice.name, "OUT " + i, false, msg);
																		if (_isPassthroughMode) {
																			_MIDIComponent.send(MIDIDevice.id, "cc", 125, 3, 16); //SPECIAL MESSAGE TO CHANGE LATER : to notify the box we're blocking the message (for an OUTPUT)
																		}
																	}

																	break;
																}
															}
														}
													}

													if (forward.to != 0) {
														break;
													}
												}
											}

											if (LUAresult.ruleTriggered != null) {
												//A rule has been triggered, we parse all messages to be sent

												let messagesToSend = LUAresult.msg;

												for (var i = 0; i < messagesToSend.length; i++) {
													let message = messagesToSend[i];

													if (message.to != undefined) {
														// ******** FORWARD ORIGINAL MESSAGE REQUESTED *************************************************************************************************************************************
														_messageForward(message, linkedPortNumber, true);
													} else {
														// ******** SEND NEW MESSAGE REQUESTED *************************************************************************************************************************************
														//A MIDI Message has to be sent
														//we now need to find to what midi output the requested b0x output is linked with

														if (message.output >= 0 && message.output <= 5) {
															for (var outputNb = 1; outputNb <= 5; outputNb++) {
																//if message.output = 0 means all possible outputs
																if (message.output != 0) {
																	if (outputNb != message.output) {
																		continue;
																	}
																}

																if (message.output > lastOutputActivated) lastOutputActivated = message.output;

																for (var j in selectedSetup.outputs) {
																	//let device = selectedSetup.outputs[j];

																	let setupDevice = selectedSetup.outputs[j];
																	let MIDIDevice = _MIDIGetCurrentDeviceByHash(setupDevice.hash);

																	if (setupDevice.linked == outputNb) {
																		outputsActivated[outputNb - 1] = true;

																		if (MIDIDevice != null && MIDIDevice.state == "connected") {
																			//do we block ?
																			if (!_isMessageBlocked(message, outputNb)) {
																				//Pass
																				_MIDIComponent.send(MIDIDevice.id, message.type, message.d1, message.d2, message.channel);
																				_flashPort("output", setupDevice.linked, true);
																				_logMIDIMessage("send", MIDIDevice.name, "OUT " + setupDevice.linked, true, message);
																				if (_isPassthroughMode) {
																					let outputDevice = getBoxOutputDeviceByPortNumber(linkedPortNumber);
																					_MIDIComponent.send(outputDevice.id, "cc", 125, 4, 16); //SPECIAL MESSAGE TO CHANGE LATER : to notify the box the input message actually passed
																				}
																			} else {
																				//Blocked
																				_flashPort("output", i, false);
																				_logMIDIMessage("send", MIDIDevice.name, "OUT " + outputNb, false, message);
																				if (_isPassthroughMode) {
																					_MIDIComponent.send(MIDIDevice.id, "cc", 125, 2, 1); //SPECIAL MESSAGE TO CHANGE LATER : to notify the box we're blocking the message
																				}
																			}
																		}
																		break;
																	}
																}

																if (message.output != 0) {
																	break;
																}
															}
														}
													}
												}

												//Visual feedback
												//we flash the rule (which also flash connected input and outputs)
												if (linkedPortNumber == _selectedPortNumber && _selectedPortType == "input") {
													//Activating connexions visual feedback if the right port is currently selected

													//visual feedbacks for outputs
													_flashConnexions(outputsActivated);
												}

												_midiRulesComponent.flashRule(LUAresult.ruleTriggered);

												_setRulesFieldsValues(msg.fields);
												//we update the serializer component
												me.emit("FieldsUpdated", {
													portNumber: linkedPortNumber,
													ruleID: LUAresult.ruleTriggered,
													fields: msg.fields,
												});
											} else {
												// FORWARDING BY DEFAULT *****************************************************************************************************
												//no rule was triggered, we check if we forward the original MIDI message to an output
												//forwarding only to outputs that accept forwarding
												//console.log(portConfig);

												_messageForward({ to: 0 }, linkedPortNumber, false);

												//Visual Feedback
												if (linkedPortNumber == _selectedPortNumber && _selectedPortType == "input") {
													//Activating connexions visual feedback if the right port is currently selected

													//visual feedbacks for outputs
													_flashConnexions(outputsActivated);
												}
											}
										} else {
											console.error("lua", LUAresult.error);

											if (LUAresult.ruleTriggered != null) {
												let rule = _getRule(LUAresult.ruleTriggered, linkedPortNumber);
												rule.state = CONDITION_RUNTIME_ERROR; //flag the rule having a runtime issue (it will prevent it to be compiled until next change in the code)

												//trying to establish what line

												//let regex = /\[string 'midiinput([0-9])'\]:([0-9]+):([a-zA-Z0-9\(\)\.]+),,,[\.]*/gim;
												//Analyzinf LUA debug trace to identify the faulty module, the faulty rule and get a usable line number for the user
												let regex = /\[string '(midiinput[0-9]|--MIDI module...|--GLOBAL module...)'\]:([0-9]+):([a-zA-Z0-9\s'\(\)\.]+)/gim;

												let matches = [...LUAresult.error.matchAll(regex)];

												//console.log("matches", LUAresult.error, matches);

												let errorLine = 0;
												let errorMessage = "";
												let errorFile = "";

												if (matches.length > 0) {
													let error = matches[0];
													//console.log("error", error);
													errorFile = error[1];
													errorLine = error[2];
													errorMessage = error[3];

													switch (errorFile) {
														case "--MIDI module...":
															for (var i = 1; i < matches.length; i++) {
																let subError = matches[i];
																if (subError[0].includes("in function 'RUN'")) {
																	errorLine = subError[2]; //get the real error line
																	break;
																}
															}
															break;
														case "--GLOBAL module...":
															errorLine += 7;

															break;
														default:
															break;
													}
												}

												let errorFull = "";
												errorFull = "error occured outside of this rule : " + errorMessage;

												let portCode = _getPortCode(linkedPortNumber);
												let searchRuleString = "ruleTriggered='" + LUAresult.ruleTriggered + "' --tracking what rule has been triggered";
												let ruleRealPosition = _getLineNumber(portCode, searchRuleString);

												let position = errorLine - ruleRealPosition;
												errorFull = "line (" + position + ") : " + errorMessage;

												rule.error = _encodeHTML(errorFull);

												_midiRulesComponent.flashRule(LUAresult.ruleTriggered, false);

												let $ruleStateInListEl = $(".midi-rule[data-uuid='" + LUAresult.ruleTriggered + "'] > .state");

												if (rule.state > 0) {
													$ruleStateInListEl.addClass("syntaxError");
												} else {
													$ruleStateInListEl.removeClass("syntaxError");
												}

												//TODO : we don't recompile yet (we need to find first a way reinitialize global variables as a recompile reboot everything)
												//_compilePort(linkedPortNumber);
											}

											//Visual Feedback
											if (linkedPortNumber == _selectedPortNumber && _selectedPortType == "input") {
												//Activating connexions visual feedback if the right port is currently selected

												//visual feedbacks for outputs
												_flashConnexions(outputsActivated);
											}
										}
									}
								}

								break;
							case "clock": //Clock
								console.log("clock received", msg);
								if (portConfig.block.clock == true) {
									_logMIDIMessage("receive", MIDIDevice.name, "IN " + linkedPortNumber, false, msg);
									return;
								} else {
									_logMIDIMessage("receive", MIDIDevice.name, "IN " + linkedPortNumber, true, msg);
								}
								break;
						}
					}
				}
				//}

				//console.log(MIDIMessageLog);
			});

			/*_MIDIComponent.on("MIDIMessageSent", function (msg) {
        //console.log("MIDI Sent : ",msg);

        let $input = me.find("#midi-" + msg.outputID + " .portLED");
        $input.addClass("on");
        setTimeout(function () {
          $input.removeClass("on");
        }, 100);
      });*/

			_MIDIRefreshDevices(true); //call it first at initialization

			_MIDIComponent.on("MIDIInputsUpdated", _MIDIUpdateDevices);
			_MIDIComponent.on("MIDIOutputsUpdated", _MIDIUpdateDevices);

			// Inspector Panel watching
			_inspectorComponent.on("panelClosed", function (panelName) {
				switch (panelName) {
					case "rulepanel": //a rule panel was closed
						_selectRule(null); //unselect the current rule

						/*_inspectorComponent.showPanel("inputpanel", {
              portNumber: _selectedPortNumber,
            });*/
						break;
				}
			});

			// LUA Implementation //////////////////////////////////////////////////////////////////////////////////////////

			//testfengari();
			_LUAComponent = Zone.getComponent("lua");

			let global = _getGlobalConfig();
			if (global.error == null) {
				$(".globalButtonArea .luacode_icon").removeClass("error");
			} else {
				$(".globalButtonArea .luacode_icon").addClass("error");
			}

			me.compileAll(); //first compilation
			me.getLUAGlobals(); //test if global code has no runtime error

			/*
            let ok;
            let luacode;

            //const interop = require('fengari/fengari-interop')
            //const fengari = require('fengari/fengari')
            const lua = fengari.lua
            const lauxlib = fengari.lauxlib
            const lualib   = fengari.lualib
            const L = lauxlib.luaL_newstate()

            

            luacode = `
              print ('Lua: initializing lua code')
              js = require "js"
              js.global.foo = function() print("WEEE") end
            `

            // open standard libraries 
            
            lualib.luaL_openlibs(L)
            lauxlib.luaL_requiref(L, fengari.to_luastring("js"), fengari.interop.luaopen_js, 1)
            lua.lua_pop(L, 1) // remove lib
            ok = lauxlib.luaL_dostring(L, fengari.to_luastring(luacode))
            if (ok) return console.log(`Error pcalling: ${ok} ${lua.lua_tojsstring(L, -1)}`)
            global.foo()
            */

			/*
            lualib.luaL_openlibs(L)
            const code = `
            print ('LUA: adder inited')
            function adder (x)
              print ('LUA: adder called with ' .. x)
              return function (y)
                print ('LUA: curried called with ' .. y)
                return x + y
              end
            end
            `
            let ok = lauxlib.luaL_dostring(L, fengari.to_luastring(code))
            if (ok) return console.log(`Error pcalling: ${ok} ${lua.lua_tojsstring(L, -1)}`)
            function luaAdder (x) {
              // if the next line is omitted, we die with not enough elements in the stack!
              lua.lua_getglobal(L, fengari.to_luastring('adder'))
              lua.lua_pushinteger(L, x)
              ok = lua.lua_pcall(L, 1, 1, 0)
              if (ok) return console.log(`Error pcalling: ${ok} ${lua.lua_tojsstring(L, -1)}`)
              const curried = lua.lua_toproxy(L, -1)
              return function (y) {
                curried(L)
                lua.lua_pushinteger(L, y)
                ok = lua.lua_pcall(L, 1, 1, 0)
                if (ok) return console.log(`Error pcalling: ${ok} ${lua.lua_tojsstring(L, -1)}`)
                const sum = lua.lua_tointeger(L, -1)
                lua.lua_pop(L, 1)
                return sum
              }
            }

            */
		},
		sendToOuput: function (portNumber, type, d1, d2, channel) {
			//portNumber is the output from the B0X point of view (from 1 to 5)
			let outputID = "output-1"; //this is the ID of the output from the MIDI API point of view
			_MIDIComponent.send(outputID, type, d1, d2, channel);
		},
		compilePort: function (portNumber) {
			//will compile the lua code of every rules for a input port in one LUA module
			_compilePort(portNumber);
		},
		compileAll: function () {
			_compileMIDI(); //first compile the MIDI Module (used by everyone)
			_compileGlobal();
			for (var i = 1; i <= 5; i++) {
				_compilePort(i);
			}
		},
		getPortRules: function (portNumber) {
			return _getPortRules(portNumber);
		},
		getPortRule: function (portNumber, ruleID) {
			return _getRule(ruleID, portNumber);
		},
		getPortConfig: function (type, portNumber) {
			return _getPortConfig(type, portNumber);
		},
		setPortConfig: function (type, portNumber, group, sub, value) {
			_setPortConfig(type, portNumber, group, sub, value);
		},
		getGlobalConfig: function () {
			return _getGlobalConfig();
		},
		setGlobalConfig: function (group, sub, value) {
			_setGlobalConfig(group, sub, value);
		},
		setConfig: function (group, property, value) {
			_setConfig(group, property, value);
		},
		getConfig: function (group) {
			if (group != undefined) {
				return _config[group];
			} else {
				return _config;
			}
		},
		getMIDIDevices: function (type) {
			return _MIDIGetCurrentSetupDevices(type);
		},
		getMIDIDevicesAvailable: function (type, portNumber) {
			return _MIDIGetCurrentSetupDevicesAvailable(type, portNumber);
		},
		getMIDIDeviceByHash: function (type, deviceHash) {
			return _MIDIGetDeviceByHash(type, deviceHash);
		},
		unlinkPort: function (type, portNumber) {
			_unlinkPort(type, portNumber);
		},
		linkPort: function (type, portNumber, deviceIndex) {
			_linkPort(type, portNumber, deviceIndex);
		},
		getLUAGlobals: function () {
			//return LUA variables declared in the global context

			//console.log("getGlobalVariables");

			let globalConfig = _getGlobalConfig();

			if (globalConfig.error == null) {
				let _globalsRuntimeCode = `

					function main(portNumber,msg)
		
						local GLOBAL = require("GLOBAL")
						--local cjson = require("cjson")
			
						--local my_json_string = cjson.encode(GLOBAL)
						--print(my_json_string)
						--return my_json_string
						return GLOBAL
			
					end
					return main

				`;
				let _globalsVariablesModule = fengari.load(_globalsRuntimeCode)(); //temporaire

				if (_globalsVariablesModule != null) {
					let result = null;
					try {
						result = _LUAComponent.execute(_globalsVariablesModule);
						$(".globalButtonArea .luacode_icon").removeClass("error");
					} catch (error) {
						console.error("lua", error); //Global module can't even execute, we need to block the execution of the whole thing

						let globalRule = _getGlobalConfig();
						globalRule.state = CONDITION_RUNTIME_ERROR; //flag the rule having a runtime issue (it will prevent it to be compiled until next change in the code)

						//trying to establish what line

						//let regex = /\[string 'midiinput([0-9])'\]:([0-9]+):([a-zA-Z0-9\(\)\.]+),,,[\.]*/gim;
						//Analyzinf LUA debug trace to identify the faulty module, the faulty rule and get a usable line number for the user
						let regex = /\[string "(--GLOBAL module...)"\]:([0-9]+):([a-zA-Z0-9\s'\(\)\.]+)/gim;

						let matches = [...error.matchAll(regex)];

						let errorLine = 0;
						let errorMessage = "";
						let errorFile = "";

						if (matches.length > 0) {
							let error = matches[0];
							console.log("error", error);
							errorFile = error[1];
							errorLine = error[2];
							errorMessage = error[3];
						}

						let errorFull = "";

						let position = errorLine - 5;
						errorFull = "line (" + position + ") : " + errorMessage;

						globalRule.error = _encodeHTML(errorFull);

						$(".globalButtonArea .luacode_icon").addClass("error");
					}
					console.log(result);
				}
			}
		},
		updateRuleName: function (portNumber, ruleID, name) {
			let newName = "";
			const maxLength = 50;

			const forbiddenPattern = /[^a-zA-Z0-9-\s]/g;

			// Replace all forbidden characters with an empty string
			newName = name.replace(forbiddenPattern, "");
			newName.length > maxLength ? newName.substring(0, maxLength) : newName;

			let rule = _getRule(ruleID, portNumber);

			rule.name = newName;

			$(".midi-rule[data-uuid=" + ruleID + "]>label>div").html(newName);

			_saveProgram();
		},
		updateRuleCode: function (portNumber, ruleID, code) {
			/* each time a rule code is requested to be updated :
                - we have to test the syntax of the rule
                - update rule data (new code and state depending on the test)
                - decide to include it or not in the whole compiled code
                - recompile the whole thing

                note that if code is not given, we'll analyze the code we already have in memory
                this is for example done during the compilation process
            */

			let rule = _getRule(ruleID, portNumber);
			//console.log("portNumber : "+portNumber);
			//console.log("ruleID : "+ruleID);
			//console.log(rule);
			rule.code = btoa(code);

			//let's test the code now
			let testResult = _LUAComponent.testSyntax(code);
			if (testResult.success) {
				if (rule.conditionError) {
					rule.state = CONDITION_SYNTAX_ERROR;
				} else {
					rule.state = OK;
				}
				rule.error = null;
			} else {
				rule.state = CODE_SYNTAX_ERROR;
				rule.error = _encodeHTML(testResult.msg);
			}

			let $ruleStateInListEl = $(".midi-rule[data-uuid='" + ruleID + "'] > .state");

			if (rule.state > 0) {
				$ruleStateInListEl.addClass("syntaxError");
			} else {
				$ruleStateInListEl.removeClass("syntaxError");
			}

			//rule = me.serializeFields("inputrule",code,portNumber,ruleID);

			//and now we recompile the whole port
			_compilePort(portNumber);

			return rule;
		},
		updateRuleCondition: function (portNumber, ruleID, code) {
			/* each time a rule condition code is requested to be updated :
                - we have to test the syntax of the condition
                - update condition rule data (new code and state depending on the test)
                - decide to include it or not in the whole compiled code
                - recompile the whole thing
            */

			let rule = _getRule(ruleID, portNumber);
			//console.log("portNumber : "+portNumber);
			//console.log("ruleID : "+ruleID);
			//console.log(rule);
			code = code.trim();
			rule.condition = code;

			if (code != "") {
				code = `if (` + code + `) then end`; //testing the syntax of the condition

				//let's test the code now
				let testResult = _LUAComponent.testSyntax(code);
				if (testResult.success) {
					if (rule.error) {
						rule.state = CODE_SYNTAX_ERROR;
					} else {
						rule.state = OK;
					}
					rule.conditionError = null;
				} else {
					rule.state = CONDITION_SYNTAX_ERROR;
					rule.conditionError = _encodeHTML(testResult.msg);
				}
			} else {
				rule.state = CONDITION_SYNTAX_ERROR;
				rule.conditionError = _encodeHTML("This rule needs a condition to be triggered");
			}

			let $ruleStateInListEl = $(".midi-rule[data-uuid='" + ruleID + "'] > .state");

			if (rule.state > 0) {
				$ruleStateInListEl.addClass("syntaxError");
			} else {
				$ruleStateInListEl.removeClass("syntaxError");
			}

			//and now we recompile the whole port
			_compilePort(portNumber);

			return rule;
		},
		updateGlobalCode: function (code) {
			/* each time the global code is requested to be updated :
                - we have to test the syntax
                - update data (new code and state depending on the test)
                - decide to include it or not in the whole compiled code
                - recompile the whole thing
            */

			let global = _getGlobalConfig();
			//console.log("portNumber : "+portNumber);
			//console.log("ruleID : "+ruleID);
			//console.log(rule);
			global.code = btoa(code);

			//let's test the code now
			let testResult = _LUAComponent.testSyntax(code);
			if (testResult.success) {
				global.state = OK;
				global.error = null;

				//while checking the syntax, we also check the runtime
				//global code is special, if it doesn't work now, it won't wok at all
				$(".globalButtonArea .luacode_icon").removeClass("error");
			} else {
				global.state = CODE_SYNTAX_ERROR;
				global.error = _encodeHTML(testResult.msg);
				$(".globalButtonArea .luacode_icon").addClass("error");
			}

			//and now we recompile the whole thing
			_compileGlobal();

			//after compiling everything, we do a runtime check
			if (global.error == null) {
				me.getLUAGlobals();
			}

			return global;
		},
		serializeFields: function (type, code, portNumber, ruleID) {
			return _serializeFields(type, code, portNumber, ruleID);
		},
		MIDILearning: function (isActive) {
			_MIDILearning(isActive);
		},
		getCurrentMIDIDevices: function () {
			return currentMIDIDevices;
		},
		getCurrentSetup: function () {
			return b0xSetups[_b0xSetupSelected];
		},
		getSetups: function () {
			return _getSetups();
		},
		selectSetup: function (id) {
			return _selectSetup(id);
		},
		forgetDevice(type, hash) {
			let devices = _MIDIGetCurrentSetupDevices(type);
			let selectedSetup = b0xSetups[_b0xSetupSelected];

			let portType = "inputs";
			if (type == "output") {
				portType = "outputs";
			}

			let device = selectedSetup[portType][hash];
			if (device != undefined) {
				delete selectedSetup[portType][hash];
				_MIDIRefreshDevices();
			}
		},
		makeGroupsSortable: function () {
			let selectedPortID;
			/*let nest = $(
        "#"+_selectedPortID+" .subrules ul"
      ).get(0);*/
			//console.log(_selectedPortID);
			//Sortable.create(nest, _sortableConfig);

			$("#b0x-midi-rules .subrules ul").each(function () {
				//console.log(this);
				//console.log(_selectedPortID, this);
				Sortable.create(this, _sortableConfig);
			});
		},
		setRulesFieldsValues(rulesFields) {
			//update field values in memory between each serializer renders
			_setRulesFieldsValues(rulesFields);
		},
		getRuleFieldsValues(ruleID) {
			//update field values in memory between each serializer renders
			if (_rulesFieldsValues[ruleID] == undefined) return {};
			else return _rulesFieldsValues[ruleID];
		},
	};
}

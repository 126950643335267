
export default class Emitter extends EventTarget{

    eventsStore;


    constructor(){
        super();

        this.eventsStore = new Map();
    }

    /**
     * @param {string} eventName 
     * @param {Function} handler 
     * @param {{ strict: boolean }} options
     * @param {boolean} options.strict used when the event is remove
     * 
     * @returns {Function}
     */
    on(eventName, handler, options = { strict: false }) {
        let cloack;

        if (options.strict) {
            cloack = (e) => handler(e.detail);
            
            super.addEventListener(eventName, cloack);
        } else {
            const handleSignature = eventName + ":" + handler.toString();
            const handleStored = this.eventsStore.get(handleSignature);

            if (handleStored) {
                cloack = handleStored;
            } else {
                cloack = (e) => handler(e.detail);

                this.eventsStore.set(handleSignature, cloack);

                super.addEventListener(eventName, cloack);
            }
        }

        return cloack;
    };

    /**
     * @param {string} eventName 
     * @param {any} data
     */
    emit(eventName, data){
        super.dispatchEvent(new CustomEvent(eventName, { detail: data }));
    };

    /**
     * @param {string} eventName 
     * @param {Function} handler
     * @param {{ strict: boolean }} options
     */
    off(eventName, handler, options = { strict: false }) {
        const handleSignature = eventName + ":" + handler.toString();

        if (options.strict) {
            super.removeEventListener(eventName, handler);

            for(const [key, value] of this.eventsStore.entries()){
                if (handleSignature === key && handler === value) {
                    this.eventsStore.delete(key);
                }
            };
        } else {
            const handleStored = this.eventsStore.get(handleSignature);

            if (handleStored) {
                this.eventsStore.delete(handleSignature);
                super.removeEventListener(eventName, handleStored);
            }
        }
    }

}
/* b0x/MIDI/MIDI */ 
/** @typedef {import("/js/dist/ComponentScript.js").default} ComponentScript*/

/**
 * @param {ComponentScript} me
 */

export default function (me) {
	let _inputPorts = {};
	let _outputPorts = {};
	let _MIDIAccess;

	//status bytes per type of command
	const noteOn = 0x90;
	const noteOff = 0x80;
	const controlChange = 0xb0;
	const clock = 0xf8;
	const Sysex = 0xf0;
	const pitchBend = 0xe0;
	const afterTouch = 0xa0;

	const MIDIpool = new Worker("/components/b0x/MIDI/libs/MIDIPoolWorker.js");

	const noteOnReceivedCommand = 9;
	const noteOffReceivedCommand = 8;
	const controlChangeReceivedCommand = 11;
	const pitchBendReceivedCommand = 14;
	const clockCommand = 15;
	const afterTouchCommand = 13;

	function _portsObjectToArray(ports) {
		let result = [];
		for (var port in ports) {
			result.push(ports[port]);
		}
		return result;
	}

	function _MIDIRefreshInputs(port) {
		let inputs = [];
		if (port) inputs.push(port); //We deal only with the given port
		else inputs = _MIDIAccess.inputs;

		let wasUpdated = false;

		inputs.forEach((input) => {
			//console.log(input);
			let id = input.id;
			if (input.state == "connected") {
				_inputPorts[id] = input;
				input.addEventListener("midimessage", _MIDIreceiveMessage);
				wasUpdated = true;
				//input.addEventListener("statechange",_MIDIPortStateChange);
			} else {
				if (_inputPorts[id]) {
					//A saved input is disconnected
					_inputPorts[id] = input;
					input.removeEventListener("midimessage", _MIDIreceiveMessage);
					wasUpdated = true;
					//input.removeEventListener("statechange",_MIDIPortStateChange);
				}
			}
		});
		if (wasUpdated == true) {
			me.emit("MIDIInputsUpdated", _portsObjectToArray(_inputPorts)); //emit an event to notify that MIDI outputs has changed (pass an array of inputs)
		}
	}

	function _MIDIRefreshOutputs(port) {
		let outputs = [];
		if (port) outputs.push(port); //We deal only with the given port
		else outputs = _MIDIAccess.outputs;

		let wasUpdated = false;

		outputs.forEach((output) => {
			let id = output.id;
			if (output.state == "connected") {
				_outputPorts[id] = output;
				wasUpdated = true;
				//output.addEventListener("statechange",_MIDIPortStateChange);
			} else {
				if (_outputPorts[id]) {
					//A saved output is disconnected
					_outputPorts[id] = output;
					wasUpdated = true;
					//output.removeEventListener("statechange",_MIDIPortStateChange);
				}
			}
		});
		if (wasUpdated == true) {
			me.emit("MIDIOutputsUpdated", _portsObjectToArray(_outputPorts)); //emit an event to notify that MIDI outputs has changed (pass an array of outputs)
		}
	}

	//Triggered every time a MIDI device is connected or disconnected
	function _MIDIConnectionUpdated(event) {
		let port = event.port;
		if (port.type == "input") _MIDIRefreshInputs(port);
		if (port.type == "output") _MIDIRefreshOutputs(port);
	}

	//Called on Init when the browser gain access to the MIDI interface
	function _MIDIrequestAccessSuccess(MIDIAccess) {
		_MIDIAccess = MIDIAccess;
		_MIDIRefreshInputs(); //initializing the inputs table
		_MIDIRefreshOutputs(); //initializing the outputs table

		setTimeout(function () {
			_MIDIAccess.addEventListener("statechange", _MIDIConnectionUpdated); //to prevent some port statechange to be trigged too early
		}, 2000);
	}

	function _MIDIrequestAccessFail() {
		throw "MIDI - I could not connect to the MIDI interface of this device";
	}

	function _MIDIreceiveMessage(input) {
		let command = input.data[0] >> 4;
		let channel = (input.data[0] & 0xf) + 1;
		let type;

		switch (command) {
			case noteOnReceivedCommand: //NoteOn (data1 is the note, data2 is the velocity)
				type = "noteon";
				break;
			case noteOffReceivedCommand: //NoteOff
				type = "noteoff";
				break;
			case controlChangeReceivedCommand: //ControlCHange
				type = "cc";
				break;
			case pitchBendReceivedCommand: //pitchBend
				type = "pb";
				break;
			case clockCommand: //Clock
				type = "clock";
				break;
			case afterTouchCommand: //afterTouch
				type = "aftertouch";
				break;
		}

		me.emit("MIDIMessageReceived", {
			type: type,
			data1: input.data[1],
			data2: input.data[2],
			channel: channel,
			inputID: input.currentTarget.id,
		});
	}

	function _MIDIsend(outputID, type, d1, d2, channel) {
		const output = _outputPorts[outputID];

		//console.log("_MIDIsend", outputID, type, d1, d2, channel);

		if (output != undefined) {
			let message;
			switch (type) {
				case "cc":
					message = [controlChange + channel - 1, d1, d2]; //d1 is control change number, d2 is the cc value
					output.send(message); // sends the message.
					break;
				case "noteon":
					//var dataByte1 = noteNumber & 0x7F; // Mask the lower 7 bits of the note number
					//var dataByte2 = velocity & 0x7F; // Mask the lower 7 bits of the velocity
					message = [noteOn + channel - 1, d1, d2]; // note on, d1 is noteNumber, d2 is velocity
					output.send(message); // sends the message.
					break;
				case "noteoff":
					//var dataByte1 = noteNumber & 0x7F; // Mask the lower 7 bits of the note number
					//var dataByte2 = releaseVelocity & 0x7F; // Mask the lower 7 bits of the release velocity
					message = [noteOff + channel - 1, d1, d2];
					output.send(message); // sends the message.
					break;
				case "clock":
					message = [clock];
					output.send(message); // sends the message.
					break;
				case "pb": //pitchbend
					//var pitchBendValue = Math.round((semitones + 2) * 8191 / 24);
					//var lsb = pitchBendValue & 0x7F; // Mask the lower 7 bits of the pitch bend value
					//var msb = (pitchBendValue >> 7) & 0x7F; // Shift the pitch bend value right by 7 bits, then mask the lower 7 bits
					message = [pitchBend + channel - 1, d1, d2];
					output.send(message); // sends the message.
					break;
				case "aftertouch":
					//var dataByte1 = noteNumber & 0x7F; // Mask the lower 7 bits of the note number
					//var dataByte2 = aftertouchValue & 0x7F; // Mask the lower 7 bits of the aftertouch value

					message = [afterTouch + channel - 1, d1, d2];
					output.send(message); // sends the message.
					break;

				case "sysex":
					message = [
						0xf0, // SysEx start byte
						0x7f, // manufacturer ID (example value)
						0x7f, // device ID (example value)
						0x06, // message type (example value)
						0x01, // parameter 1 (example value)
						0x02, // parameter 2 (example value)
						0xf7, // SysEx end byte];
					];
					output.send(message); // sends the message.
					break;
			}

			me.emit("MIDIMessageSent", {
				type: type,
				data1: d1,
				data2: d2,
				channel: channel,
				outputID: outputID,
			});
		}
	}

	return {
		onInit: function () {
			// Starting MIDI implementation

			console.log("MIDI INIT!!!");

			if (navigator.requestMIDIAccess) {
				navigator.requestMIDIAccess().then(_MIDIrequestAccessSuccess, _MIDIrequestAccessFail);
			}

			let workerMessage = {
				action: "init",
			};
			MIDIpool.postMessage(workerMessage);
			MIDIpool.addEventListener("message", (event) => {
				_MIDIsend(event.data.entry, event.data.type, event.data.d1, event.data.d2, event.data.channel); //one day; we'll be able to send MIDI from workers... :/
			});
		},
		send: function (outputID, type, d1, d2, channel) {
			//add the MIDI message to the pool to be sent by the worker (in a separate thread)
			//todo : better analyze the midi message to be sent and be sure it's not nonsense
			if (d2 < 0 || d2 > 127) {
				console.error("you're trying to send a value out of bound data2:" + d2);
				return;
			}

			if (d1 < 0 || d1 > 127) {
				console.error("you're trying to send a value out of bound data1:" + d1);
				return;
			}

			if (_outputPorts[outputID]) {
				//we don't bother sending a message to a non existing output
				let MIDImessageToSend = {
					outputID: outputID,
					type: type,
					d1: d1,
					d2: d2,
					channel: channel,
				};

				let workerMessage = {
					action: "send",
					MIDIMessage: MIDImessageToSend,
				};

				MIDIpool.postMessage(workerMessage); //use a worker to manage the MIDI pool (hoping that one day we will be able to send MIDI from there... :/)
			}
		},
		truc: function (eventName, eventObject) {
			me.emit(eventName, eventObject);
		},
	};
}

/* b0x/layout-parts/inspector/panels/docs/docs */ 
/** @typedef {import("/js/dist/ComponentScript.js").default} ComponentScript*/

/**
 * @param {ComponentScript} me
 */

export default function (me) {
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  let _data;
  return {
    onInit: function () {
      //_b0xComponent = Zone.getComponent("b0x");
    },
    onRender: function () {
      //console.log("###################################################################");
      //_data = me.getData();
    },
    onReady: function () {
      _data = me.getData();
      //console.log(_data);
      if (_data.chapterX != undefined)
        Zone.getComponent("inspector").showPanel("docspanel");
    },
  };
}

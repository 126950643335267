/* b0x/layout-parts/modals/export/export */ 
/** @typedef {import("/js/dist/ComponentScript.js").default} ComponentScript*/

/**
 * @param {ComponentScript} me
 */

export default function (me) {
  let _MIDIComponent;
  let _modal;
  let _data;

  return {
    onInit: function () {
      let test = Zone.getComponent("b0x");
    },
    show: function () {
      if (!_modal) {
        _modal = new bootstrap.Modal("#export-modal", {
          backdrop: "static",
          focus: true,
          keyboard: true,
          width: 500,
        });
      }
      _modal.show();
    },
  };
}

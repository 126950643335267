/* b0x/layout-parts/inspector/panels/output/output */ 
/** @typedef {import("/js/dist/ComponentScript.js").default} ComponentScript*/

/**
 * @param {ComponentScript} me
 */

export default function (me) {
  let $properties;
  let $code;

  let _data;
  let _codeIsChecked = false;
  let _portNumber = null;

  let _b0xComponent;

  let _delegateDeclared = false;

  //Show PropertiesPanel
  function _showPropertiesPanel() {
    $properties = $("#globalProperties");
    $code = $("#globalLUAcode");
    $code.hide();
    $properties.show();
    _codeIsChecked = false;
    //$(".needScroll").getNiceScroll().resize();
  }

  function _showCodePanel() {
    $properties = $("#globalProperties");
    $code = $("#globalLUAcode");

    console.log($properties);

    $properties.hide();
    $code.show();
    _codeIsChecked = true;
    //$(".needScroll").getNiceScroll().resize();
  }

  //debounce used to test and save the luaCode typed in Monaco Editor
  const _debounce = (func, wait) => {
    let timeout;

    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };

      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  };

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  return {
    onInit: function () {
      _b0xComponent = Zone.getComponent("b0x");
    },
    onRender: function () {
      //console.log("###################################################################");
      _data = me.getData();
      _portNumber = _data.portNumber;
    },
    onReady: function () {
      if (!_delegateDeclared) {
        //Implementing clicks events //////////////////////////////////////////////////////////

        $("[zone-name=outputpanel").on(
          "click",
          ".form-check-input",
          function () {
            let $checkboxClicked = $(this);
            let checked = $checkboxClicked[0].checked;
            let group = $checkboxClicked.attr("group");
            let sub = $checkboxClicked.attr("sub");

            _b0xComponent.setPortConfig(
              "output",
              _data.portNumber,
              group,
              sub,
              checked
            );
          }
        );

        $("#outputLinkedWith").change(function () {
          let selected = $(this);
          console.log("outputLinkedWith", selected);

          var deviceHash = selected.find(":selected").val().trim();

          if (deviceHash == -1) {
            //connected to nothing
            _b0xComponent.unlinkPort("output", _portNumber);
          } else {
            //connected to a device, but we need to check if this device is connected
            console.log("value", deviceHash);
            let device = _b0xComponent.getMIDIDeviceByHash(
              "output",
              deviceHash
            );
            console.log("outputLinkedWith CHange", device);
            _b0xComponent.linkPort("output", _portNumber, deviceHash);
          }
        });

        _delegateDeclared = false;
      }
    },
  };
}

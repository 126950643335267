import * as cmpList from "../app.component.js";
import { isFunction } from "./globals.js";
import "./types.js";
import md5 from "blueimp-md5";

class RawComponent {

    /** @type {string} */
    id;
    /** @type {string} */
    path;
    
    /** @type {string} */
    template;
    templateHash;
    assetsPaths;

    /** @type {boolean?} */
    isLogic;


    /** @type {{ [selector: string]: RawComponent }} */
    subComponents = {};

    /** @type {Function} */
    scriptComponent;

    /** @type {HTMLLinkElement} */
    cssHTMLEl;

    /**
     * @typedef {{
     * path: string,
     * template: string,
     * templateHash: string,
     * assetsPaths: { string: string },
     * }} RawComponentParams
     * 
     * @param {RawComponentParams}
     */
    constructor({ path, template, templateHash, assetsPaths }) {
        this.path = path;

        this.id = path.substring(1).replaceAll("/", ".");

        this.template = template;
        this.templateHash = templateHash;
        this.assetsPaths = assetsPaths;

        if (!this.template) {
            this.isLogic = true;
        }
    }

    async init() {
        if (this.assetsPaths?.js) {
            try {
                const jsFile = await this.getJSFile();
                if (isFunction(jsFile)) {
                    this.scriptComponent = jsFile;
                } else {
                    Zone.logs.error(["Zone", "RawComponent"], this.assetsPaths.js + " don't export a function");
                }
            } catch (err) {
                Zone.logs.error(["Zone", "RawComponent"], err);
            }
        }

        if (this.assetsPaths?.css && !this.cssHTMLEl) {
            this.cssHTMLEl = this.loadCSSFile();
        }
    }

    /**
     * @returns {Promise<ComponentScript?>}
     */
    async getJSFile() {
        let cmpName = "cmp" + md5(this.path);
        return cmpList[cmpName];
    }

    loadCSSFile() {
        let styleEl = document.head.querySelector("link[href=\"" + this.assetsPaths.css + "\"]");
        if (!styleEl) {
            styleEl = document.createElement('link');
            styleEl.rel = "stylesheet";
            styleEl.href = this.assetsPaths.css;
    
            document.head.append(styleEl);
        }

        return styleEl;
    }
}

export default RawComponent;

/* b0x/layout-parts/menu/menu */ 
/** @typedef {import("/js/dist/ComponentScript.js").default} ComponentScript*/

/**
 * @param {ComponentScript} me
 */

export default function (me) {
  return {
    onInit: function () {
      $("body").on("click", "#btB0X-devices", function () {
        Zone.getComponent("devicesModal").show();
      });

      $("body").on("click", "#btB0X-contact", function () {
        Zone.loadPage("/b0x/sb23");
      });

      $("body").on("click", "#btB0X-export", function () {
        Zone.getComponent("exportModal").show();
      });

      $("body").on("click", "#btB0X-help", function () {
        console.log("help click");
        Zone.getComponent("inspector").showPanel("docspanel");
      });
    },
  };
}

import { eventDelegate, querySelectorAllOnArray } from "./globals.js"
import Component from "./Component.js"

import EventStore from "./EventStore.js"


class HTMLQuery{

    /** @type {Node[]} */
    #elements;

    /** @type {EventStore} */
    #eventStore;

    /**
     * @param {string | Node | HTMLQuery | Component} target selector or element
     * @param {Node | HTMLQuery | Component | undefined} scope scope for the target if it's a string
     */
    constructor(target, scope) {

        // set elements
        if (!target) {
            // set scope
            if (scope) {
                if (scope instanceof Component) {
                    if (scope.state.ready) this.#elements = [scope.container];
                    else Zone.logs.warning(["Zone", "HTMLQuery"], "target component is not ready, this elements can be expired");
                } else {
                    throw new Error("scope type is invalid");
                }
            } else {
                throw new Error("target and scope are not defined");
            }
        } else if (typeof target === "string") {
            // set scope
            if (scope) {
                if (scope instanceof HTMLQuery) {
                    this.#elements = querySelectorAllOnArray(scope.#elements, target);
                } else if (scope instanceof Node){
                    this.#elements = [...scope.querySelectorAll(target)];
                } else if (scope instanceof Component) {
                    if (scope.state.ready) this.#elements = [...scope.container.querySelectorAll(target)];
                    else Zone.logs.warning(["Zone", "HTMLQuery"], "target component is not ready, this elements can be expired, you can use Zone.find instead if you want select an element in DOM");
                } else {
                    throw new Error("scope type is invalid");
                }
            } else {
                this.#elements = [...document.querySelectorAll(target)];
            }
        } else if (target instanceof Node) {
            this.#elements = [ target ];
        } else if (Array.isArray(target) || target instanceof NodeList) {
            for (const el of target) {
                if(!(el instanceof Node)) throw new Error("an element in target array isn't a Node");
            }
            this.#elements = target;
        } else if (target instanceof HTMLQuery) {
            Object.assign(this, target);
        } else if (target instanceof Component) {
            this.#elements = target.rootNodes;
        } else {
            throw new Error("selector type is invalid");
        }

        if (!this.#elements?.length) {
            Zone.logs.warning(["Zone", "HTMLQuery"], "element not found in target:");
            Zone.logs.warning(["Zone", "HTMLQuery"], target);
        }

    }

    getElements() {
        return this.#elements;
    }
    getEl() {
        return this.getElements();
    }

    /**
     * @param {string} selector
     */
    select(selector) {
        const elements = querySelectorAllOnArray(this.#elements, selector);

        return elements;
    }


    /**
     * @param {string?} content
     * 
     * @returns {HTMLQuery | string[]}
     */
    html(content) {
        if (content) {
            //Setter
            for (const element of this.#elements) {
                element.innerHTML = content;
            }

            return this;
        } else {
            //Getter
            const result = this.#elements.map(el => el.innerHTML);

            return result;
        }
    }

    /**
     * @param {string} ruleName
     * @param {string?} value
     * 
     * @returns {HTMLQuery | string[]}
     */
    css(ruleName, value) {
        if (!ruleName) Zone.logs.warning(["Zone", "HTMLQuery"], "CSS ruleName not defined");

        if (value) {
            //Setter
            for (const element of this.#elements) {
                element.style[ruleName] = value;
            }

            return this;
        } else {
            //Getter
            const result = this.#elements.map(el => getComputedStyle(el)[ruleName]);

            return result;
        }
    }

    /**
     * @param {string} name
     * @param {string?} value
     * 
     * @returns {HTMLQuery | string[]}
     */
    attr(name, value) {
        if (!name) Zone.logs.warning(["Zone", "HTMLQuery"], "attribute name not defined");

        if (value) {
            // Setter
            for (const element of this.#elements) {
                element.setAttribute(name, value);
            }

            return this;
        } else {
            // Getter
            const result = this.#elements.map(el => el.getAttribute(name));

            return result;
        }
    }

    /**
     * @param {string} className
     * 
     * @returns {HTMLQuery}
     */
    addClass(className) {
        for (const element of this.#elements) {
            element.classList.add(className);
        }

        return this;
    }
    /**
     * @param {string} className
     * 
     * @returns {HTMLQuery}
     */
    removeClass(className) {
        for (const element of this.#elements) {
            element.classList.remove(className);
        }

        return this;
    }

    /**
     * @param {string} className
     * 
     * @returns {boolean}
     */
    hasClass(className) {
        for (const element of this.#elements) {
            if(element.classList.contains(className)) return true;
        }

        return false;
    }

    /**
     * @param {string} event event
     * @param {Array<String | Object | Function>} args handler, data and delegate selector
     * 
     * @returns {HTMLQuery}
     */
    on(event, ...args) {
        let selector, data, handler;

        for (const arg of args) {
            if (typeof arg === "string") {
                selector || (selector = arg);
            } else if (typeof arg === "object") {
                data || (data = arg);
            } else if (typeof arg === "function") {
                handler || (handler = arg);
            }
        }

        if (handler) {
            handler = this.#switchThisToHTMLQuery(handler, selector);

            if (data) {
                const fn = handler;
                handler = (e) => {
                    fn(e, data);
                }
            }
    
            let storeEventName;
            if (selector) {
                handler = eventDelegate(selector, handler);
                storeEventName = event+"DelegateOn"+selector;
            } else {
                storeEventName = event;
            }

            for (const element of this.#elements) {
                element.addEventListener(event, handler);
            }
    
            // set eventStore and save handler
            this.#eventStore || (this.#eventStore = new EventStore());
            this.#eventStore.set(storeEventName, handler);
        }else{
            Zone.logs.error(["Zone", "HTMLQuery"], "the handler is not defined in function params");
        }

        return this;
    }

    off(event, ...args) {
        let selector, data, handler;

        for (const arg of args) {
            if (typeof arg === "string") {
                selector = arg;
            } else if (typeof arg === "object") {
                data = arg;
            } else if (typeof arg === "function") {
                handler = arg;
            }
        }

        if (handler) {
            handler = this.#switchThisToHTMLQuery(handler, selector);

            if (data) {
                const fn = handler;
                handler = (e) => {
                    fn(e, data);
                }
            }
    
            let storeEventName;
            if (selector) {
                handler = eventDelegate(selector, handler);
                storeEventName = event+"DelegateOn"+selector;
            } else {
                storeEventName = event;
            }

            // delete from eventStore
            if (this.#eventStore?.get(storeEventName, handler)) {
                handler = this.#eventStore.delete(storeEventName, handler);

                for (const element of this.#elements) {
                    element.removeEventListener(event, handler);
                }
            } else {
                Zone.logs.error(["Zone", "HTMLQuery"], "the handler is not found");
            }
        } else {
            Zone.logs.error(["Zone", "HTMLQuery"], "the handler is not defined in function params");
        }

        return this;
    }

    #switchThisToHTMLQuery(handler, selector) {
        return (e, ...args) => {
            let element;
    
            if (selector) {
                element = e.target.closest(selector);
            } else {
                let elements = this.#elements.filter(el => el.contains(e.target));
    
                elements = elements.filter(el => {
                    for (const element of elements) {
                        if (el !== element && el.contains(element)) return false;
                    }
    
                    return true;
                });
                
                if (elements.length > 1) Zone.logs.warning(["Zone", "HTMLQuery"], "the target element of the event have multiples elements catched");
            
                element = elements[0];
            }
    
            handler.apply(new HTMLQuery(element), [e, ...args]);
        }
    }

}

export default HTMLQuery;
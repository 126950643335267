import zone from "./Zone.js";
import localforage from "../../public/libs/localforage/localforage.js";


let toto=3;
var global = {};

console.log(toto);
// SERVICE WORKER
// if ("serviceWorker" in navigator) {

//     navigator.serviceWorker.register("/sw.js", {
//         scope: "/",
//     })
//     // .then((registration) => {

//     //     if(zone.debugMode){
//     //         registration.addEventListener("updatefound", () => {
//     //             // If updatefound is fired, it means that there's
//     //             // a new service worker being installed.
//     //             const installingWorker = registration.installing;
//     //             console.log(
//     //                 "A new service worker is being installed:",
//     //                 installingWorker
//     //             );
        
//     //             // You can listen for changes to the installing service worker's
//     //             // state via installingWorker.onstatechange
//     //         });
//     //     }

//     // })
//     .catch((error) => {
//         console.error(`Service worker registration failed: ${error}`);
//     });

// } else {
//     console.error("Service workers are not supported.");
// }




// // INIT DB
// const dbName = "Zone PWA";

// // option store
// const saveDataStore = localforage.createInstance({
//     name: dbName,
//     storeName: "hive_save-data",
// });

// // const options = {};

// // getData("options")
// // .then(data => {
// //     Object.assign(options, data);
// // }).catch(err => {
// //     console.error(err);
// // });

// // saveData("options", options)
// // .catch(err => {
// //     console.error(err);
// // });


// async function saveData(key, value){
//     await saveDataStore.setItem(key, value)
//     .catch(error => console.error(error));
// }

// async function getData(key){
//     return await saveDataStore.getItem(key)
//     .catch(error => console.error(error));
// }
/* b0x/layout-parts/modals/contact/contact */ 
/** @typedef {import("/js/dist/ComponentScript.js").default} ComponentScript*/

/**
 * @param {ComponentScript} me
 */

export default function (me) {
  let _b0xComponent;
  let _modal;

  return {
    onBeforeInit: function () {},
    onInit: function () {
      _b0xComponent = Zone.getComponent("b0x");
    },
    onRender: function () {
      console.log();
    },
    onReady: function () {},
    show: function () {
      if (!_modal) {
        _modal = new bootstrap.Modal("#contact-modal", {
          backdrop: "static",
          focus: true,
          keyboard: true,
        });
      }
      _modal.show();
    },
  };
}

/* b0x/layout-parts/midirules/midirules */ 
/** @typedef {import("/js/dist/ComponentScript.js").default} ComponentScript*/

/**
 * @param {ComponentScript} me
 */

export default function (me) {
  let _MIDIComponent;
  let _LUAComponent;
  let _b0xComponent;
  //let _ports;
  let _selectorListeners = []; //to prevent adding an event listener already there on an element

  function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
  }

  return {
    onInit: function () {
      _MIDIComponent = Zone.getComponent("midi");
      _LUAComponent = Zone.getComponent("lua");
      _b0xComponent = Zone.getComponent("b0x");
    },
    onRender: function () {
      console.log("Render");
      _b0xComponent.makeGroupsSortable();
    },
    display: function (portNumber) {
      //UPdate and display the MIDI Rules of a dedicated port Number
      // if portNumber is null, we display an empty list (ie an output has been clicked)

      let data = { rules: [] };
      if (portNumber != null) {
        //dealing with inputs
        let portRules = _b0xComponent.getPortRules(portNumber);
        data.rules = portRules;
      }

      console.log("display portRules : ", data);

      me.render(data);
    },
    /*getRuleByID: function (portNumber, ruleID) {
      _b0xComponent = Zone.getComponent("b0x"); //temporary
      let portRules = _b0xComponent.getPortRules(portNumber);




      for (var portRule in portRules) {
        let rule = portRules[portRule];
        if (rule.uuid == ruleID) {
          return rule;
          break;
        }
      }
      return null;
    },*/
    /*flashRule: function (ruleID,ok) {
      //console.log("flashRule", ruleID);

      if (ok == undefined)ok =true;

      const element = document.querySelector(
        '.midi-rule[data-uuid="' + ruleID + '"]'
      );
      //element.classList.add('animate__animated', 'animate__pulse');

      if (element) {
        element.scrollIntoView({
          behavior: "instant",
          block: "nearest",
          inline: "center",
        });
        element.classList.add("flash");
        $(".b0x-rules").addClass("active");

        if (!_selectorListeners.includes(element, 0)) {
          element.addEventListener("animationend", () => {
            element.classList.remove("flash");
            $(".b0x-rules").removeClass("active");
          });
          _selectorListeners.push(element);
        }
      }
    },*/
    flashRule: function (ruleID, ok) {
      //console.log("flashRule", ruleID);

      if (ok == undefined) ok = true;

      const element = document.querySelector(
        '.midi-rule[data-uuid="' + ruleID + '"]'
      );
      //element.classList.add('animate__animated', 'animate__pulse');

      if (element) {
        element.scrollIntoView({
          behavior: "instant",
          block: "nearest",
          inline: "center",
        });

        let color = "#33e329"; //green
        if (!ok) {
          color = "#ad214b"; //red
        }

        //let letID = "midi-" + type + "-" + portNumber;

        element.animate(
          [
            {
              background: color,
            },
          ],
          {
            duration: 50,
            iterations: 1,
          }
        );

        element.classList.add("flash");
        $(".b0x-rules").addClass("active");
        if (!_selectorListeners.includes(element, 0)) {
          element.addEventListener("animationend", () => {
            element.classList.remove("flash");
            $(".b0x-rules").removeClass("active");
          });
          _selectorListeners.push(element);
        }
      }
    },
  };
}

/* b0x/LUA/LUA */ 
/** @typedef {import("/js/dist/ComponentScript.js").default} ComponentScript*/

/** 
 * @param {ComponentScript} me 
 */

export default function (me) {

    const lua = fengari.lua
    const lauxlib = fengari.lauxlib
    const lualib = fengari.lualib
    const interop = fengari.interop;

    const {
      LUA_ERRRUN,
      LUA_ERRSYNTAX,
      LUA_OK,
      LUA_VERSION_MAJOR,
      LUA_VERSION_MINOR,
      lua_Debug,
      lua_getinfo,
      lua_getstack,
      lua_gettop,
      lua_insert,
      lua_pcall,
      lua_pop,
      lua_pushcfunction,
      lua_pushstring,
      lua_remove,
      lua_setglobal,
      lua_tojsstring
    } = lua;
    const {
      luaL_loadbuffer,
      luaL_newstate,
      luaL_requiref
    } = lauxlib;
    const {
      checkjs,
      luaopen_js,
      push,
      tojs,
      createproxy,
      typeof
    } = interop;

    let L;
    
  

    return {
        onInit : function() {


            //mainModule = fengari.load(compiledCode)();
            //console.log(mainModule);
            
            //midiModule();

            //const result = fengari.call(mainModule, "main", 2);

            //main  = main.require("midiModule");
            
            //L = fengari.lua();

            /*
            const L = fengari.Lua();
            const myModule = L.dostring(`${myModuleCode}; return package.loaded.myModule`)[0];
            L.getfield(L.lua.LUA_REGISTRYINDEX, '_LOADED');
            L.getfield(-1, 'myModule');
            const moduleIndex = L.top - 1;
            if (!L.istable(moduleIndex)) {
              L.pop(1);
              L.settop(moduleIndex);
              L.setfield(-2, 'myModule');
            }*/

            //console.log(midiModule);
            //mainModule.call(3);
            //main();
            /*const myModule = module.require("myModule");
            myModule.sayHello();

            const mySingleton = module;
            mySingleton.sayHello();*/


            //L = lauxlib.luaL_newstate();

            /*


    
            lualib.luaL_openlibs(L);
            luaCodeOld = `
                print ('LUA: adder inited')
                function adder (x)
                    print ('LUA: adder called with ' .. x)
                    return function (y)
                        print ('LUA: curried called with ' .. y)
                        return x + y
                    end
                end
            `

            luaCodeOld = `
                print ('LUA: adder inited')
                function adder (x)
                    print ('LUA: adder called with ' .. x)
                    return function (y)
                        print ('LUA: curried called with ' .. y)
                        return x + y
                    end
                end
            `



            let ok = lauxlib.luaL_dostring(L, fengari.to_luastring(luaCodeOld))
            if (ok) return console.log(`Error pcalling: ${ok} ${lua.lua_tojsstring(L, -1)}`)

            function luaAdder (x) {
              // if the next line is omitted, we die with not enough elements in the stack!
              lua.lua_getglobal(L, fengari.to_luastring('adder'))
              lua.lua_pushinteger(L, x)
              ok = lua.lua_pcall(L, 1, 1, 0)
              if (ok) return console.log(`Error pcalling: ${ok} ${lua.lua_tojsstring(L, -1)}`)
              const curried = lua.lua_toproxy(L, -1)
              return function (y) {
                curried(L)
                lua.lua_pushinteger(L, y)
                ok = lua.lua_pcall(L, 1, 1, 0)
                if (ok) return console.log(`Error pcalling: ${ok} ${lua.lua_tojsstring(L, -1)}`)
                const sum = lua.lua_tointeger(L, -1)
                lua.lua_pop(L, 1)
                return sum
              }
            }

            const curried14 = luaAdder(14)
            const curried3 = luaAdder(3)
            //console.log(curried14(8)) // It says 22!
            //console.log(curried3(7)) // It says 10!
            */

            /*
            let global;
            const luacode = `
            print ('Lua: initializing lua code')
            js = require "js"
            js.global.foo = function(a) 
                print("WEEE") 
                print(a)
            end
            `*/

            //const L = lauxlib.luaL_newstate()
            /* open standard libraries */
            /*
            lualib.luaL_openlibs(L)
            lauxlib.luaL_requiref(L, fengari.to_luastring("js"), interop.luaopen_js, 1)
            lua.lua_pop(L, 1) //
            const ok = lauxlib.luaL_dostring(L, fengari.to_luastring(luacode))
            if (ok) return console.log(`Error pcalling: ${ok} ${lua.lua_tojsstring(L, -1)}`)
            window.foo(30)*/

        },
        run : function(codeLUA){
            let ok = lauxlib.luaL_dostring(L, fengari.to_luastring(codeLUA))
            if (ok) return console.log(`Error pcalling: ${ok} ${lua.lua_tojsstring(L, -1)}`)
        },
        compile : function(code,moduleName){

          let module;
          if (moduleName)module = fengari.load(code,moduleName)();
          else module = fengari.load(code)();

          return module;
        },        
        testSyntax(luaCode){
          //testing the syntax of some lua Code
          let chunkName = "test"; //temporary (let's see later if giving a chunk name is relevant)
          let msg = null;
          let success = true;

          const L = lauxlib.luaL_newstate();
          
          lualib.luaL_openlibs(L); //open standard libraries in lua
          luaL_requiref(L, fengari.to_luastring("js"), luaopen_js, 1); //require the js lib for lua 
          lua_pop(L, 1); // remove lib (don't know why we have to do that)

          if (typeof luaCode == "string") luaCode = fengari.to_luastring(luaCode); //compatibiliy to lua string type for the code
          chunkName = chunkName?fengari.to_luastring(chunkName):null; //idem for chunk name

          let ok = luaL_loadbuffer(L, luaCode, chunkName, null);
          if (ok === LUA_ERRSYNTAX) {
            //msg = new SyntaxError(lua_tojsstring(L, -1));
            msg = lua_tojsstring(L, -1);
          } else {
            msg = tojs(L, -1);
          }
          lua_pop(L, 1); //not sure why we need to do that
          lua.lua_close(L); //neither if we must close it to be sure this peace of code we tested leaves the memory (need to be sure later)
          if (ok !== LUA_OK) success = false;
          return {"success":success,"msg":msg};
        },
        execute(luamodule){
          console.log("LUA EXECUTE");
          console.log(interop);

          let result = luamodule.call();
          //let test = interop.typeof(result);
          return result;

        }
    }
}

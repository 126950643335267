import Emitter from "./Emitter.js"
import Twig from "./twig/twig.min.js";

// UTILS
function hash(string) {
    let h = 0;
    for(let i = 0; i < string.length; i++) 
          h = Math.imul(31, h) + string.charCodeAt(i) | 0;

    return h;
}

/**
 * Random number generator
 * adapted from https://github.com/heikomat/uuid-browser/blob/master/
 * Unique ID creation requires a high quality random # generator.  In the
 * browser this is a little complicated due to unknown quality of Math.random()
 * and inconsistent support for the `crypto` API.  We do the best we can via
 * feature-detection
 */ 
const getRnd = () => {
    const crypto = typeof global !== 'undefined' && (global.crypto || global.msCrypto); // for IE 11  let rnds8 = new Uint8Array(16);
    if (crypto && crypto.getRandomValues) {
        // WHATWG crypto RNG - http://wiki.whatwg.org/wiki/Crypto    crypto.getRandomValues(rnds8);
        return rnds8;
    }
    /** Math.random()-based (RNG)
     *  If all else fails, use Math.random().  It's fast, but is of unspecified
     *  quality.
     */
    let rnds = new Array(16);
    for (let i = 0, r; i < 16; i++) {
        if ((i & 0x03) === 0) r = Math.random() * 0x100000000;
        rnds[i] = r >>> ((i & 0x03) << 3) & 0xff;
    }

    return rnds;
}

const byteToHex = [];
for (let i = 0; i < 256; ++i) {
    byteToHex[i] = (i + 0x100).toString(16).substring(1);
}

const bytesToUuid = (buf, offset) => {
    let i = offset || 0;
    const bth = byteToHex;

    return bth[buf[i++]] + bth[buf[i++]] +
        bth[buf[i++]] + bth[buf[i++]] + '-' +
        bth[buf[i++]] + bth[buf[i++]] + '-' +
        bth[buf[i++]] + bth[buf[i++]] + '-' +
        bth[buf[i++]] + bth[buf[i++]] + '-' +
        bth[buf[i++]] + bth[buf[i++]] +
        bth[buf[i++]] + bth[buf[i++]] +
        bth[buf[i++]] + bth[buf[i++]];
}
const uuid = () => {
    const rnds = getRnd();
    // RFC 4122 version 4 UUID format
    rnds[6] = (rnds[6] & 0x0f) | 0x40;
    rnds[8] = (rnds[8] & 0x3f) | 0x80;

    return bytesToUuid(rnds);
};

function isFunction(x) {
    if (typeof x === 'function') {
        if (x.prototype) {
            if (Object.getOwnPropertyDescriptor(x, 'prototype').writable) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    } else {
        return false;
    }
}


function debounce(callback, delay) {
    let timer;

    return function (...args) {
        clearTimeout(timer);

        timer = setTimeout(function () {
            callback.apply(this, args);
        }, delay);
    }
}

function throttle(callback, delay) {
    let last;
    let timer;

    return function (...args) {
        const now = Date.now();

        if (last && now < last + delay) {
            clearTimeout(timer);

            timer = setTimeout(function () {
                last = now;
                callback.apply(this, args);
            }, delay);
        } else {
            last = now;
            callback.apply(this, args);
        }
    }
}

function b64DecodeUnicode(str) {
    // Going backwards: from bytestream, to percent-encoding, to original string.
    return decodeURIComponent(atob(str).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
}


// NETWORK
/**
 * @param {string} key 
 */
function getCookie(key){
    const cookies = document.cookie.split("; ");
    return cookies.find((c) => c.startsWith(`${key}=`))?.split("=")[1];
}
/**
 * @param {string} key 
 * @param {string} value
 * @param {{
 * domain: string,
 * path: string,
 * sameSite: string,
 * expires: string,
 * maxAge: string,
 * secure: boolean,
 * }} params
 */
function setCookie(key, value, params = { path: "/", secure: true }) {
    let cookieValue = `${key}=${value}`;

    params.domain && (cookieValue += "; domain=" + params.domain);
    params.path && (cookieValue += "; path=" + params.path);
    params.sameSite && (cookieValue += "; SameSite=" + params.sameSite);
    params.expires !== undefined && (cookieValue += "; expires=" + params.expires);
    params.maxAge !== undefined && (cookieValue += "; max-age=" + params.maxAge);
    params.secure && (cookieValue += "; Secure");

    document.cookie = cookieValue;
}


// HTML
const animations = {
    animateFade,
}

/**
 * @param {HTMLElement | HTMLElement[]} element 
 * @param {number | KeyframeAnimationOptions | undefined} options 
 */
async function animateFade(element, options = {}){
    await animate(element, [
        {
            opacity: 0,
        },
        {
            opacity: 1,
        },
    ],
    options);
}

/**
 * @param {HTMLElement | HTMLElement[]} element
 * @param {Keyframe[] | PropertyIndexedKeyframes | undefined} timeline 
 * @param {number | KeyframeAnimationOptions | undefined} options 
 */
function animate(element, timeline = [], options = {}) {
    if (Array.isArray(element)) {
        const results = [];
        for (const el of element) {
            results.push(animate(el, timeline, options));
        }

        return Promise.all(results);
    } else {
        return new Promise(resolve => {
            element.animate(timeline,
            {
                duration: 200,
                fill: "forwards",
                ...options,
            })
            .addEventListener("finish", () => {
                resolve();
            });
        });
    }
}

/**
 * @param {string} delegateSelector 
 * @param {Function} handler
 * 
 * @returns {Function}
 */
function eventDelegate(delegateSelector, handler) {
    return event => {
        const el = event.target.closest(delegateSelector);
        el && handler(event, el);
    };
}


/**
 * @param {Node[]} elements
 * @param {string} selector
 * @returns {HTMLElement?}
 */
function querySelectorOnArray(elements, selector) {
    for(const element of elements){
        if (element.matches(selector)) {
            return element;
        } else {
            const el = element.querySelector(selector);

            if(el) return el;
        }
    }
}
/**
 * @param {Node[]} elements
 * @param {string} selector
 * @returns {HTMLElement[]}
 */
function querySelectorAllOnArray(elements, selector) {
    const result = new Set();

    for(const element of elements){
        if (element.matches(selector)) {
            result.add(element);
        } else {
            const els = Array.from(element.querySelectorAll(selector));

            for(const el of els){
                result.add(el);
            }
        }
    }

    return [ ...result ];
}


// COMPONENT
/**
 * @param {Component} component
 */
function getLocalComponentsList(component) {
    const components = {};

    if (component) {
        components[component.rawComponent.path] = component.getIsStatic();

        for (const subComponent of Object.values(component.subComponents)) {
            Object.assign(components, getLocalComponentsList(subComponent));
        }
    }

    return components;
}


export {
    Emitter,
    Twig,

    animations,
    eventDelegate,
    querySelectorOnArray,
    querySelectorAllOnArray,

    getLocalComponentsList,

    hash,
    uuid,
    isFunction,
    debounce,
    throttle,

    getCookie,
    setCookie,
    b64DecodeUnicode
};
import { eventDelegate } from "./libs/globals.js"
import "./libs/types.js";
import md5 from "blueimp-md5";

export default class ComponentScript {

    /**
     * @private
     * @type {Component}
     */
    #component;

    /** @type {ZoneClass} */
    zone;

    /** @private */
    #htmlEvents = {};


    /** @param {{ zone: one, component: Component }} */
    constructor({ zone, component }) {
        // if (this.constructor == ComponentScript) {
        //   throw new Error("Abstract classes can't be instantiated.");
        // }

        this.zone = zone;
        this.#component = component;
    }

    get name() {
        return this.#component.name;
    }
    get idMD5() {
        return md5(this.#component.URN);
    }
    get URN() {
        return this.#component.URN;
    }

    get attr() {
        return this.#component.componentData.attr;
    }
    /** @param {{}} data */
    set attr(data) {
        Object.assign(this.attr, data);
    }

    get container() {
        return this.#component.container;
    }

    get rootNodes() {
        return this.#component.rootNodes;
    }

    /** @param {Component} component */
    set component(component) {
        this.#component = component;
    }

    /**
     * get data value with key in the component data or direct data if key is not defined
     * @param {string?} key
     * @returns {{}}
     */
    getData(key) {
        if (key) return this.#component.data[key];
        else return this.#component.data;
    }

    /**
     * @param {Object} data
     * @param {boolean?} mergeData
     */
    setData(data, mergeData) {
        this.#component.state.ready = false;

        if (mergeData) {
            Object.assign(this.#component.data, data);
        } else {
            this.#component.data = data;
        }
    }


    // EVENTS
    /**
     * @param {String} eventName 
     * 
     * @param {Array<Function, Object?> | Array<String, Function, Object?>} args delegate selector (optional), handler and options
     * @param {String} args.selector (optionnal)
     * @param {Function} args.handler
     * @param {Object?} args.options
     * @param {boolean?} args.options.strict
     * 
     * @returns {Function?}
     */
    on(eventName, ...args) {
        // set params
        const inInitEvent = this.#component.state.inOnBeforeInit || this.#component.state.inOnInit;
        let selector;
        let handler;
        let options;
        if (typeof args[0] === "string") {
            selector = args[0];
            handler = args[1];
            options = args[2];
        } else if (typeof args[0] === "function") {
            handler = args[0];
            options = args[1];
        } else {
            Zone.logs.error(["Zone", "ComponentScript"], "the second parameter should be a handler (function) or a delegate selector (string)");
            return;
        }

        const {
            strict = false,
        } = options || {};


        if (document.body["on" + eventName] || document.body["on" + eventName] === null) {
            // DOM event mode
            if (this.#component.rawComponent.isLogic) {
                Zone.logs.error(["Zone", "ComponentScript"], `the event name "${eventName}" is a DOM event, isn't usable on a logic component`);
                return;
            }
            if (this.#component.state.inOnBeforeInit) Zone.logs.warning(["Zone", "ComponentScript"], "adding a DOM event in the OnBeforeInit method should not work because the container isn't set yet");

            if (selector) {
                handler = eventDelegate(selector, handler);
            }

            if (!strict) {
                const storeEventName = selector ? eventName + "DelegateOn" + selector : eventName;
                const handleSignature = handler.toString();

                this.#htmlEvents[storeEventName] || (this.#htmlEvents[storeEventName] = {});
                if (this.#htmlEvents[storeEventName][handleSignature]) {
                    handler = this.#htmlEvents[storeEventName][handleSignature];
                } else {
                    this.#htmlEvents[storeEventName][handleSignature] = handler;
                }

                if (inInitEvent) {
                    this.#component.htmlEventsInInit[storeEventName] || (this.#component.htmlEventsInInit[storeEventName] = {});
                    if (!this.#component.htmlEventsInInit[storeEventName][handleSignature]) {
                        this.#component.htmlEventsInInit[storeEventName][handleSignature] = handler;
                    }
                }
            }

            this.container.addEventListener(eventName, handler);

            return handler;
        }

        // default event mode
        const fullEventName = `${this.URN}:${eventName}`;

        if (inInitEvent) {
            const handleSignature = handler.toString();

            this.#component.eventsInInit[fullEventName] || (this.#component.eventsInInit[fullEventName] = {});

            if (this.#component.eventsInInit[fullEventName][handleSignature]) {
                handler = this.#component.eventsInInit[fullEventName][handleSignature];
            } else {
                this.#component.eventsInInit[fullEventName][handleSignature] = handler;
            }
        }

        return this.zone.on(fullEventName, handler, options);
    }
    /**
     * @param {string} eventName 
     * @param {Object} params 
     */
    emit(eventName, params) {
        if (document.body["on" + eventName] || document.body["on" + eventName] === null) {
            // DOM event mode
            if (this.#component.rawComponent.isLogic) {
                Zone.logs.error(["Zone", "ComponentScript"], `the event name "${eventName}" is a DOM event, isn't usable on a logic component`);
                return;
            }
        }

        this.zone.emit(`${this.URN}:${eventName}`, params);
    }
    /**
     * @param {string} eventName 
     * 
     * @param {Array<Function, Object?> | Array<String, Function, Object?>} args delegate selector (optional), handler and options
     * @param {String} args.selector (optionnal)
     * @param {Function} args.handler
     * @param {Object?} args.options
     * @param {boolean?} args.options.strict
     * @param {boolean?} args.options.inInitEvent
     * 
     * @returns {Function?}
     */
    off(eventName, ...args) {
        // set params
        let selector;
        let handler;
        let options;
        if (typeof args[0] === "string") {
            selector = args[0];
            handler = args[1];
            options = args[2];
        } else if (typeof args[0] === "function") {
            handler = args[0];
            options = args[1];
        } else {
            Zone.logs.error(["Zone", "ComponentScript"], "the second parameter should be a handler (function) or a delegate selector (string)");
            return;
        }

        const {
            strict = false,
            inInitEvent = false,
        } = options || {};

        if (this.container && (this.container["on" + eventName] || this.container["on" + eventName] === null)) {
            // DOM event mode
            if (selector) {
                handler = eventDelegate(selector, handler);
            }

            if (!strict) {
                const storeEventName = selector ? eventName + "DelegateOn" + selector : eventName;
                const handleSignature = handler.toString();

                if(this.#htmlEvents[storeEventName]){
                    handler = this.#htmlEvents[storeEventName][handleSignature];
                    if (this.#htmlEvents[storeEventName][handleSignature]) {
                        this.#htmlEvents[storeEventName][handleSignature] = undefined;
                    }
                }

                if (inInitEvent) {
                    if (this.#component.htmlEventsInInit[storeEventName]) {
                        if (this.#component.htmlEventsInInit[storeEventName][handleSignature]) {
                            this.container.removeEventListener(eventName, this.#component.htmlEventsInInit[storeEventName][handleSignature]);
                            this.#component.htmlEventsInInit[storeEventName][handleSignature] = undefined;
                        }
                    }
                }
            }

            this.container.removeEventListener(eventName, handler);

            return;
        }

        // default event mode
        const fullEventName = `${this.URN}:${eventName}`;

        if (inInitEvent) {
            const handleSignature = handler.toString();

            if (this.#component.eventsInInit[fullEventName]) {
                if (this.#component.eventsInInit[fullEventName][handleSignature]) {
                    handler = this.#component.eventsInInit[fullEventName][handleSignature];
                    this.#component.eventsInInit[fullEventName][handleSignature] = undefined;
                }
            }
        }

        this.zone.off(fullEventName, handler, options);
    }


    // STEPS
    /**
     * @param {Object?} data
     * @param {boolean?} mergeData
     */
    async render(data, mergeData) {
        if (this.#component.state.inOnRender) {
            Zone.logs.error(["Zone", "ComponentScript"], "render method is call in the onRender method of the component");
            return;
        }
        if(!this.#component.state.initiated) Zone.logs.warning(["Zone", "ComponentScript"], "component " + this.name + " isn't already initialized");

        data && this.setData(data, mergeData);

        await this.#component.render();
    }

    /**
     * @param {Array<boolean> | Array<Object, boolean?> | undefined} args simple rendering or rendering with a setData
     * 
     * @param {boolean} args.render (optional)
     * 
     * @param {object} args.data (optional)
     * @param {boolean} args.mergeData (optional)
     */
    async deploy(...args) {
        if (this.#component.state.inOnDeploy) {
            Zone.logs.error(["Zone", "ComponentScript"], "deploy method is call in the onDeploy method of the component");
            return;
        }
        if(!this.#component.state.initiated) Zone.logs.warning(["Zone", "ComponentScript"], "component " + this.name + " isn't already initialized");

        if (this.#component.parent?.isPage && this.#component.parent !== this.zone.rootComponent) {
            Zone.logs.error(["Zone", "ComponentScript"], "parent component of " + this.name + " is a page component and it isn't the actual root component");
            return;
        }
        this.#component.parent?.state.deployed || Zone.logs.warning(["Zone", "ComponentScript"], "parent component of " + this.name + " isn't deployed");

        if (args[0] === true) {
            // force render
            await this.render();
        } else if (typeof args[0] === "object") {
            // set data and force render
            await this.render(args[0], args[1]);
        } else {
            await this.#component.mount();
        }
    }

    async leave() {
        if (this.#component.state.inOnLeave) {
            Zone.logs.error(["Zone", "ComponentScript"], "leave method is call in the onLeave method of the component");
            return;
        }
        if(!this.#component.state.initiated) Zone.logs.warning(["Zone", "ComponentScript"], "component " + this.name + " isn't already initialized");

        if (this.#component.parent?.isPage && this.#component.parent !== this.zone.rootComponent) {
            Zone.logs.error(["Zone", "ComponentScript"], "parent component of " + this.name + " is a page component and it isn't the actual root component");
            return;
        }
        this.#component.parent?.state.deployed || Zone.logs.warning(["Zone", "ComponentScript"], "parent component of " + this.name + " isn't deployed");

        await this.#component.unmount();
    }


    // HTML ELEMENTS
    /**
     * get the container of the component or a querySelector if a selector is set
     * @param {string?} selector 
     * @returns {HTMLElement}
     */
    getElement(selector) {
        if (selector) return this.container.querySelector(selector);
        else return this.container;
    }
    /**
     * get the container of the component or a querySelectorAll if a selector is set
     * @param {string?} selector 
     * @returns {HTMLElement[]}
     */
    getElements(selector) {
        if (selector) return Array.from(this.container.querySelectorAll(selector));
        else return this.#component.getElements();
    }
    
    /**
     * select an element in component or in his sub components
     * @param {string} selector 
     * @returns {HTMLElement}
     */
    querySelector(selector) {
        return this.#component.querySelector(selector);
    }
    /**
     * select all elements in component or in his sub components
     * @param {string} selector 
     * @returns {HTMLElement[]}
     */
    querySelectorAll(selector) {
        return this.#component.querySelectorAll(selector);
    }

    /**
     * 
     * @param {string?} selector 
     * @returns {HTMLQuery}
     */
    find(selector) {
        if (this.#component.rawComponent.isLogic) {
            Zone.logs.error(["Zone", "ComponentScript"], "find is not available on component " + this.name + " because is a logic component");
        } else {
            return this.zone.find(selector, this.#component);
        }
    }


    // COMPONENT
    getParent() {
        return this.#component.parent?.componentScript;
    }

    getSubComponents() {
        return Object.values(this.#component.subComponents).map(item => item.componentScript);
    }

    /**
     * Get a component by this name
     * @param {string} componentName "zone-name"
     */
    getSubComponent(componentName) {
        const component = this.#component.getComponent(componentName);

        if (!component) {
            Zone.logs.error(["Zone", "ComponentScript"], "sub component " + componentName + " not found in " + this.URN);
        }

        return component?.componentScript;
    }

    /**
     * Get a component from root component or relative from this
     * @param {string} componentURN ">zone-name1>zone-name2>zone-name3"
     */
    getComponentByBranch(componentURN) {
        const component = this.#component.getSubComponentByURN(componentURN);

        if (!component) {
            Zone.logs.warning(["Zone", "ComponentScript"], "component " + componentURN + " not found");
        }

        return component?.componentScript;
    }


    /**
     * @param {{
     *   url: string,
     *   name: string,
     *   selector: string?,
     *   data: object?
     * }}
     * 
     * @returns {Promise<ComponentScript>}
     */
    async loadComponent({ url, name, selector, data = {} }){
        const component = await this.#component.loadComponent(url, name, selector);

        component.componentScript.setData(data);
        await component.render();

        return component.componentScript;
    }

}
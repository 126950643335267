
export default class EventStore {

    store;

    constructor(){
        this.store = new Map();
    }

    /**
     * @param {string} eventName
     * @param {Function} handler
     * 
     * @returns {Function}
     */
    get(eventName, handler) {
        const handlerSignature = this.convertHandlerToSignature(eventName, handler);
        return this.store.get(handlerSignature);
    }

    /**
     * @param {string} eventName
     * @param {Function} handler 
     */
    set(eventName, handler) {
        const handlerSignature = this.convertHandlerToSignature(eventName, handler);
        this.store.set(handlerSignature, handler);
    }

    /**
     * @param {string} eventName
     * @param {Function} handler
     * 
     * @returns {Function}
     */
    delete(eventName, handler) {
        const handlerSignature = this.convertHandlerToSignature(eventName, handler);

        const handleStored = this.store.get(handlerSignature);

        if (handleStored) {
            this.store.delete(handlerSignature);
        }

        return handleStored;
    }

    /**
     * @param {string} eventName
     * @param {Function} handler
     * 
     * @returns {string}
     */
    convertHandlerToSignature(eventName, handler) {
        return eventName + ":" + handler.toString();
    }

}
//--- IMPORT ---//
import ZoneClass from "./libs/ZoneClass.js";
import {
  animations,
  eventDelegate,
  debounce,
  throttle,
  b64DecodeUnicode,
  getLocalComponentsList,
} from "./libs/globals.js";

import "./libs/types.js";

//--- VARIABLE ---//
/** @type { [RegExp, string][] } */
let routesTemplates;

/** @type { [RegExp, string][] } */
let routesComponents;

const zone = new ZoneClass({
  conf,
});

const zoneEvents = {
  onInit: [],

  onBeforePageLoad: [],
  onPageLoad: [],

  onBeforePageLeave: [],
  onPageLeave: [],
};

//--- MAIN ---//
if (zone.debugMode) {
  globalThis.zone = zone;
}

globalThis.Zone = {
  animations,
  debounce,
  throttle,

  debugMode: zone.debugMode,
  logs: zone.log,
  log: (content, context, verbose) => {
    zone.log.debug(context ? context : "default", content, verbose);
  },

  on: zone.on.bind(zone),
  emit: zone.emit.bind(zone),
  off: zone.off.bind(zone),

  /**
   * @param {Function} handler
   */
  onInit: function (handler) {
    zoneEvents.onInit.push(handler);
  },
  /**
   * @param {Function} handler
   */
  onBeforePageLoad: function (handler) {
    zoneEvents.onBeforePageLoad.push(handler);
  },
  /**
   * @param {Function} handler
   */
  onPageLoad: function (handler) {
    zoneEvents.onPageLoad.push(handler);
  },
  /**
   * @param {Function} handler
   */
  onBeforePageLeave: function (handler) {
    zoneEvents.onBeforePageLeave.push(handler);
  },
  /**
   * @param {Function} handler
   */
  onPageLeave: function (handler) {
    zoneEvents.onPageLeave.push(handler);
  },

  animationPageLoad: async function () {
    await Zone.animations.animateFade(document.body);
  },

  animationPageLeave: async function () {
    await Zone.animations.animateFade(document.body, { direction: "reverse" });
  },

  find: zone.find.bind(zone),

  /**
   * Get a component by this name
   * @param {string} componentName "zone-name"
   * @returns {ComponentScript}
   */
  getComponent: (componentName) => {
    const component = zone.getComponent.bind(zone)(componentName);

    if (!component)
      Zone.logs.warning("Zone", "component " + componentName + " not found");

    return component?.componentScript;
  },
  /**
   * Get a component in the root component hierarchy
   * @param {string} componentURN ">zone-name1>zone-name2>zone-name3"
   * @returns {ComponentScript}
   */
  getComponentByURN: (componentURN) => {
    const component = zone.getComponentByURN(componentURN);

    if (!component)
      Zone.logs.warning("Zone", "component " + componentURN + " not found");

    return component?.componentScript;
  },
  getRootComponent: () => {
    return zone.rootComponent?.componentScript;
  },

  loadData: zone.loadData.bind(zone),
  /**
   * @param {string | URL} url
   * @param {string} componentName
   *
   * @returns {Promise<ComponentScript>}
   */
  loadComponent: async (url, componentName) => {
    const component = await zone.loadComponent(url, componentName);

    await component?.componentScript.render();

    return component?.componentScript;
  },
};

initFirstPage();

//--- FUNCTION ---//
/**
 * @requires layoutInit set in html script tag by php
 * @requires routeTemplateMap set in html script tag by php
 * @requires conf set in html script tag by php
 * @requires routesTemplates
 * @requires zone
 */
async function initFirstPage() {
  zone.log.info("Zone", "----- INIT FIRST PAGE -----");

  // routesTemplates
  routesTemplates = JSON.parse(b64DecodeUnicode(routeTemplateMap));
  zone.log.info("Zone", "routeTemplateMap", 1);
  zone.log.info("Zone", routesTemplates, 1);

  // routesComponents
  routesComponents = JSON.parse(b64DecodeUnicode(routeComponentMap));
  zone.log.info("Zone", "routesComponents", 1);
  zone.log.info("Zone", routesComponents, 1);

  // layout init
  zone.log.info("Zone", "layoutInit", 1);
  zone.log.info("Zone", layoutInit, 1);

  // conf (logs)
  zone.log.info("Zone", "conf.logs", 1);
  zone.log.info("Zone", conf.logs, 1);

  // create main component
  const component = await zone.addComponent(layoutInit, null, true);

  zone.rootComponent = component;
  await component.beforeInit();

  await parseHTMLComponent(component);

  // add url to history
  if (component.isLayout) {
    zone.history[window.location.pathname] =
      component.subComponents[layoutInit.refLayoutName];
  } else {
    zone.history[window.location.pathname] = component;
  }

  // remove script tag
  for (const scriptEl of document.querySelectorAll("[zone-script]")) {
    scriptEl.remove();
  }
  layoutInit = undefined;
  routeTemplateMap = undefined;
  routeComponentMap = undefined;
  conf = undefined;

  const currentURL = new URL(window.location.href);
  const templateHash = getRouteTemplateHash(currentURL);
  history.replaceState({ templateHash }, "", currentURL.href);

  window.addEventListener("popstate", onHistoryPopState);

  // set onCLick internals links
  document.body.addEventListener(
    "click",
    eventDelegate("a[href]", (event, delegateTarget) => {
      if (delegateTarget.target !== "" && delegateTarget.target !== "_self") {
        return;
      }

      const el = delegateTarget;

      const r = /^(?:[a-z+]+:)?\/\//i;
      let elURL;

      if (!r.test(el.href)) {
        //url is relative
        elURL = new URL(el.href, document.baseURI);
      } else {
        //url is absolute
        elURL = new URL(el.href);
      }

      const currentURL = new URL(window.location.href);

      if (elURL.host === currentURL.host) {
        event.preventDefault();

        const templateHash = getRouteTemplateHash(elURL);

        history.scrollRestoration = "manual";
        if (elURL.href === currentURL.href) {
          history.replaceState({ templateHash }, "", elURL.href);
        } else {
          history.pushState({ templateHash }, "", elURL.href);
        }

        // check if is a different layout
        if (zone.rootComponent.rawComponent.templateHash === templateHash) {
          loadPage(elURL);
          return;
        }

        document.location.assign(elURL);
      }
    })
  );

  await emitZoneEvent("onInit", component);
}

/**
 * @param {string | URL} url url of the component to load
 *
 * @requires zone
 */
async function loadPage(url) {
  try {
    url = new URL(url);

    zone.log.info("Zone", "----- LOAD PAGE ----- " + url.pathname);

    const targetComponentPath = getRouteComponentPath(url);

    let leaveRootComponent = !(
      (zone.rootComponent.isLayout &&
        zone.rootComponent.subComponents[zone.rootComponent.activeComponentName]
          .rawComponent.path === targetComponentPath) ||
      zone.rootComponent.rawComponent.path === targetComponentPath
    );

    if (leaveRootComponent) {
      await emitZoneEvent("onBeforePageLeave", zone.rootComponent);
      await Zone.animationPageLeave();
      await zone.rootComponent.unmount();
      await emitZoneEvent("onPageLeave", zone.rootComponent);
    }

    let component = zone.history[url.pathname];

    const isNewComponent = !component;
    let isStatic = component?.getIsStatic();

    if (component?.parent?.isLayout) {
      component.parent.activeComponentName = component.name;
      component = component.parent;

      if (isStatic && component.getIsStatic()) {
        const componentsStaticInfos = {};

        for (const layoutSubComponent of Object.values(
          component.layoutSubComponents[component.activeComponentName]
        )) {
          Object.assign(
            componentsStaticInfos,
            getLocalComponentsList(layoutSubComponent)
          );
        }

        for (const info of Object.values(componentsStaticInfos)) {
          if (!info) {
            isStatic = false;
            break;
          }
        }
      }
    }

    if (isNewComponent || !isStatic) {
      // fetch component data
      await zone
        .fetchComponentData(url)
        .then(async (componentData) => {
          component = await zone.addComponent(componentData, null, true);

          // add url to history
          if (component.isLayout) {
            zone.history[url.pathname] =
              component.subComponents[componentData.refLayoutName];
          } else {
            zone.history[url.pathname] = component;
          }
        })
        .catch((error) => {
          throw error;
        });
    } else {
      await component.reset();
    }

    zone.rootComponent = component;

    if (isNewComponent) {
      if (component.isLayout) {
        await component.subComponents[
          component.activeComponentName
        ].beforeInit();
      } else {
        await component.beforeInit();
      }
    }

    await emitZoneEvent("onBeforePageLoad", component);

    await component.refresh();

    await emitZoneEvent("onPageLoad", component);

    if (leaveRootComponent) {
      await Zone.animationPageLoad();
    }
  } catch (error) {
    zone.log.error("Zone", error);
  }
}

/**
 * @param {Component} component
 */
async function parseHTMLComponent(component, isFirst = true) {
  zone.log.info("Zone", "-- PARSE HTML --" + component.name, 1);

  if (!component.rawComponent.isLogic) {
    if (component.isPage) {
      component.container = document;
      component.rootNodes = [document.head, document.body];
    } else {
      if (!component.isRoot) {
        component.rootNodes = [...component.container.childNodes];
        component.cleanRootNodes();

        component.container.setAttribute(
          "zone-path",
          component.rawComponent.path
        );
      }
    }
  }

  component.setChildrenContainers();

  component.state.deployed = true;

  for (const subComponent of Object.values(component.subComponents)) {
    await parseHTMLComponent(subComponent, false);
  }

  if (isFirst) {
    await component.init();
    await component.ready();
  }
}

/**
 * @param {URL} url
 */
function getRouteTemplateHash(url) {
  for (const [route, templateHash] of routesTemplates) {
    if (new RegExp(route).test(url.pathname)) {
      return templateHash;
    }
  }
}
/**
 * @param {URL} url
 */
function getRouteComponentPath(url) {
  for (const [route, componentPath] of routesComponents) {
    if (new RegExp(route).test(url.pathname)) {
      return "/" + componentPath;
    }
  }
}

function onHistoryPopState({ state }) {
  if (zone.rootComponent.rawComponent.templateHash === state.templateHash) {
    loadPage(window.location.href);
    return;
  }

  document.location.assign(window.location.href);
}

async function emitZoneEvent(eventName, ...args) {
  const handlersResponses = [];
  for (const handler of zoneEvents[eventName]) {
    handlersResponses.push(handler(...args));
  }

  await Promise.all(handlersResponses);
}

//--- EXPORT ---//
export default zone;

/* site/index.page */ 

/** 
 * @param {ComponentScript} me 
 */

export default function (me) {

    let _isLocalStorageEnabled = null;
    let _ua = navigator.userAgent.toLowerCase();

    function _check(r) {return r.test(_ua)};

    function _isWindows() { return _check(/windows|win32/) };
    
    function _debouncedResize(callback) {
        let tick;
        window.addEventListener('resize', (e) => {
            let tock = Date.now();
            tick = tock;
            setTimeout(() => {
                if (tick === tock) {
                    callback(e);
                }
            }, 125);
        });
    }

    return {

        onInit : function () {
            //console.log("SITE onInit trigged");

            // Check if the localstorage is enabled
            try {
                typeof localStorage.constructor;
                _isLocalStorageEnabled = true;
            } catch (e) {
                _isLocalStorageEnabled = false;
            }
    
            _debouncedResize((e) => {
                //Writing here the things to do when the viewport is resized
                //....
            });
    
            if (_isWindows()){
                //console.log("IS WINDOWS");
                /*$("body").niceScroll({
                    zindex: 10000
                });*/
            }
    
            $(window).scroll(function (event) {
                var scroll = $(window).scrollTop();
                // Do something
                if (scroll == 0){
                    $(".main-menu").removeClass("active");
                }else{
                    $(".main-menu").addClass("active");
                }
            });
    
            $("body").on("click",function () {
                $(".search-container").removeClass("active");
            });
    
            $("button.search-quit").on("click",function (e) {
                e.stopPropagation();
                $(".search-container").removeClass("active");
            });        
    
            $(".search-container").on("click",function (e) {
                e.stopPropagation();
                $(this).addClass("active");
            });
    
    
        }


    }

}
